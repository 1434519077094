<template>
  <div>
    <BaseSwitcher
      :value="form.checkGameProfileRegion.value"
      :error-message="form.checkGameProfileRegion.error"
      :disabled="!abilities.canEditCheckGameProfileRegion"
      @input="
        updateFormData({
          name: form.checkGameProfileRegion.name,
          value: $event,
        })
      "
    >
      <template slot="label">
        {{ $t('games.server') }}
        <pvp-tooltip class="info">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-server') }}
          </template>
        </pvp-tooltip>
      </template>
    </BaseSwitcher>

    <BaseSelect
      v-if="form.checkGameProfileRegion.value"
      class="settings-control"
      :value="form.regionCode.value"
      :label="$t('games.server')"
      :disabled="!abilities.canEditRegionCode"
      :error-message="form.regionCode.error"
      :options="regionData"
      value-key="code"
      label-key="label"
      :required="true"
      @input="
        updateFormData({ name: form.regionCode.name, value: $event })
      "
    />
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'LaSettings',
  components: {
    Icon,
    BaseSwitcher,
    BaseSelect,
  },
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapGetters('application', ['getLaShards']),

    regionData() {
      return this.getLaShards;
    },
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
  },
};
</script>

<style lang="scss" scoped>
.select,
.switcher {
  margin-top: 26px;
}
.settings-control {
  max-width: 420px;
}
</style>
