<template>
  <div>
    <tournament-settings-form>
      <h2>{{ $t('tournaments.settings_games') }}</h2>
      <component :is="componentName" v-bind="$attrs" />
    </tournament-settings-form>
  </div>
</template>

<script>
import CfSettings from '@components/TournamentComponents/Settings/Games/CfSettings.vue';
import WfSettings from '@components/TournamentComponents/Settings/Games/WfSettings.vue';
import HsSettings from '@components/TournamentComponents/Settings/Games/HsSettings.vue';
import LaSettings from '@components/TournamentComponents/Settings/Games/LaSettings.vue';
import CsgoSettings from '@components/TournamentComponents/Settings/Games/CsgoSettings.vue';
import Cs2Settings from '@components/TournamentComponents/Settings/Games/Cs2Settings.vue';
import TournamentSettingsForm from '@components/TournamentComponents/Settings/TournamentSettingsForm.vue';

export default {
  name: 'TournamentEditGame',
  components: {
    TournamentSettingsForm,
    LaSettings,
    HsSettings,
    WfSettings,
    CfSettings,
    CsgoSettings,
    Cs2Settings,
  },
  computed: {
    ...mapState('tournamentSettings', ['form']),
    ...mapGetters('application', ['getGameCode']),

    gameCode() {
      return this.getGameCode(this.form?.idGame?.value);
    },

    componentName() {
      switch (this.gameCode) {
        case 'wf':
          return 'wf-settings';
        case 'cf':
          return 'cf-settings';
        case 'hs':
          return 'hs-settings';
        case 'csg':
          return 'csgo-settings';
        case 'cs2':
          return 'cs2-settings';
        case 'lar':
          return 'la-settings';
        default:
          return '';
      }
    },
  },
};
</script>
