<template>
  <div class="match-making-leaderboard-settings">
    <loader
      v-if="isLoading.page"
      class="match-making-leaderboard-settings__loader"
    />
    <div v-else class="match-making-leaderboard__wrapper">
      <h3
        v-if="leaderboardId === 'new'"
        class="match-making-leaderboard-settings__header"
      >
        Создание нового лидерборда
      </h3>
      <h3 v-else class="match-making-leaderboard-settings__header">
        <Button
          tag="button"
          size="regular"
          type="secondary"
          :text="$t('global.back')"
          @click="goBack"
        >
          <template #slotBefore>
            <icon name="arrow-left" />
          </template>
        </Button>
        Настройки лидерборда
        <p v-if="hasLeaderboard">
          {{ leaderboard.name }}
        </p>
      </h3>
      <div
        v-if="leaderboardId === 'new'"
        class="match-making-leaderboard-settings__wrapper mb-24"
      >
        <!-- Input "Имя нового лидерборда" -->
        <div class="inline">
          <BaseInput
            v-model="leaderboardPayload.name"
            class="match-making-leaderboard__input"
            required
            :error-message="validationErrors('name')"
            :label="$t('tournaments.leaderboard_name')"
          />
        </div>
        <!-- Селект "Система подсчета" -->
        <div v-if="supportedSystems.length" class="inline mb-8">
          <BaseSelect
            class="match-making-leaderboard__input"
            disabled
            value-key="systemId"
            label-key="label"
            :value="leaderboardPayload.systemId"
            :label="$t('tournaments.type_counting_system')"
            list-position="top"
            :options="supportedSystems"
            :required="true"
          />
          <pvp-tooltip class="left">
            <icon name="info" />
            <template slot="tooltip">
              {{
                $t(
                  'tournaments.tooltips_settings_type_counting_system',
                )
              }}
            </template>
          </pvp-tooltip>
        </div>
        <!-- Datapicker "Время начала лидерборда" -->
        <div class="inline">
          <pvp-datepicker
            v-model="leaderboardPayload.periodFromTs"
            class="match-making-leaderboard__input"
            position="top"
            :has-time="true"
            :required="true"
            :error-message="validationErrors('period')"
            :placeholder="$t('tournaments.leaderboard_date_start')"
            :label="$t('tournaments.leaderboard_reg_start')"
          />
          <pvp-tooltip>
            <icon name="info" />
            <template slot="tooltip">
              {{
                $t(
                  'tournaments.tooltips_leaderboard_settings_reg_start',
                )
              }}
            </template>
          </pvp-tooltip>
        </div>
        <!-- Datapicker "Время окончания лидерборда" -->
        <div class="inline">
          <pvp-datepicker
            v-model="leaderboardPayload.periodToTs"
            class="match-making-leaderboard__input"
            position="top"
            :has-time="true"
            :required="true"
            :error-message="validationErrors('period')"
            :placeholder="$t('tournaments.leaderboard_date_end')"
            :label="$t('tournaments.leaderboard_reg_end')"
          />
          <pvp-tooltip>
            <icon name="info" />
            <template slot="tooltip">
              {{
                $t(
                  'tournaments.tooltips_leaderboard_settings_reg_end',
                )
              }}
            </template>
          </pvp-tooltip>
        </div>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.start"
            required
            :error-message="validationErrors('system.start')"
            :label="$t('tournaments.initial_value')"
          />
        </div>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.victory"
            required
            :error-message="validationErrors('system.victory')"
            :label="$t('tournaments.scores_for_win')"
          />
        </div>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.defeat"
            required
            :error-message="validationErrors('system.defeat')"
            :label="$t('tournaments.scores_for_loose')"
          />
        </div>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.tp"
            required
            :error-message="validationErrors('system.tp')"
            :label="$t('matches.tech_shortDefeat')"
          />
        </div>
      </div>
      <div
        v-else
        class="match-making-leaderboard-settings__wrapper mb-24"
      >
        <!-- Input "Имя лидерборда" -->
        <div class="inline">
          <BaseInput
            v-model="leaderboardPayload.name"
            required
            :error-message="validationErrors('name')"
            :label="$t('tournaments.leaderboard_name')"
          />
        </div>
        <!-- Селект "Система подсчета" -->
        <div class="inline mb-8">
          <BaseSelect
            class="match-making-leaderboard__input"
            disabled
            :value="leaderboardPayload.systemId"
            value-key="systemId"
            label-key="label"
            :label="$t('tournaments.type_counting_system')"
            list-position="top"
            :options="supportedSystems"
            :required="true"
          />
          <pvp-tooltip class="left">
            <icon name="info" />
            <template slot="tooltip">
              {{
                $t(
                  'tournaments.tooltips_settings_type_counting_system',
                )
              }}
            </template>
          </pvp-tooltip>
        </div>

        <template v-if="isSeason">
          <!-- Datapicker "Время начала лидерборда" -->
          <div class="inline">
            <pvp-datepicker
              v-model="leaderboardPayload.periodFromTs"
              class="match-making-leaderboard__input"
              position="top"
              :has-time="true"
              :required="true"
              :error-message="validationErrors('period')"
              :placeholder="$t('tournaments.leaderboard_date_start')"
              :label="$t('tournaments.leaderboard_reg_start')"
            />
            <pvp-tooltip>
              <icon name="info" />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_leaderboard_settings_reg_start',
                  )
                }}
              </template>
            </pvp-tooltip>
          </div>
          <!-- Datapicker "Время окончания лидерборда" -->
          <div class="inline">
            <pvp-datepicker
              v-model="leaderboardPayload.periodToTs"
              class="match-making-leaderboard__input"
              position="top"
              :has-time="true"
              :required="true"
              :error-message="validationErrors('period')"
              :placeholder="$t('tournaments.leaderboard_date_end')"
              :label="$t('tournaments.leaderboard_reg_end')"
            />
            <pvp-tooltip>
              <icon name="info" />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_leaderboard_settings_reg_end',
                  )
                }}
              </template>
            </pvp-tooltip>
          </div>
        </template>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.start"
            required
            :error-message="validationErrors('system.start')"
            :label="$t('tournaments.initial_value')"
          />
        </div>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.victory"
            required
            :error-message="validationErrors('system.victory')"
            :label="$t('tournaments.scores_for_win')"
          />
        </div>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.defeat"
            required
            :error-message="validationErrors('system.defeat')"
            :label="$t('tournaments.scores_for_loose')"
          />
        </div>

        <div class="inline">
          <BaseInput
            v-model.number="leaderboardPayload.system.tp"
            required
            :error-message="validationErrors('system.tp')"
            :label="$t('matches.tech_shortDefeat')"
          />
        </div>
      </div>
    </div>
    <div class="match-making-leaderboard__footer">
      <div class="match-making-leaderboard__wrapper">
        <Button
          tag="button"
          size="regular"
          type="primary"
          text="Cохранить"
          :loading="isLoading.leaderboard"
          @click="save"
        >
        </Button>

        <InfoBox
          v-if="info"
          :variant="info.state"
          class="match-making-leaderboard-settings__info"
        >
          {{ info.message }}
        </InfoBox>

        <InfoBox
          v-if="isError && errors['forbidden']"
          class="match-making-leaderboard-settings__error"
          variant="error"
        >
          {{ errors['forbidden'].title }}
        </InfoBox>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'MatchMakingLeaderboardSettings',
  components: { Button, BaseSelect, Icon, BaseInput, InfoBox },
  props: {
    tnId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      leaderboardPayload: {
        type: 'season',
        name: '',
        periodFromTs: null,
        periodToTs: null,
        systemId: '',
        system: {},
      },
      supportedSystems: [],
      defaultSystemId: 1,
      defaultSettings: {},
      isError: false,
      errorText: '',
      errors: {},
      info: null,
      isLoading: {
        page: false,
        leaderboard: false,
      },
    };
  },
  computed: {
    isSeason() {
      return this.leaderboardPayload.isSeason ?? false;
    },
    hasLeaderboard() {
      return _.isObject(this.leaderboard);
    },
    leaderboardId() {
      return this.$route.params.id;
    },
    organizationId() {
      return this.$route.params.orgId;
    },
  },
  async mounted() {
    await this.fetchSystemIds();
    if (this.leaderboardId !== 'new') {
      await this.fetchLeaderboardData();
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    validationErrors(name) {
      return Object.keys(this.errors).length
        ? this.errors[name]?.detail
        : '';
    },
    async fetchSystemIds() {
      this.isLoading.page = true;
      const url = `organization/${this.organizationId}/tournament/${this.tnId}/rating/params`;
      const {
        data: { supportedSystems, defaultSystemId },
      } = await api.get(url);
      this.isLoading.page = false;

      this.supportedSystems = supportedSystems;
      this.leaderboardPayload.systemId = defaultSystemId;
      this.leaderboardPayload.system = supportedSystems[0].settings;
      return { supportedSystems, defaultSystemId };
    },
    async fetchLeaderboardData() {
      const url = `organization/${this.organizationId}/tournament/${this.tnId}/rating/${this.leaderboardId}`;
      try {
        this.isLoading.page = true;
        this.isErrror = false;
        this.errorText = '';
        const {
          data: { rating },
        } = await api.get(url);
        const leaderboardPayload = JSON.parse(
          JSON.stringify({
            ...this.leaderboardPayload,
            ...rating,
          }),
        );
        delete leaderboardPayload.id;
        this.leaderboardPayload = leaderboardPayload;
      } catch (err) {
        this.isErrror = true;
        const errorText = err.error[0].detail;
        this.errorText = errorText;
      } finally {
        this.isLoading.page = false;
      }
    },
    async createNewLeaderboard(payload) {
      const url = `organization/${this.organizationId}/tournament/${this.tnId}/rating`;
      await api.post(url, payload);
      this.goBack();
    },
    async editLeaderboard(payload) {
      const url = `organization/${this.organizationId}/tournament/${this.tnId}/rating/${this.leaderboardId}`;
      await api.put(url, payload);
    },
    async save() {
      if (this.leaderboardId === 'new') {
        try {
          this.isLoading.leaderboard = true;
          this.isError = false;
          this.errorText = '';
          await this.createNewLeaderboard(this.leaderboardPayload);
          this.errors = {};
          this.info = {
            state: 'success',
            message: this.$t('save.success'),
          };
        } catch (err) {
          this.isError = true;
          this.errors = err.error.reduce(
            (acc, obj) => ({ ...acc, [obj.source]: { ...obj } }),
            {},
          );
        } finally {
          this.isLoading.leaderboard = false;
          setTimeout(() => {
            this.info = null;
          }, 2000);
        }
      } else {
        const payloadForEditLeaderboard = {
          ...this.leaderboardPayload,
        };
        delete payloadForEditLeaderboard.type;

        try {
          this.isError = false;
          this.isLoading.leaderboard = true;
          this.errorText = '';
          await this.editLeaderboard(payloadForEditLeaderboard);
          this.errors = {};
          this.info = {
            state: 'success',
            message: this.$t('save.success'),
          };
        } catch (err) {
          this.isError = true;
          this.errors = err.error.reduce(
            (acc, obj) => ({ ...acc, [obj.source]: { ...obj } }),
            {},
          );
        } finally {
          this.isLoading.leaderboard = false;
          setTimeout(() => {
            this.info = null;
          }, 2000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.match-making-leaderboard-settings {
  background: var(--main-color-darkgray);
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
}
.match-making-leaderboard__wrapper {
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 42px;
  padding-bottom: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-8 {
  margin-bottom: 8px;
}
.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}
.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 420px;
  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 0.5em;
    font-size: 18px;
  }
}
.match-making-leaderboard-settings__header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}
.match-making-leaderboard__footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.match-making-leaderboard-settings__info,
.match-making-leaderboard-settings__error {
  margin-top: 12px;
}
.match-making-leaderboard__input {
  width: 420px;
}
</style>
