<template>
  <div class="final-round">
    <BaseSwitcher
      v-model="needFinalRound"
      :disabled="!abilities.canEditFinalRoundsIds"
      class="indent"
      @input="fetchBracket"
    >
      <span slot="label" class="info">
        {{ $t('round.incomplete_conduct') }}
        <pvp-tooltip>
          <icon name="info" inline class="icon" />
          <template slot="tooltip">
            {{ $t('round.incomplete_conduct_info') }}
          </template>
        </pvp-tooltip>
      </span>
    </BaseSwitcher>

    <template v-if="isFinalRoundVisible">
      <BaseSelect
        v-model="firstRound"
        class="settings-control"
        :disabled="!abilities.canEditFinalRoundsIds"
        :label="
          $t(
            isDe
              ? 'round.tournament_finish_winners'
              : 'round.tournament_finish',
          )
        "
        :placeholder="$t('round.select_single')"
        :options="rounds.winners || rounds"
        value-key="id"
        label-key="name"
        @input="updateData"
      />

      <BaseSelect
        v-if="isDe"
        v-model="secondRound"
        class="settings-control"
        :disabled="!abilities.canEditFinalRoundsIds"
        :label="$t('round.tournament_finish_losers')"
        :placeholder="$t('round.select_single')"
        :options="rounds.losers"
        value-key="id"
        label-key="name"
        @input="updateData"
      />
    </template>
  </div>
</template>

<script>
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'FinalRoundSettings',
  components: {
    BaseSwitcher,
    Icon,
    BaseSelect,
  },
  data: () => ({
    needFinalRound: false,
    firstRound: null,
    secondRound: null,
  }),
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapState('tournaments', ['availableSystems']),
    ...mapGetters('tournaments', ['getTournamentBracket']),

    isFinalRoundVisible() {
      return this.needFinalRound && this.rounds;
    },

    tnId() {
      return this.$route.params?.tnId;
    },

    isDe() {
      const { code } =
        this.availableSystems[this.form.idSystem.value] || {};
      return code === 'double_elim';
    },

    rounds() {
      return this.getTournamentBracket(this.tnId);
    },
  },
  created() {
    this.needFinalRound = Boolean(
      this.form?.finalRoundsIds?.value?.length,
    );
    if (this.needFinalRound) {
      this.fetchBracket();
    }
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    ...mapActions('tournaments', ['fetchTournamentBracket']),

    fetchBracket() {
      if (this.needFinalRound) {
        if (this.rounds === undefined) {
          this.fetchTournamentBracket(this.tnId).then(this.setData);
        } else {
          this.setData();
        }
      } else {
        this.updateData();
      }
    },

    setData() {
      if (this.isDe) {
        const [first, second] = this.form.finalRoundsIds?.value;
        const isFirstWin = this.rounds?.winners.some(
          ({ id }) => id === first,
        );
        this.firstRound = isFirstWin ? first : second;
        this.secondRound = isFirstWin ? second : first;
      } else {
        this.firstRound = this.form.finalRoundsIds?.value?.[0];
      }
    },

    updateData() {
      const value =
        (this.needFinalRound &&
          [this.firstRound, this.secondRound].filter((id) => id)) ||
        [];
      this.updateFormData({
        name: this.form.finalRoundsIds.name,
        value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.final-round {
  margin-top: 20px;
}
.settings-control {
  max-width: 420px;
}
.icon {
  vertical-align: middle;
  margin-left: 4px;
}
</style>
