<template>
  <loader v-if="isLoading" />
  <div v-else-if="isBracketVisible" class="bracket">
    <template v-if="system === 'subgroups' || system === 'swiss'">
      <tournament-schedule-table
        v-for="(table, key) in rounds"
        :id="tnId"
        :key="key"
        :table="table.rounds"
        :title="table.name"
        :dropdown-disabled="rounds.length < 2"
      >
        <template #round="{ round }">
          <span class="subgroups-round">
            {{ round.name }}
            <div
              v-if="round.tsStartTime > now"
              class="edit"
              @click="editRound(round)"
            >
              <Icon name="pencil-round" inline />
            </div>
          </span>
        </template>
        <template #match="{ match }">
          <div class="table-column subgroups-link">
            <pvp-btn
              v-if="match.id"
              variant="clear"
              @click="editMatch(match)"
            >
              <Icon name="pencil-round" inline />
            </pvp-btn>
            <BaseLink
              v-if="match.id"
              icon-right="arrow-right"
              class="name"
              :to="{ name: 'match', params: { id: match.id } }"
            >
              {{ $t('matches.single') }}
            </BaseLink>
          </div>
        </template>
      </tournament-schedule-table>
      <!--  -->
    </template>

    <component
      :is="bracket"
      v-else-if="hasBracket"
      :tournament-id="tnId"
      :rounds="rounds"
      controls-disabled
    >
      <template #round="{ round }">
        <div v-if="round.name" class="round">
          <span class="name">
            {{ round.name }}
            <pvp-tooltip v-if="round.description" class="info">
              <Icon name="info" inline />
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="tooltip"
                class="info-tooltip"
                v-html="round.description"
              />
            </pvp-tooltip>
            <template v-if="hasRoundEditRestrictions">
              <pvp-btn
                v-if="round.tsStartTime > now"
                variant="clear"
                class="edit"
                @click="editRound(round)"
              >
                <Icon name="pencil-round" inline />
              </pvp-btn>
            </template>
            <pvp-btn
              v-else
              variant="clear"
              class="edit"
              @click="editRound(round)"
            >
              <Icon name="pencil-round" inline />
            </pvp-btn>
          </span>
          <template v-if="system === 'team_ffa' && round.current">
            <pvp-btn
              v-if="round.prevRoundId && abilities.canMoveResetRound"
              :is-loading="roundTransfer.loading"
              class="round-transfer"
              variant="secondary"
              @click="roundTransfer.cancelId = round.id"
            >
              {{
                $t('["widget.bracket"].action_reset_move_next_round')
              }}
            </pvp-btn>
            <pvp-btn
              v-if="round.nextRoundId && abilities.canMoveToNextRound"
              :is-loading="roundTransfer.loading"
              class="round-transfer"
              @click="transferRound(round.id, 'start')"
            >
              {{ $t('["widget.bracket"].action_move_next_round') }}
            </pvp-btn>
            <div
              v-if="roundTransfer.error"
              class="round-transfer-error"
            >
              {{ roundTransfer.error }}
            </div>
          </template>
          <div class="time">
            {{ $dt(round.tsStartTime, 'dd_MM_HH_mm') }}
          </div>
          <div class="description">
            <template
              v-if="round.matchesInfo && round.matchesInfo.text"
            >
              {{ round.matchesInfo.text }}
            </template>
            <template v-else-if="round.gameMap">
              {{ round.gameMap.name }}
            </template>
          </div>
        </div>
      </template>

      <template #match="{ match }">
        <div class="match" :class="system">
          <pvp-btn
            v-if="match.id"
            class="edit"
            variant="clear"
            @click="editMatch(match)"
          >
            <Icon name="pencil-round" inline />
          </pvp-btn>
          <template v-if="system === 'team_ffa'">
            <BaseLink
              v-if="match.id"
              class="name"
              :to="{ name: 'match', params: { id: match.id } }"
              >{{ match.name }}
            </BaseLink>
            <span class="time">{{
              $dt(match.tsStartTime, 'dd_MM_HH_mm')
            }}</span>
          </template>
          <match-box v-else :is-edit="true" :match="match" />
        </div>
      </template>
    </component>

    <match-edit-popup
      v-if="selectedMatch"
      :value="isMatchEditPopupOpen"
      :match="selectedMatch"
      @input="editMatch"
    />

    <round-edit-popup
      v-if="selectedRound"
      :value="isRoundEditPopupOpen"
      :round="selectedRound"
      @input="editRound"
    />

    <pvp-modal
      v-if="roundTransfer.cancelId"
      :value="true"
      :width="580"
      class="round-transfer-modal"
      @input="roundTransfer.cancelId = null"
    >
      <template #modal-title>
        {{ $t('["widget.bracket"].round_transfer_cancel_title') }}
      </template>
      <p class="modal-text">
        {{
          $t('["widget.bracket"].round_transfer_cancel_description')
        }}
      </p>
      <template #modal-footer>
        <pvp-btn
          variant="secondary"
          @click="roundTransfer.cancelId = null"
        >
          {{ $t('global.back') }}
        </pvp-btn>
        <pvp-btn
          @click="transferRound(roundTransfer.cancelId, 'reset')"
          >{{ $t('global.confirm') }}</pvp-btn
        >
      </template>
    </pvp-modal>
  </div>
</template>

<script>
import TournamentScheduleTable from '@components/TournamentComponents/TournamentScheduleTable.vue';
import SingleElimination from '@components/TournamentComponents/Brackets/SingleElimination.vue';
import DoubleElimination from '@components/TournamentComponents/Brackets/DoubleElimination.vue';
import TeamFfa from '@components/TournamentComponents/Brackets/TeamFFA.vue';
import MatchEditPopup from '@components/TournamentComponents/Settings/MatchEditPopup.vue';
import RoundEditPopup from '@components/TournamentComponents/Settings/RoundEditPopup.vue';
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import MatchBox from '@components/TournamentComponents/Brackets/BracketUI/MatchBox.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'BracketMatchSettings',
  components: {
    Icon,
    MatchBox,
    RoundEditPopup,
    MatchEditPopup,
    TournamentScheduleTable,
    CellSimple,
    CellHeader,
    TeamFfa,
    DoubleElimination,
    SingleElimination,
    BaseLink,
  },
  data: () => ({
    isLoading: false,
    dragTeam: {},
    cartTeams: [],
    overCart: false,
    isMatchEditPopupOpen: false,
    selectedMatch: null,
    isRoundEditPopupOpen: false,
    selectedRound: null,
    now: Date.now() / 1000,
    roundTransfer: {
      cancelId: null,
      error: '',
      loading: false,
    },
  }),
  computed: {
    ...mapState('tournamentSettings', ['abilities']),
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentBracket',
    ]),

    tnId() {
      return this.$route.params.tnId;
    },

    tournament() {
      return this.getTournament(this.tnId);
    },

    hasRoundEditRestrictions() {
      return ['executing', 'cancelled', 'finished'].includes(
        this.tournament?.status?.code,
      );
    },

    isBracketVisible() {
      return this.tournament?.isBracketFormed;
    },

    rounds() {
      const rounds = this.getTournamentBracket(this.tnId);

      if (this.system === 'subgroups') {
        return (
          rounds?.detail?.map((item, index) => ({
            name: `${this.$t('tournaments.scheduleTable_group')} ${
              index + 1
            }`,
            rounds: item.rounds,
          })) || []
        );
      }

      if (this.system === 'swiss') {
        return [
          {
            name: '',
            rounds: rounds,
          },
        ];
      }

      return rounds;
    },

    hasBracket() {
      if (
        (_.isArray(this.rounds) && !this.rounds.length) ||
        (this.system === 'double_elim' &&
          !this.rounds.winners.length &&
          !this.rounds.loosers.length)
      ) {
        return false;
      }
      return true;
    },

    system() {
      return this.tournament?.system?.code;
    },

    bracket() {
      switch (this.system) {
        case 'single':
          return 'single-elimination';
        case 'double_elim':
          return 'double-elimination';
        case 'team_ffa':
          return 'team-ffa';
        default:
          return '';
      }
    },
  },

  watch: {
    isBracketVisible: {
      handler(isVisible) {
        if (isVisible) {
          this.isLoading = true;
          this.fetchTournamentBracket(this.tnId).finally(() => {
            this.isLoading = false;
          });
        }
      },
      immediate: true,
    },
    rounds() {
      this.$set(this.roundTransfer, 'loading', false);
    },
  },

  methods: {
    ...mapActions('tournaments', [
      'fetchSubgroups',
      'fetchTournamentBracket',
    ]),

    editRound(round) {
      this.selectedRound = round || null;
      this.isRoundEditPopupOpen = Boolean(round);
    },

    editMatch(match) {
      this.selectedMatch = match || null;
      this.isMatchEditPopupOpen = Boolean(match);
    },

    transferRound(roundId, status) {
      this.roundTransfer.cancelId = null;
      this.roundTransfer.error = '';
      this.roundTransfer.loading = true;
      api
        .post(`/round/${roundId}/transfer/${status}`)
        .catch(({ error }) => {
          this.roundTransfer.error = (() => {
            if (_.isArray(error)) {
              return error.join(', ');
            }
            return error || this.$t('validator.some_errors');
          })();
          this.roundTransfer.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loader,
.bracket {
  margin-top: 24px;
}

.menu {
  @include min-desktop() {
    flex-shrink: 0;
    width: 380px;
  }
}

.match {
  flex-shrink: 0;
  position: relative;

  &.single,
  &.double_elim {
    height: em(80px);

    .edit {
      width: em(20px);
      right: em(45px);
      z-index: 2;
    }
  }

  &.team_ffa {
    height: 100%;

    .edit {
      right: 0;
      width: em(40px);
    }
  }

  .icon {
    border: 1px solid $dark-two;
    background-color: $dark-two;
    border-radius: 50%;
  }

  .edit,
  .info {
    position: absolute;
    color: white;
    top: 0;
    height: 100%;
    font-size: em(20px);
  }

  .info {
    right: 0;
    width: em(40px);
    color: $slate-grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit {
    color: $azure;
  }
}

.team {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: em(10px);
  padding-right: em(14px);
  color: rgba(white, 0.4);
  background-color: rgba($dark-two, 0.4);

  &.active {
    border-radius: 4px 4px 0 0;
    background-color: rgba($white, 0.1);
    color: $azure;
  }

  & + .active {
    border-radius: 0 0 4px 4px;
  }
}

.round {
  .button {
    font-size: inherit;

    .icon {
      font-size: em(18px);
      margin-left: em(5px);
    }
  }

  .edit {
    color: $azure;
    margin-left: auto;
  }

  &-transfer {
    margin: 8px 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    text-align: center;

    &-modal {
      text-align: center;

      .modal-text {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
      }

      .button {
        width: 160px;
        & + .button {
          margin-left: 12px;
        }
      }
    }

    &-error {
      font-size: 14px;
      line-height: 1.2;
      color: $orangey-red;
      margin-bottom: 4px;
    }
  }
}

.subgroups {
  &-round {
    display: flex;

    .icon {
      font-size: 20px;
      margin-left: 5px;
      color: $azure;
    }
  }

  &-link {
    @include min-laptop() {
      justify-content: flex-end;
    }

    .base-link {
      margin-left: 20px;
    }

    .button {
      font-size: 20px;
      color: $azure;
    }
  }
}

.match-name,
.time {
  display: block;
  font-size: em(13px);
  color: rgba(white, 0.43);
}

.match-name {
  margin-right: 8px;
}
</style>
