var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"indent settings-control",attrs:{"value":_vm.form.minRank.value,"label":_vm.$t('players.min-rank'),"options":_vm.rankList,"error-message":_vm.form.minRank.error,"required":true},on:{"input":function($event){return _vm.updateFormData({ name: _vm.form.minRank.name, value: $event })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-min-rank'))+" ")])],2)],1),(_vm.abilities.canEditNeedRatingRankRestriction)?_c('BaseSwitcher',{attrs:{"value":_vm.form.needRatingRankRestriction.value},on:{"input":function($event){return _vm.updateFormData({
        name: _vm.form.needRatingRankRestriction.name,
        value: $event,
      })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.wf_leagues_available'))+" "),_c('pvp-tooltip',{staticClass:"info"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_leagues_wf'))+" ")])],2)],1)],2):_vm._e(),(_vm.form.needRatingRankRestriction.value)?_c('div',{staticClass:"indent"},[_c('div',{staticClass:"leagues-label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.wf_leagues_list'))+" ")]),_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.ratingRankRestriction.value,"disabled":!_vm.abilities.canEditNeedRatingRankRestriction,"placeholder":_vm.$t('tournaments.select_leagua_placeholder'),"list-position":"bottom","multiple":true,"limit":7,"value-key":"value","label-key":"label","options":_vm.leagues},on:{"input":function($event){return _vm.updateFormData({
          name: _vm.form.ratingRankRestriction.name,
          value: $event,
        })}}})],1):_vm._e(),_c('div',[_c('BaseSwitcher',{attrs:{"value":_vm.form.checkGameProfileRegion.value,"error-message":_vm.form.checkGameProfileRegion.error,"disabled":!_vm.abilities.canEditCheckGameProfileRegion},on:{"input":function($event){return _vm.updateFormData({
          name: _vm.form.checkGameProfileRegion.name,
          value: $event,
        })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('games.server'))+" "),_c('pvp-tooltip',{staticClass:"info"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-server'))+" ")])],2)],1)],2)],1),(_vm.form.checkGameProfileRegion.value)?_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.regionCode.value,"label":_vm.$t('games.server'),"disabled":!_vm.abilities.canEditRegionCode,"error-message":_vm.form.regionCode.error,"options":_vm.regionData,"value-key":"code","label-key":"label","required":true},on:{"input":function($event){return _vm.updateFormData({ name: _vm.form.regionCode.name, value: $event })}}}):_vm._e(),(_vm.form.pickBanSystem)?_c('BaseSwitcher',{attrs:{"value":_vm.form.pickBanSystem.value,"disabled":!_vm.form.checkinMatchEnable.value,"error-message":_vm.form.pickBanSystem.error},on:{"input":function($event){return _vm.updateFormData({
        name: _vm.form.pickBanSystem.name,
        value: $event,
      })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('matches.wf_map_picking'))+" "),_c('pvp-tooltip',{staticClass:"info"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-maps'))+" ")])],2)],1)],2):_vm._e(),(_vm.form.pickBanSystem && _vm.form.pickBanSystem.value)?[(_vm.form.pickBanTimeout)?_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.pickBanTimeout.value,"label":_vm.$t('matches.wf_map_picking-time'),"options":_vm.pickbanTimeoutsList,"error-message":_vm.form.pickBanTimeout.error,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.pickBanTimeout.name,
            value: $event,
          })}},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$tc('date.seconds_pluralization', option))+" ")]}},{key:"selected-option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$tc('date.seconds_pluralization', option))+" ")]}}],null,false,2712626445)}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-pick-time'))+" ")])],2)],1):_vm._e(),(_vm.form.gameMaps)?_c('div',{staticClass:"maps indent"},[_c('div',{staticClass:"maps-label"},[_vm._v(" "+_vm._s(_vm.$t('matches.wf_map_list'))+" "),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-maps-list'))+" ")])],2)],1),(_vm.maps.length)?_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.gameMaps.value,"placeholder":_vm.$t('games.select_map'),"list-position":"bottom","multiple":true,"limit":7,"error-message":_vm.form.gameMaps.error,"value-key":"code","label-key":"name","options":_vm.maps},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.gameMaps.name,
            value: $event,
          })}}}):_vm._e()],1):_vm._e(),(_vm.form.gameType)?_c('div',{staticClass:"inline"},[_c('tournament-format-selector',{staticClass:"indent settings-control",attrs:{"value":_vm.form.gameType.value,"disabled":!_vm.form.pickBanSystem.value,"error-message":_vm.form.gameType.error},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.gameType.name,
            value: $event,
          })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-best-of'))+" ")])],2)],1):_vm._e(),(
        _vm.form.lastMapPickSideFormat &&
        _vm.form.gameType &&
        _vm.form.gameType.value !== 2
      )?_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.lastMapPickSideFormat.value,"label":_vm.$t('games.last_map_pick_side_label'),"error-message":_vm.form.lastMapPickSideFormat.error,"options":_vm.lastMapPickSide,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.lastMapPickSideFormat.name,
            value: $event,
          })}},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$t(`games.last_map_pick_side_${option}`))+" ")]}},{key:"selected-option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$t(`games.last_map_pick_side_${option}`))+" ")]}}],null,false,2810229389)})],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }