<template>
  <pvp-modal :width="500" v-bind="$attrs" v-on="$listeners">
    <template #modal-title>{{
      $t('matches.change_single')
    }}</template>
    <BaseForm @submit="editMatch">
      <pvp-datepicker
        v-model="date"
        :has-time="true"
        :error-message="error.time"
        :label="$t('matches.time_start')"
        position="fixed"
      />
      <BaseInput
        v-model="description"
        :label="$t('matches.description')"
        :is-area="true"
      />

      <pvp-btn type="submit">{{ $t('global.apply') }}</pvp-btn>

      <InfoBox v-if="error.common" variant="error">
        {{ error.common }}
      </InfoBox>
    </BaseForm>
  </pvp-modal>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'MatchEditPopup',
  components: {
    BaseInput,
    BaseForm,
    InfoBox,
  },
  props: {
    match: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    date: null,
    description: '',
    error: {},
  }),

  computed: {
    ...mapGetters('matches', ['getMatch']),
  },

  created() {
    this.date = this.match?.tsStartTime;
    this.fetchMatch(this.match.id).then(() => {
      this.description =
        this.getMatch(this.match.id)?.description || '';
    });
  },

  methods: {
    ...mapActions('tournaments', ['fetchTournamentBracket']),
    ...mapActions('matches', ['fetchMatch']),
    editMatch() {
      this.error = {};
      return api
        .patch(`/match/${this.match.id}/tuning/edit`, {
          time: this.date,
          description: this.description,
        })
        .then((data) => {
          if (data.success) {
            this.$emit('input', false);
            return this.fetchTournamentBracket(
              this.$route.params.tnId,
            );
          }
          return false;
        })
        .catch(({ error }) => {
          if (_.isArray(error)) {
            this.error = {
              common: error?.join(', '),
            };
          } else {
            this.error = error;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin: 20px auto 0;
  display: block;
  width: 180px;
}

.pvp-info-box {
  margin-top: 12px;
}
</style>
