var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.form.pickBanSystem)?_c('BaseSwitcher',{attrs:{"value":_vm.form.pickBanSystem.value,"disabled":!_vm.form.checkinMatchEnable.value,"error-message":_vm.form.pickBanSystem.error},on:{"input":function($event){return _vm.updateFormData({
        name: _vm.form.pickBanSystem.name,
        value: $event,
      })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('matches.wf_map_picking'))+" "),_c('pvp-tooltip',{staticClass:"info"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-maps'))+" ")])],2)],1)],2):_vm._e(),(_vm.form.pickBanSystem && _vm.form.pickBanSystem.value)?[(_vm.form.pickBanTimeout)?_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.pickBanTimeout.value,"label":_vm.$t('matches.wf_map_picking-time'),"options":_vm.pickbanTimeoutsList,"error-message":_vm.form.pickBanTimeout.error,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.pickBanTimeout.name,
            value: $event,
          })}},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$tc('date.seconds_pluralization', option))+" ")]}},{key:"selected-option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$tc('date.seconds_pluralization', option))+" ")]}}],null,false,2712626445)}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-pick-time'))+" ")])],2)],1):_vm._e(),(_vm.form.gameMaps)?_c('div',{staticClass:"maps indent"},[_c('div',{staticClass:"maps-label"},[_vm._v(" "+_vm._s(_vm.$t('matches.wf_map_list'))+" "),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-maps-list'))+" ")])],2)],1),(_vm.maps.length)?_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.gameMaps.value,"placeholder":_vm.$t('matches.wf_map_list'),"multiple":true,"list-position":"bottom","error-message":_vm.form.gameMaps.error,"value-key":"code","label-key":"name","options":_vm.maps},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.gameMaps.name,
            value: $event,
          })}}}):_vm._e()],1):_vm._e(),(_vm.form.gameType)?_c('div',{staticClass:"inline"},[_c('tournament-format-selector',{staticClass:"indent settings-control",attrs:{"value":_vm.form.gameType.value,"disabled":!_vm.form.pickBanSystem.value,"error-message":_vm.form.gameType.error},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.gameType.name,
            value: $event,
          })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-best-of'))+" ")])],2)],1):_vm._e(),(
        _vm.form.lastMapPickSideFormat &&
        _vm.form.gameType &&
        _vm.form.gameType.value !== 2
      )?_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.lastMapPickSideFormat.value,"label":_vm.$t('games.last_map_pick_side_label'),"error-message":_vm.form.lastMapPickSideFormat.error,"options":_vm.lastMapPickSide,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.lastMapPickSideFormat.name,
            value: $event,
          })}},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$t(`games.last_map_pick_side_${option}`))+" ")]}},{key:"selected-option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$t(`games.last_map_pick_side_${option}`))+" ")]}}],null,false,2810229389)})],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }