<template>
  <div class="participants settings">
    <div class="content-bg">
      <div class="participants-header">
        <h2>{{ $t('participants.few') }}</h2>
        <pvp-btn>{{ $t('["team.invite"].title') }}</pvp-btn>
      </div>

      <loader v-if="isLoading" />
      <template v-else-if="participants.length">
        <div class="pvp-table stripped adaptive">
          <div class="table-header">
            <div class="table-row">
              <cell-header>№</cell-header>
              <cell-header t="teams.edit_labels_teamName" />
              <cell-header t="tournaments.registerDate" />
              <cell-header t="team.th_status" />
              <cell-header t="global.action" />
            </div>
          </div>
          <div class="table-body">
            <div
              v-for="(participant, key) in participants"
              :key="key"
              class="table-row"
            >
              <cell-simple>
                <template #title>№</template>
                {{ key + participantStartNumber }}
              </cell-simple>
              <cell-simple t="['admin.panel'].name">
                {{ participant.teamName }}
              </cell-simple>
              <cell-simple class="date" t="date.single">
                {{ participant.regDate }}
              </cell-simple>
              <cell-simple t="global.status">
                <pvp-status-text
                  v-if="participant.status"
                  :status="participant.status"
                >
                  {{ participant.statusText }}
                </pvp-status-text>
              </cell-simple>
              <cell-simple>
                <pvp-btn variant="secondary">
                  {{ $t('["organization.tn.participant"].exclude') }}
                </pvp-btn>
              </cell-simple>
            </div>
          </div>
        </div>
        <pvp-pagination
          :params="paginationParams"
          @onchange="onPaginationChange"
        />
      </template>
      <div v-else class="empty">
        <icon class="empty-icon" name="pvp-swords" />
        <div class="empty-text">
          {{ $t('participants.no_team_registered') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CellHeader from '@components/TableCells/CellHeader';
import CellSimple from '@components/TableCells/CellSimple';
import PvpStatusText from '@components/BaseComponents/PvpStatusText';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    PvpStatusText,
    CellSimple,
    CellHeader,
    Icon,
  },
  data: () => ({
    isLoading: true,
    participants: [],
    page: 1,
    perPage: 12,
    total: 0,
  }),
  computed: {
    ...mapGetters('tournamentSettings', ['getWorkflow']),

    tnId() {
      return Number(this.$route?.params?.tnId);
    },

    orgId() {
      return Number(this.$route?.params?.orgId);
    },

    tnState() {
      return this.getWorkflow(this.tnId)?.currentState;
    },

    participantStartNumber() {
      return (
        1 +
        (this.paginationParams.page - 1) *
          this.paginationParams.perPage
      );
    },

    paginationParams() {
      return {
        page: this.page,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: [12, 36, 72],
      };
    },
  },
  created() {
    this.fetchParticipants();
  },
  methods: {
    onPaginationChange({ page, perPage }) {
      this.page = page;
      this.perPage = perPage;
      this.fetchParticipants();
    },

    fetchParticipants() {
      this.isLoading = true;
      return api
        .get(
          `/organization/${this.orgId}/tournament/${this.tnId}/seedinglist`,
          {
            params: {
              page: this.page,
              limit: this.perPage,
            },
          },
        )
        .then(({ list, totalItems }) => {
          this.prepareData(list);
          this.total = totalItems;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    prepareData(list) {
      const getStatus = (status) => {
        if (status === 'new') {
          if (this.tnState === 'regopen') {
            return {
              status: 'success',
              statusText: this.$t(
                'participants.team_status_registered',
              ),
            };
          }
          if (this.tnState === 'confirmation') {
            return {
              status: 'success',
              statusText: this.$t(
                'participants.team_status_confirmed',
              ),
            };
          }
        }
        if (status === 'participates') {
          return {
            status: 'warning',
            statusText: this.$t('team.tn_out'),
          };
        }

        return {
          status: '',
          statusText: '',
        };
      };

      this.participants = list?.map((item) => ({
        ...item,
        ...getStatus(item.participationStatus),
        regDate: this.$dt(item.registeredTime, 'dd_MMMM_YYYY_HH_mm'),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.button {
  width: 100%;
}

.participants {
  .content-bg {
    padding-bottom: 0;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include min-laptop() {
      padding: 42px 48px 30px;
      .button {
        max-width: 250px;
      }
    }
    @include max-laptop() {
      padding: 42px 12px 30px;
      .button {
        width: auto;
      }
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-body {
    .table-column:first-of-type {
      @include min-laptop() {
        font-size: 14px;
      }
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 0.4fr 1fr 1fr 1fr 0.8fr;
    }
  }

  &-column {
    overflow: hidden;
    padding-left: 4px;
    padding-right: 4px;

    &:nth-last-of-type(n + 2) {
      ::v-deep .column-title {
        width: 90px;
      }
    }
  }
}

.pagination {
  margin-top: 40px;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  &-icon {
    font-size: 80px;
    color: $dark;
  }

  &-text {
    text-align: center;
    margin-top: 12px;
    color: rgba(white, 0.5);
  }
}

.date {
  font-size: 14px;
  color: rgba(white, 0.4);
}
</style>
