<template>
  <div>
    <BaseSwitcher
      :value="form.ratingRegDuringExec.value"
      :error-message="form.ratingRegDuringExec.error"
      @input="
        updateFormData({
          name: form.ratingRegDuringExec.name,
          value: $event,
        })
      "
    >
      <span slot="label" class="info">
        {{ $t('tournaments.post-registration') }}
        <pvp-tooltip>
          <icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_rating-reg') }}
          </template>
        </pvp-tooltip>
      </span>
    </BaseSwitcher>

    <BaseInput
      v-if="form.systemRatingSystem.value === 4"
      :label="$t('scores.system_rating')"
      :disabled="true"
      value="ELO"
      class="switcher settings-control"
    />
  </div>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'RatingSettings',
  components: {
    BaseInput,
    BaseSwitcher,
    Icon,
  },
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
  },
};
</script>

<style lang="scss" scoped>
.switcher {
  margin-top: 20px;
}
.settings-control {
  max-width: 420px;
}
</style>
