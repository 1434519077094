<template>
  <main-layout>
    <loader v-if="isPageLoading && idSystem" />
    <div v-else class="edit">
      <div v-if="isVisibleHusStatus" class="hub-state">
        <div class="pvp-container">
          <div class="hub-wrapper">
            <pvp-btn
              v-if="state.matches('created')"
              @click="send('PUBLISH')"
            >
              {{ $t('tournaments.announce_hub') }}
            </pvp-btn>
            <pvp-btn
              v-if="state.matches('published')"
              @click="send('START')"
            >
              {{ $t('tournaments.launch_hub') }}
            </pvp-btn>
            <pvp-btn
              v-if="state.matches('executing')"
              @click="send('FINISH')"
            >
              {{ $t('tournaments.finish_hub') }}
            </pvp-btn>
          </div>
        </div>
      </div>
      <div v-if="stateInfo.step && !getIsHub" class="state">
        <div class="pvp-container">
          <steps :step="stateInfo.step" :counts="8" />
          <div class="state-text">
            <!-- eslint-disable vue/no-v-html -->
            <p v-html="stateInfo.title"></p>
          </div>
        </div>
        <div
          v-if="stateInfo.state"
          :key="stateContolKey"
          class="state-controls"
        >
          <pvp-btn
            :disabled="isTimeIncorrect || stateInfo.disabled"
            @click="changeTournamentState"
          >
            {{ stateInfo.button }}
          </pvp-btn>
          <p v-if="isTimeIncorrect" class="state-error">
            {{ $t('tournament.timeErrorMsg') }}
          </p>
          <template v-else>
            <p
              v-for="(message, index) in errorMessages"
              :key="index"
              class="state-error"
            >
              {{ message }}
            </p>
          </template>
        </div>
      </div>

      <div v-if="form" class="pvp-container">
        <div class="header">
          <h1 class="header__title">{{ headerTitle }}</h1>
          <tournament-publishing class="header__right" />
          <div class="header__left">
            <BaseLink
              class="button"
              target="_blank"
              :to="{ name: 'tournament', params: { id: tnId } }"
              icon-right="visible"
            />
            <pvp-btn
              variant="clear"
              icon-right="clone"
              @click="isCloneOpen = true"
            />
            <pvp-btn
              v-if="canCancel"
              variant="clear"
              icon-right="trash"
              @click="isTnCancelOpen = true"
            />
          </div>
        </div>

        <div class="wrapper">
          <div class="menu">
            <tournament-settings-menu
              v-if="gameCode"
              :form="form"
              :game-code="gameCode"
            />
          </div>
          <router-view class="content" />
        </div>
      </div>
    </div>
    <pvp-modal
      v-model="isRegCancelOpen"
      width="580"
      class="reg-cancel"
    >
      <template slot="modal-title">{{
        $t('tournaments.state_to_reg-cancel_title')
      }}</template>
      <p class="desc">
        {{ $t('tournaments.state_to_reg-cancel_desc') }}
      </p>
      <template slot="modal-footer">
        <pvp-btn variant="secondary" @click="isRegCancelOpen = false">
          {{ $t('global.back') }}
        </pvp-btn>
        <pvp-btn @click="changeTournamentWorkflow(stateInfo.state)">
          {{ $t('global.confirm') }}
        </pvp-btn>
      </template>
    </pvp-modal>

    <pvp-modal
      v-model="isTnCancelOpen"
      width="580"
      class="reg-cancel"
    >
      <template slot="modal-title">{{
        $t('tournaments.state_to_tournament-cancel_title')
      }}</template>
      <p class="desc">
        {{ $t('tournaments.state_to_tournament-cancel_desc') }}
      </p>
      <template slot="modal-footer">
        <pvp-btn variant="secondary" @click="isTnCancelOpen = false">
          {{ $t('global.back') }}
        </pvp-btn>
        <pvp-btn
          v-if="!getIsHub"
          @click="changeTournamentWorkflow('cancelled')"
        >
          {{ $t('global.confirm') }}
        </pvp-btn>
        <pvp-btn v-else @click="closeHub">
          {{ $t('global.confirm') }}
        </pvp-btn>
      </template>
    </pvp-modal>

    <tournament-creation
      v-model="isCloneOpen"
      :id-clone="tnId"
      :organization-id="orgId"
    />
  </main-layout>
</template>

<script>
import Steps from '@components/BaseComponents/Steps.vue';
import TournamentCreation from '@components/TournamentComponents/TournamentCreateModal.vue';
import TournamentSettingsMenu from '@components/TournamentComponents/Settings/TournamentSettingsMenu.vue';
import TournamentPublishing from '@components/TournamentComponents/Settings/TournamentPublishing.vue';
import { useMachine } from 'xstate-vue2';
import hubStatusMachine from '@src/machines/hub/hubStatus.js';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'TournamentEdit',
  components: {
    TournamentPublishing,
    TournamentSettingsMenu,
    TournamentCreation,
    Steps,
    BaseLink,
  },
  props: {
    tnId: {
      type: [Number, String],
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      state: null,
      send: null,
      isPageLoading: true,
      isChangesLoading: false,
      isRegCancelOpen: false,
      isTnCancelOpen: false,
      isCloneOpen: false,
      isSecondaryVisible: false,
      success: '',
      errorMessages: [],
      csrf: null,
      stateContolKey: 0,
      dateNow: '',
      dateInterval: '',
    };
  },
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapGetters('tournamentSettings', ['getWorkflow', 'getIsHub']),
    ...mapGetters('organizations', ['getOrganization']),
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentBracket',
    ]),
    ...mapGetters('application', ['getGameCode']),

    gameCode() {
      return this.getGameCode(this.tournament?.idGame);
    },

    workflow() {
      return this.getWorkflow(this.tnId);
    },
    getWorkflowState() {
      return this.workflow?.currentState;
    },
    idSystem() {
      return this.tournament?.idSystem;
    },
    rounds() {
      return this.getTournamentBracket(this.tnId);
    },
    organization() {
      return this.getOrganization(this.orgId);
    },
    tournament() {
      return this.getTournament(this.tnId);
    },
    canCancel() {
      return this.workflow?.transitions?.includes('cancelled');
    },
    isVisibleHusStatus() {
      return this.getIsHub && _.isObject(this.state);
    },
    stateInfo() {
      const status = {
        created: {
          title: this.$t('tournaments.state_to_announce_title'),
          button: this.$t('tournaments.state_to_announce_action'),
          disabled: !this.abilities?.canAnnounce,
          state: this.workflow?.transitions?.find(
            (item) => item === 'published',
          ),
          step: 1,
        },
        published: {
          title: this.$t('tournaments.state_to_reg-open_title'),
          button: this.$t('tournaments.state_to_reg-open_action'),
          disabled: !this.abilities?.canRegistrationOpen,
          state: this.workflow?.transitions?.find(
            (item) => item === 'regopen',
          ),
          step: 2,
        },
        regopen: {
          title: this.$t('tournaments.state_to_reg-active_title'),
          button: this.$t('tournaments.state_to_reg-active_action'),
          state: this.workflow?.transitions?.find(
            (item) => item === 'published',
          ),
          step: 3,
        },
        confirmation: {
          title: this.$t(
            'tournaments.state_to_participation-confirm_title',
          ),
          step: 4,
        },
        bracketform: {
          title: this.$t('tournaments.state_to_bracket-create_title'),
          button: this.$t(
            'tournaments.state_to_bracket-create_action',
          ),
          disabled: !this.abilities?.canBracketForm,
          state: 'preparation',
          step: 5,
        },
        bracketpublish: {
          title: this.$t(
            'tournaments.state_to_bracket-publish_title',
          ),
          button: this.$t(
            'tournaments.state_to_bracket-publish_action',
          ),
          disabled: !this.abilities?.canBracketPublish,
          state: 'preparation',
          step: 6,
        },
        preparation: {
          title: this.$t(
            'tournaments.state_to_tournament-open_title',
          ),
          button: this.$t(
            'tournaments.state_to_tournament-open_action',
          ),
          state: this.workflow?.transitions?.find(
            (item) => item === 'executing',
          ),
          disabled: !this.abilities?.canTournamentStart,
          step: 7,
        },
        executing: {
          title: this.$t(
            'tournaments.state_to_tournament-active_title',
          ),
          step: 8,
        },
      };

      if (this.workflow?.currentState === 'preparation') {
        if (this.tournament?.isBracketFormed === false) {
          return status.bracketform;
        }
        if (this.tournament?.isBracketPublished === false) {
          return status.bracketpublish;
        }
      }
      return (
        status[this.workflow?.currentState] || {
          step: 0,
        }
      );
    },
    isTimeIncorrect() {
      if (
        this.stateInfo?.step === 7 &&
        this.firstRoundStartTime.length
      ) {
        return (
          Date.parse(this.firstRoundStartTime) <
          Date.parse(this.dateNow)
        );
      }
      return false;
    },
    firstRoundStartTime() {
      if (this.rounds?.winners) {
        return new Date(
          this.rounds?.winners[0]?.startTime,
        ).toUTCString();
      }
      if (this.rounds && this.idSystem !== 7) {
        return new Date(this.rounds[0]?.startTime).toUTCString();
      }
      return '';
    },
    headerTitle() {
      return this.form.name?.defaultValue;
    },
  },

  watch: {
    tnId() {
      this.isPageLoading = true;
      this.updateData();
    },
    idSystem(value) {
      if (value !== 8 || value !== 7) {
        this.fetchTournamentBracket(this.tnId);
      }
    },
  },

  async created() {
    await this.updateData();
  },

  mounted() {
    this.dateInterval = setInterval(() => {
      this.dateNow = new Date().toUTCString();
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.dateInterval);
  },
  methods: {
    ...mapActions('organizations', ['fetchOrganization']),
    ...mapActions('tournaments', [
      'fetchTournamentPage',
      'fetchTournamentBracket',
    ]),
    ...mapActions('tournamentSettings', [
      'fetchTournamentFields',
      'fetchTournamentAbilities',
      'fetchTournamentWorkflow',
    ]),

    async updateData() {
      await Promise.all([
        this.fetchTournamentPage(this.tnId),
        this.fetchTournamentWorkflow(this.tnId),
        this.fetchTournamentFields(this.tnId),
        this.fetchTournamentAbilities(this.tnId),
        this.fetchOrganization(this.orgId),
      ])
        .then(() => {
          this.isPageLoading = false;
          if (this.getIsHub && this.getWorkflowState) {
            this.runHubStatusMachine();
          }
        })
        .catch(() => {
          this.$router.push({
            name: 'tournament',
            params: { id: this.tnId },
          });
        });
    },

    runHubStatusMachine() {
      const { state, send } = useMachine(
        hubStatusMachine(this.workflow?.currentState),
        {
          context: {
            tnId: this.tnId,
          },
        },
      );

      this.state = state;
      this.send = send;
    },

    closeHub() {
      this.send('CANCEL');
      this.isTnCancelOpen = false;
    },

    changeTournamentState() {
      const { state } = this.stateInfo;
      switch (state) {
        case 'preparation':
          if (this.tournament?.isBracketFormed === false) {
            api
              .patch(
                `/organization/${this.orgId}/tournament/${this.tnId}/bracketcreate`,
              )
              .then(() => (this.errorMessages = []))
              .catch((data) => {
                this.errorMessages = data.error;
              });
          } else if (this.tournament?.isBracketPublished === false) {
            api
              .patch(
                `/organization/${this.orgId}/tournament/${this.tnId}/bracketpublish`,
              )
              .then(this.updateData);
          }
          break;
        case 'published':
          if (this.workflow?.currentState === 'regopen') {
            this.isRegCancelOpen = true;
          } else {
            this.changeTournamentWorkflow(state);
          }
          break;
        default:
          this.changeTournamentWorkflow(state);
      }
    },

    async changeHubWorkflow(state) {
      const url = `/tournament/${this.tnId}/workflow/run`;
      const payload = {
        state,
        tournamentId: this.tnId,
      };
      const res = await api.post(url, payload);

      if (res.status) {
        await Promise.all(
          this.fetchTournamentWorkflow(this.tnId),
          this.fetchTournamentAbilities(this.tnId),
          this.fetchTournamentFields(this.tnId),
        );
      }
    },

    changeTournamentWorkflow(state) {
      if (this.isRegCancelOpen === true) {
        this.isRegCancelOpen = false;
      }
      api
        .post(`/tournament/${this.tnId}/workflow/run`, {
          state,
          tournamentId: this.tnId,
        })
        .then((data) => {
          if (data?.status) {
            this.fetchTournamentWorkflow(this.tnId);
            this.fetchTournamentAbilities(this.tnId);
            this.fetchTournamentFields(this.tnId);
            this.isTnCancelOpen = false;
          }
        });
    },

    fetchBracketPublish() {
      api.patch(
        `/organization/${this.orgId}/tournament/${this.tnId}/bracketpublish`,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.loader {
  margin-top: calc(#{$headerHeight} + 20px);
}

.state {
  text-align: center;
  background-color: rgba(black, 0.15);

  .pvp-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-controls {
    padding: 16px 0;
    border-top: 1px solid rgba(white, 0.1);
  }

  &-text {
    padding-top: 24px;
    color: rgba(white, 0.5);

    ::v-deep b {
      color: white;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @include min-tablet() {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include min-tablet() {
      font-size: 32px;
    }
  }

  &__left {
    display: flex;
    gap: 8px;
    .button {
      width: 32px;
      height: 32px;
      color: rgba(white, 0.5);
      background-color: $charcoal-grey;
      border-radius: 3px;
      font-size: 18px;
      &:hover {
        color: white;
      }
    }
    @include min-tablet() {
      order: -1;
    }
  }
}

.wrapper {
  @include min-desktop() {
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  @include max-desktop() {
    .empty {
      display: none;
    }
  }
}

.menu {
  @include max-desktop() {
    margin-bottom: 10px;
  }
}

.content {
  flex-grow: 1;
  @include min-desktop() {
    margin-left: 20px;
    max-width: calc(100% - 400px);
  }
  @include max-laptop() {
    margin-left: -12px;
    margin-right: -12px;
  }

  ::v-deep {
    .content-bg {
      background-color: $dark-two;
      padding-bottom: 20px;

      .divider {
        height: 1px;
      }
    }

    .label,
    .select {
      display: block;
    }

    h2 {
      font-size: 22px;
    }

    .switcher {
      @include max-laptop() {
        font-size: 14px;
      }
    }
  }
}

.reg-cancel {
  .desc {
    text-align: center;
  }

  .button {
    width: 160px;

    & + .button {
      margin-left: 1em;
    }
  }
}

.state-error {
  padding-top: 12px;
  font-weight: bold;
  color: var(--additional-color-red);
}

.hub-state {
  padding: 24px;
}

.hub-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
