<template>
  <div class="se-settings">
    <BaseSwitcher
      :value="form.thirdPlaceMatch.value"
      :disabled="!abilities.canEditThirdPlaceMatch"
      :error-message="form.thirdPlaceMatch.error"
      class="indent"
      @input="
        updateFormData({
          name: form.thirdPlaceMatch.name,
          value: $event,
        })
      "
    >
      <span slot="label" class="info">
        {{ $t('matches.thirdPlace') }}
        <pvp-tooltip>
          <icon name="info" inline class="icon" />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_se-third') }}
          </template>
        </pvp-tooltip>
      </span>
    </BaseSwitcher>
    <final-round-settings />
  </div>
</template>

<script>
import FinalRoundSettings from '@components/TournamentComponents/Settings/FinalRoundSettings.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'SeSettings',
  components: { FinalRoundSettings, BaseSwitcher, Icon },
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
  },
};
</script>

<style lang="scss" scoped>
.indent {
  margin-top: 20px;
}
.icon {
  vertical-align: middle;
  margin-left: 4px;
}
</style>
