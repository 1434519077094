var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('tournament-settings-form',[_c('h2',[_vm._v(_vm._s(_vm.$t('global.additionally')))]),(_vm.getIsHub)?[_c('BaseSwitcher',{attrs:{"value":_vm.form.showTeamPriority.value,"disabled":!_vm.abilities.canEditShowTeamPriority,"error-message":_vm.form.showTeamPriority.error},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.showTeamPriority.name,
            value: $event,
          })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.settings_additionally_priority-show'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-priority', ))+" ")])],2)],1)],2),_c('BaseSwitcher',{attrs:{"value":_vm.form.oneTeamResultsEnabled.value,"disabled":!_vm.abilities.canEditOneTeamResultsEnabled},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.oneTeamResultsEnabled.name,
            value: $event,
          })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.settings_additionally_score-auto-confirm', ))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-auto-apply', ))+" ")])],2)],1)],2),(_vm.form.oneTeamResultsEnabled.value)?_c('BaseInput',{staticClass:"settings-control",attrs:{"type":"number","label":_vm.$t('tournaments.settings_additionally_result-timeout'),"value":_vm.form.enterResultsAfter.value,"disabled":!_vm.abilities.canEditEnterResultsAfter,"error-message":_vm.form.enterResultsAfter.error},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.enterResultsAfter, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.enterResultsAfter, "error", $event)},"input":function($event){_vm.updateFormData({
            name: _vm.form.enterResultsAfter.name,
            value: Number($event),
          })}}}):_vm._e()]:[_c('BaseSwitcher',{attrs:{"value":_vm.form.changeTeamRosterAfterRegister.value,"disabled":!_vm.abilities.canEditChangeTeamRosterAfterRegister,"error-message":_vm.form.changeTeamRosterAfterRegister.error},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.changeTeamRosterAfterRegister.name,
            value: $event,
          })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.settings_additionally_team-change'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-allow', ))+" ")])],2)],1)],2),(_vm.form.wfTeamRegCheckCompendium)?_c('BaseSwitcher',{attrs:{"disabled":!_vm.abilities.canEditWfTeamRegCheckCompendium,"value":_vm.form.wfTeamRegCheckCompendium.value,"error-message":_vm.form.wfTeamRegCheckCompendium.error},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.wfTeamRegCheckCompendium.name,
            value: $event,
          })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.settings_additionally_compendium-check'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-compendium', ))+" ")])],2)],1)],2):_vm._e(),_c('BaseSwitcher',{attrs:{"value":_vm.form.isVirtual.value,"warning-message":_vm.virtualWarning,"error-message":_vm.form.isVirtual.error,"disabled":_vm.form.isVirtual.defaultValue || !_vm.abilities.canEditIsVirtual},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.isVirtual.name,
            value: $event,
          })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.virtual_create'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-virtual', ))+" ")])],2)],1)],2),_c('BaseSwitcher',{attrs:{"value":_vm.form.showTeamPriority.value,"disabled":!_vm.abilities.canEditShowTeamPriority,"error-message":_vm.form.showTeamPriority.error},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.showTeamPriority.name,
            value: $event,
          })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.settings_additionally_priority-show'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-priority', ))+" ")])],2)],1)],2),_c('BaseSwitcher',{attrs:{"value":_vm.form.oneTeamResultsEnabled.value,"disabled":!_vm.abilities.canEditOneTeamResultsEnabled},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.oneTeamResultsEnabled.name,
            value: $event,
          })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.settings_additionally_score-auto-confirm', ))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-auto-apply', ))+" ")])],2)],1)],2),(_vm.form.oneTeamResultsEnabled.value)?_c('BaseInput',{staticClass:"settings-control",attrs:{"type":"number","label":_vm.$t('tournaments.settings_additionally_result-timeout'),"value":_vm.form.enterResultsAfter.value,"disabled":!_vm.abilities.canEditEnterResultsAfter,"error-message":_vm.form.enterResultsAfter.error},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.enterResultsAfter, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.enterResultsAfter, "error", $event)},"input":function($event){_vm.updateFormData({
            name: _vm.form.enterResultsAfter.name,
            value: Number($event),
          })}}}):_vm._e(),(_vm.isVisible)?[_c('BaseSwitcher',{attrs:{"value":_vm.form.organizationCanRegisterTeam.value,"disabled":!_vm.abilities.canEditOrganizationCanRegisterTeam},on:{"input":function($event){return _vm.updateFormData({
              name: _vm.form.organizationCanRegisterTeam.name,
              value: $event,
            })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.settings_additionally_team-add-manual', ))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-manual', ))+" ")])],2)],1)],2),_c('BaseSwitcher',{attrs:{"value":_vm.form.checkSimultaneous.value,"disabled":!_vm.abilities.canEditCheckSimultaneous,"error-message":_vm.form.checkSimultaneous.error},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.checkSimultaneous, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.checkSimultaneous, "error", $event)},"input":function($event){return _vm.updateFormData({
              name: _vm.form.checkSimultaneous.name,
              value: $event,
            })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.settings_additionally_restriction'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-same-time', ))+" ")])],2)],1)],2),(_vm.form.checkSimultaneous.value)?_c('BaseInput',{attrs:{"value":_vm.form.simultaneousTag.value,"label":_vm.$t('tournaments.settings_additionally_series'),"disabled":!_vm.abilities.canEditSimultaneousTag},on:{"input":function($event){return _vm.updateFormData({
              name: _vm.form.simultaneousTag.name,
              value: $event,
            })}}}):_vm._e(),_c('div',{staticClass:"divider"}),_c('h5',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('players.restrictions')))]),_c('div',{staticClass:"inline-group"},[_c('BaseInput',{attrs:{"value":_vm.form.playerAge.value,"disabled":!_vm.abilities.canEditPlayerAge,"error-message":_vm.form.playerAge.error,"placeholder":18,"label":_vm.$t('global.age')},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.playerAge, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.playerAge, "error", $event)},"input":function($event){_vm.updateFormData({
                name: _vm.form.playerAge.name,
                value: Number($event) || null,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_additionally-age'))+" ")])],2)],1),_c('div',{staticClass:"inline-group"},[_c('BaseSelect',{attrs:{"value":_vm.form.playerRequired.value,"label":_vm.$t('players.required_field'),"options":_vm.userRequiredFields,"disabled":!_vm.abilities.canEditPlayerRequired,"placeholder":_vm.$t('players.required_select'),"multiple":true,"label-key":"name","value-key":"code","list-position":"top"},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.playerRequired.name,
                value: $event,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-required', ))+" ")])],2)],1),_c('div',{staticClass:"inline-group"},[_c('CountrySelect',{staticClass:"label",attrs:{"value":_vm.form.playerCountry.value,"disabled":!_vm.abilities.canEditPlayerCountry,"list-position":"top","label":_vm.$t('players.country_label')},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.playerCountry.name,
                value: $event,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-country', ))+" ")])],2)],1),_c('div',{staticClass:"inline-group"},[_c('BaseInput',{attrs:{"value":_vm.form.playerCity.value,"label":_vm.$t('players.city_label'),"disabled":!_vm.abilities.canEditPlayerCity,"error-message":_vm.form.playerCity.error,"placeholder":_vm.$t('players.city_label')},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.playerCity.name,
                value: $event || null,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-city', ))+" ")])],2)],1),_c('BaseSwitcher',{attrs:{"value":_vm.form.premiumFks.value,"disabled":!_vm.abilities.canEditPremiumFks,"error-message":_vm.form.premiumFks.error},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.premiumFks, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.premiumFks, "error", $event)},"input":function($event){return _vm.updateFormData({
              name: _vm.form.premiumFks.name,
              value: $event,
            })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.settings_additionally_fcs'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-fcs-premium', ))+" ")])],2)],1)],2),_c('BaseSwitcher',{attrs:{"value":_vm.form.needFksTerms.value,"disabled":!_vm.abilities.canEditNeedFksTerms,"error-message":_vm.form.needFksTerms.error},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.needFksTerms, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.needFksTerms, "error", $event)},"input":function($event){return _vm.updateFormData({
              name: _vm.form.needFksTerms.name,
              value: $event,
            })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('terms.accept_fcs_agreement'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-fcs-terms', ))+" ")])],2)],1)],2),_c('BaseSwitcher',{attrs:{"value":_vm.form.needFksHash.value,"disabled":!_vm.abilities.canEditNeedFksHash,"error-message":_vm.form.needFksHash.error},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.needFksHash, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.needFksHash, "error", $event)},"input":function($event){return _vm.updateFormData({
              name: _vm.form.needFksHash.name,
              value: $event,
            })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('profile.fcs_title'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-fcs-account', ))+" ")])],2)],1)],2),_c('BaseSwitcher',{attrs:{"value":_vm.getFksPdEnabled,"disabled":!_vm.abilities.canEditFksPdFields},on:{"input":_vm.showPDList}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('profile.profile_pd'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_profile_pd'))+" ")])],2)],1)],2),(_vm.getFksPdEnabled)?_c('div',{staticClass:"pd indent"},[_c('div',{staticClass:"pd-label"},[_vm._v(" "+_vm._s(_vm.$t('profile.pd_select_label'))+" "),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_pd_select_tooltip', ))+" ")])],2)],1),_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.fksPdFields.value,"placeholder":"Список ПД для турнира","list-position":"bottom","multiple":true,"limit":29,"value-key":"value","label-key":"label","options":_vm.getFksPersonalDataList},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.fksPdFields.name,
                value: $event,
              })}}})],1):_vm._e(),_c('div',{staticClass:"divider"}),_c('h5',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('teams.restrictions')))]),_c('BaseSwitcher',{attrs:{"value":_vm.form.teamLogo.value,"disabled":!_vm.abilities.canEditTeamLogo},on:{"input":function($event){return _vm.updateFormData({
              name: _vm.form.teamLogo.name,
              value: $event,
            })}}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('teams.logo_title'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-logo', ))+" ")])],2)],1)],2),_c('div',{staticClass:"inline-group"},[_c('CountrySelect',{staticClass:"label",attrs:{"value":_vm.form.teamCountry.value,"disabled":!_vm.abilities.canEditTeamCountry,"list-position":"top","label":_vm.$t('players.country_label')},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.teamCountry.name,
                value: $event,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t( 'tournaments.tooltips_settings_additionally-country', ))+" ")])],2)],1),_c('BaseSwitcher',{attrs:{"value":_vm.form.captainDiscordRequired.value,"disabled":!_vm.abilities.canEditCaptainDiscordRequired},on:{"input":function($event){return _vm.updateFormData({
              name: _vm.form.captainDiscordRequired.name,
              value: $event,
            })}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('teams.captain_discord_check'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('teams.captain_discord_check_tooltip'))+" ")])],2)]},proxy:true}],null,false,237483764)})]:_vm._e(),_c('pvp-btn',{staticClass:"toggle-btn",attrs:{"variant":"link"},on:{"click":function($event){_vm.isVisible = !_vm.isVisible}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.isVisible ? 'global.collapse' : 'global.expand'))+" ")])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }