<template>
  <div>
    <BaseSwitcher
      v-if="form.pickBanSystem"
      :value="form.pickBanSystem.value"
      :disabled="!form.checkinMatchEnable.value"
      :error-message="form.pickBanSystem.error"
      @input="
        updateFormData({
          name: form.pickBanSystem.name,
          value: $event,
        })
      "
    >
      <template slot="label">
        {{ $t('matches.wf_map_picking') }}
        <pvp-tooltip class="info">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-maps') }}
          </template>
        </pvp-tooltip>
      </template>
    </BaseSwitcher>

    <template v-if="form.pickBanSystem && form.pickBanSystem.value">
      <div v-if="form.pickBanTimeout" class="inline">
        <BaseSelect
          class="settings-control"
          :value="form.pickBanTimeout.value"
          :label="$t('matches.wf_map_picking-time')"
          :options="pickbanTimeoutsList"
          :error-message="form.pickBanTimeout.error"
          :required="true"
          @input="
            updateFormData({
              name: form.pickBanTimeout.name,
              value: $event,
            })
          "
        >
          <template v-slot:option="{ option }">
            {{ $tc('date.seconds_pluralization', option) }}
          </template>
          <template v-slot:selected-option="{ option }">
            {{ $tc('date.seconds_pluralization', option) }}
          </template>
        </BaseSelect>
        <pvp-tooltip class="left">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-pick-time') }}
          </template>
        </pvp-tooltip>
      </div>

      <div v-if="form.gameMaps" class="maps indent">
        <div class="maps-label">
          {{ $t('matches.wf_map_list') }}
          <pvp-tooltip class="left">
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_wf-maps-list') }}
            </template>
          </pvp-tooltip>
        </div>
        <BaseSelect
          v-if="maps.length"
          class="settings-control"
          :value="form.gameMaps.value"
          :placeholder="$t('matches.wf_map_list')"
          :multiple="true"
          list-position="bottom"
          :error-message="form.gameMaps.error"
          value-key="code"
          label-key="name"
          :options="maps"
          @input="
            updateFormData({
              name: form.gameMaps.name,
              value: $event,
            })
          "
        />
      </div>

      <div v-if="form.gameType" class="inline">
        <tournament-format-selector
          :value="form.gameType.value"
          :disabled="!form.pickBanSystem.value"
          :error-message="form.gameType.error"
          class="indent settings-control"
          @input="
            updateFormData({
              name: form.gameType.name,
              value: $event,
            })
          "
        />
        <pvp-tooltip class="left">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-best-of') }}
          </template>
        </pvp-tooltip>
      </div>

      <div
        v-if="
          form.lastMapPickSideFormat &&
          form.gameType &&
          form.gameType.value !== 2
        "
        class="inline"
      >
        <BaseSelect
          class="settings-control"
          :value="form.lastMapPickSideFormat.value"
          :label="$t('games.last_map_pick_side_label')"
          :error-message="form.lastMapPickSideFormat.error"
          :options="lastMapPickSide"
          :required="true"
          @input="
            updateFormData({
              name: form.lastMapPickSideFormat.name,
              value: $event,
            })
          "
        >
          <template v-slot:option="{ option }">
            {{ $t(`games.last_map_pick_side_${option}`) }}
          </template>
          <template v-slot:selected-option="{ option }">
            {{ $t(`games.last_map_pick_side_${option}`) }}
          </template>
        </BaseSelect>
      </div>
    </template>
  </div>
</template>

<script>
import TournamentFormatSelector from '@components/TournamentComponents/TournamentFormatSelector.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'CfSettings',
  components: {
    TournamentFormatSelector,
    BaseSwitcher,
    BaseSelect,
    Icon,
  },
  data: () => ({
    maps: [],
  }),
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapState('application', ['pickbanTimeoutsList']),
    ...mapGetters('application', ['getWfShards']),

    lastMapPickSide() {
      return [0, 1];
    },

    regionData() {
      return this.getWfShards;
    },
  },
  created() {
    this.fetchMapList(this.form.idGame.value).then(({ maps }) => {
      this.maps = maps;
    });
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    ...mapActions('matches', ['fetchMapList']),
  },
};
</script>

<style lang="scss" scoped>
.switcher {
  margin-top: 26px;
}

.select,
.indent {
  margin-top: 20px;
}

.maps {
  .select {
    margin-top: 4px;
  }

  &-label {
    color: rgba(white, 0.5);
    font-size: em(14px);

    .tt-wrapper {
      vertical-align: middle;
    }
  }
}

.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 420px;

  .select {
    width: 100%;
  }

  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 0.5em;
    font-size: 18px;
  }
}

.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.settings-control {
  max-width: 420px;
}
</style>
