var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('tournament-edit-form',[_c('h2',[_vm._v(_vm._s(_vm.$t('tournaments.about')))]),_c('BaseInput',{attrs:{"label":_vm.$t('tournaments.name'),"value":_vm.form.name.value,"error-message":_vm.form.name.error,"disabled":!_vm.abilities.canEditName},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.name, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.name, "error", $event)},"input":function($event){return _vm.updateFormData({ name: _vm.form.name.name, value: $event })}}}),_c('div',{staticClass:"edit-avatar-logo"},[_vm._v(_vm._s(_vm.$t('global.logo')))]),_c('edit-logo',{attrs:{"id":_vm.params.tnId,"max-file-size":0.5,"allowed-formats":['png', 'jpg'],"helper-text":_vm.$t('uploader.maxSize', { from: 498, to: 498 }),"type":"tournamentlogo"},on:{"updated":_vm.onLogoUploaded}},[(_vm.tournament.logo)?_c('img',{staticClass:"img-logo",attrs:{"src":_vm.tournament.logo}}):_c('div',{staticClass:"default-logo"},[_c('game-icon',{attrs:{"name":_vm.game.code,"inline":""}})],1)]),_c('wysiwyg-editor',{attrs:{"value":_vm.form.description.value,"disabled":!_vm.abilities.canEditDescription},on:{"input":function($event){return _vm.updateFormData({
          name: _vm.form.description.name,
          value: $event,
        })}}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('global.description'))+" "),_c('pvp-tooltip',[_c('Icon',{staticClass:"info",attrs:{"name":"info","size":16}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_about'))+" ")])],2)],1)]),_c('wysiwyg-editor',{key:_vm.pasteKey,attrs:{"value":_vm.form.rules.value,"disabled":!_vm.abilities.canEditRules},on:{"input":function($event){return _vm.updateFormData({ name: _vm.form.rules.name, value: $event })}}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.rules'))+" "),_c('pvp-tooltip',[_c('Icon',{staticClass:"info",attrs:{"name":"info","size":16}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_rules'))+" ")])],2)],1)]),(_vm.rules.length)?_c('pvp-btn',{staticClass:"rules",on:{"click":function($event){return _vm.toggleRulesModal(true)}}},[_vm._v(" "+_vm._s(_vm.$t('rules.standart'))+" ")]):_vm._e(),_c('div',{staticClass:"divider"}),_c('h5',{staticClass:"contacts"},[_vm._v(" "+_vm._s(_vm.$t('global.contacts'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_contacts'))+" ")])],2)],1),_c('BaseInput',{attrs:{"label":"Discord","value":_vm.form.discordContact.value,"disabled":!_vm.abilities.canEditDiscordContact,"error-message":_vm.form.discordContact.error,"placeholder":"example#1337"},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.discordContact, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.discordContact, "error", $event)},"input":function($event){return _vm.updateFormData({
          name: _vm.form.discordContact.name,
          value: $event,
        })}}}),_c('BaseInput',{attrs:{"label":_vm.$t('global.site'),"value":_vm.form.siteContact.value,"disabled":!_vm.abilities.canEditSiteContact,"error-message":_vm.form.siteContact.error,"placeholder":"url"},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.siteContact, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.siteContact, "error", $event)},"input":function($event){return _vm.updateFormData({
          name: _vm.form.siteContact.name,
          value: $event,
        })}}}),_c('BaseInput',{attrs:{"label":_vm.$t('global.additionally'),"value":_vm.form.anyContact.value,"error-message":_vm.form.anyContact.error,"placeholder":_vm.$t('info.additional_players'),"rows":4,"is-area":true},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.anyContact, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.anyContact, "error", $event)},"input":function($event){return _vm.updateFormData({
          name: _vm.form.anyContact.name,
          value: $event,
        })}}}),_c('pvp-modal',{attrs:{"width":580},model:{value:(_vm.isRulesModalVisible),callback:function ($$v) {_vm.isRulesModalVisible=$$v},expression:"isRulesModalVisible"}},[_c('template',{slot:"modal-title"},[_vm._v(_vm._s(_vm.$t('rules.title')))]),_vm._l((_vm.rules),function(rule,key){return _c('pvp-btn',{key:key,staticClass:"rules-button",class:{ active: _vm.ruleId === rule.id },attrs:{"variant":"clear"},on:{"click":function($event){_vm.ruleId = rule.id}}},[_c('Icon',{attrs:{"name":"file-add","inline":""}}),_vm._v(" "+_vm._s(rule.name)+" ")],1)}),_c('div',{staticClass:"rules-footer",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('pvp-btn',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.toggleRulesModal(false)}}},[_vm._v(_vm._s(_vm.$t('global.cancel')))]),_c('pvp-btn',{attrs:{"disabled":!_vm.ruleId},on:{"click":_vm.uploadRules}},[_vm._v(_vm._s(_vm.$t('uploader.upload')))])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }