<template>
  <BaseForm class="content-bg" @submit="saveChanges">
    <div class="padded">
      <slot />
    </div>

    <div class="controls">
      <pvp-btn :is-loading="isLoading" type="submit"
        >{{ $t('save.single') }}
      </pvp-btn>
    </div>

    <InfoBox v-if="info" :variant="info.state">
      {{ info.message }}
    </InfoBox>
  </BaseForm>
</template>

<script>
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'TournamentSettingsForm',
  components: {
    BaseForm,
    InfoBox,
  },
  data: () => ({
    isLoading: false,
    info: null,
  }),
  computed: {
    ...mapState('tournamentSettings', ['form']),
    ...mapState('application', ['scssContext']),

    transitionMap() {
      const getName = (arr) =>
        arr.reduce((result, item) => {
          const name = this.form?.[item]?.name;
          if (name) {
            result.push(name);
          }
          return result;
        }, []);
      return [
        {
          route: 'about',
          value: getName([
            'name',
            'description',
            'rules',
            'discordContact',
            'siteContact',
            'anyContact',
          ]),
        },
        {
          route: 'main',
          value: getName([
            'numTeamMembers',
            'maxReservePlayersCount',
            'needCheckIn',
            'checkinMatchEnable',
            'checkinMatchTimeout',
            'tsStartReg',
            'tsStartRun',
            'tsEndRun',
            'timeBeforeStart',
          ]),
        },
        {
          route: 'game',
          value: getName([
            'showPick',
            'hsLoadDeck',
            'hsPickCnt',
            'hsBanCnt',
            'minRank',
            'checkGameProfileRegion',
            'regionCode',
            'pickBanSystem',
            'pickBanTimeout',
            'gameType',
            'gameMaps',
            'csgoServerConfigSpectators',
          ]),
        },
        {
          // WARN: потециально может что-то упасть в настройках, потому что было поле bracket, а у нас сейчас нет такого роута tournament-edit-bracket.
          route: 'grid',
          value: getName([
            'numLimitParticipants',
            'ratingRegDuringExec',
            'thirdPlaceMatch',
            'count',
            'countOnGroup',
            'countRound',
            'pointWin',
            'pointTie',
            'pointDefeat',
            'preferNumRounds',
            'countPlayersOnMap',
            'ffaCountGamesInSeries',
            'ffaDisableKills',
            'teamFfaKills',
            'teamFfaPlaces',
          ]),
        },
        {
          route: 'other',
          value: getName([
            'changeTeamRosterAfterRegister',
            'wfTeamRegCheckCompendium',
            'isVirtual',
            'showTeamPriority',
            'enterResulstAfter',
            'premiumFks',
            'checkSimultaneous',
          ]),
        },
      ];
    },
  },
  methods: {
    ...mapActions('tournamentSettings', [
      'fetchTournamentFields',
      'saveTournamentFields',
    ]),
    ...mapActions('tournaments', ['fetchTournamentPage']),
    saveChanges() {
      if (this.isLoading) {
        return false;
      }
      this.form.description.value
        .replace('&amp;', '&')
        .replaceAll('amp;', '');

      this.info = null;
      this.isLoading = true;
      const { tnId } = this.$route.params;

      return this.saveTournamentFields(tnId)
        .then((data) => {
          if (data?.success) {
            this.fetchTournamentPage(tnId);
            this.fetchTournamentFields(tnId);
            this.info = {
              state: 'success',
              message: this.$t('save.success'),
            };
          }
        })
        .catch(({ error, transition }) => {
          if (transition) {
            this.transitionMap.forEach(({ route, value }) => {
              if (value.includes(transition)) {
                const name = route === 'about' ? '' : route;
                this.$router
                  .push({
                    name: `tournament-edit${name && `-${name}`}`,
                  })
                  .catch(() => ({}))
                  .finally(() => {
                    setTimeout(() => {
                      const el = document.querySelector('.error');
                      if (el) {
                        window.scrollTo({
                          top:
                            window.pageYOffset -
                            this.scssContext?.headerHeight +
                            el.getBoundingClientRect().top,
                          behavior: 'smooth',
                        });
                      }
                    }, 300);
                  });
              }
            });
          } else if (error) {
            this.info = {
              state: 'error',
              message: error || this.$t('errors.unknown'),
            };
          }
        })
        .finally(() => {
          this.isLoading = false;
          setTimeout(() => {
            this.info = null;
          }, 2000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.padded {
  @include min-laptop() {
    padding: 42px 48px;
  }
  @include max-laptop() {
    padding: 20px 12px;
  }
}

.content-bg {
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
}

.controls {
  border-top: 1px solid rgba(white, 0.1);

  @include min-laptop() {
    padding: 42px 48px 0;
  }

  @include max-laptop() {
    padding: 20px 12px 0;
    text-align: center;
  }

  .button {
    @include min-laptop() {
      width: 160px;
    }
    @include max-laptop() {
      width: 120px;
    }

    & + .button {
      margin-left: 10px;
    }
  }
}

.pvp-info-box {
  margin-top: 20px;
  @include min-laptop() {
    margin-left: 48px;
    margin-right: 48px;
  }
  @include max-laptop() {
    margin-left: 12px;
    margin-right: 12px;
  }
}
</style>
