import { createMachine, assign, spawn } from 'xstate';
import { hubLoading } from './hubLoading.js';

const hubStatusMachine = (initial) =>
  createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QAsCuAjAygFwIbdVgDoBjAJzH0gGIBhAQQDlaBRAGQG0AGAXUVAAOAe1gBLbKKEA7fiAAeiAIwBWLkQCci9QCYALADYAzAA51y7aeUAaEAE9ExxUV3HlAdmW7Fh5atXqAXwCbNCw8AmJySmwaAAUAVQAhNgBJTAAJbj4kEGExCWlZBQQVNU0dAxMzCzMbewRtQzcNC20uR0VtfW0VYyCQjBx8QiIBDAAbUVhkGkwAFXoAJTms2TzxSRkc4t0ubQ0jXRMuZXU3Qy7rO0RGp2VvCy43Xe0PE36QUKGI0YmpmYgdCYrE4vDWIg2hW2iBeB0MR2MJzOF30V3q932Fx86nhXS4XBxHy+4RGYDkYBIqAkUig1AAYilGGlMmCcusCltQMVUWpFKZ1I4ed1unUbtplEQCcZDPo3I1GoYBYYiYMScQyRSqaIaUDmOxVmyIRyiogeUQ+eoBYohV1tKKEDLjEQ3IopVwLppnsp9CqwsNiAAzbX-GgMPWg7KCI2bE0IYzGXQaa0GfGGRXKHz27TiyVK2XytNKoLBEBSIQQOCyYn+8H5GPQhAAWn09sbEstHc7XeVJerPyiVAgtchnPkMLt1xKiicXVcnRcbn0umevu+IxIuCkJDA43GkGHxobrr5REaexq+i4RyMWfuREcbkX6l2xn0imeulXat+6Em033hp1lCXI3G4Tq6MoxjaDokGqCYN6Tl0M7qJeioEno75uF+-pEBqlLUlAB71iBCDmPop6Pm0+gCt0KiGLeibxtOGaGN4hjugY2E-EGUghkOgEjrGj6GEQcEeCcj6uuoWYuM4+hvr4XBaMm2jFgEQA */
    id: 'hubStatus',
    context: {
      tnId: undefined,
    },
    initial: initial,
    states: {
      created: {
        on: {
          CANCEL: {
            target: 'cancelled',
            actions: assign({
              hubState: (context) =>
                spawn(
                  hubLoading({
                    currentState: 'cancelled',
                    tnId: context.tnId,
                  }),
                ),
            }),
          },
          PUBLISH: {
            target: 'published',
            actions: assign({
              hubState: (context) =>
                spawn(
                  hubLoading({
                    currentState: 'published',
                    tnId: context.tnId,
                  }),
                ),
            }),
          },
        },
      },

      cancelled: {
        type: 'final',
      },

      published: {
        on: {
          START: {
            target: 'executing',
            actions: assign({
              hubState: (context) =>
                spawn(
                  hubLoading({
                    currentState: 'executing',
                    tnId: context.tnId,
                  }),
                ),
            }),
          },
          CANCEL: {
            target: 'cancelled',
            actions: assign({
              hubState: (context) =>
                spawn(
                  hubLoading({
                    currentState: 'cancelled',
                    tnId: context.tnId,
                  }),
                ),
            }),
          },
        },
      },

      executing: {
        on: {
          FINISH: {
            target: 'finished',
            actions: assign({
              hubState: (context) =>
                spawn(
                  hubLoading({
                    currentState: 'finished',
                    tnId: context.tnId,
                  }),
                ),
            }),
          },
          CANCEL: {
            target: 'cancelled',
            actions: assign({
              hubState: (context) =>
                spawn(
                  hubLoading({
                    currentState: 'cancelled',
                    tnId: context.tnId,
                  }),
                ),
            }),
          },
        },
      },

      finished: {
        on: {
          CANCEL: {
            target: 'cancelled',
            actions: assign({
              hubState: (context) =>
                spawn(
                  hubLoading({
                    currentState: 'cancelled',
                    tnId: context.tnId,
                  }),
                ),
            }),
          },
        },
      },
    },
  });

export default hubStatusMachine;
