var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"match-making-settings"},[_c('tournament-settings-form',[_c('h2',{staticClass:"match-making-settings__header"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.settings_match_making'))+" ")]),_c('BaseSwitcher',{staticClass:"indent",attrs:{"value":_vm.form.hubIsMatchmakingEnabled.value},on:{"input":function($event){return _vm.updateFormData({
          name: _vm.form.hubIsMatchmakingEnabled.name,
          value: $event,
        })}}},[_c('span',{staticClass:"info",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.matchmaking_is_enabled'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_matchmaking_is_enabled'))+" ")])],2)],1)]),(_vm.form.isIngameLobbyFlowEnabled)?_c('BaseSwitcher',{staticClass:"indent",attrs:{"value":_vm.form.isIngameLobbyFlowEnabled.value},on:{"input":function($event){return _vm.updateFormData({
          name: _vm.form.isIngameLobbyFlowEnabled.name,
          value: $event,
        })}}},[_c('span',{staticClass:"info",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.lobby_info_is_enabled'))+" "),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_lobby_info_is_enabled'))+" ")])],2)],1)]):_vm._e(),_c('div',{staticClass:"inline mb-24"},[_c('BaseSelect',{attrs:{"value":_vm.form.hubCheckinTimeoutSec.value,"value-key":"key","label-key":"value","label":_vm.$t('tournaments.checkin_timeout'),"list-position":"top","options":_vm.form.hubCheckinTimeoutSec.selectFrom,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.hubCheckinTimeoutSec.name,
            value: $event,
          })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_checkin_timeout'))+" ")])],2)],1),(_vm.form.hubIsSoloSupported.value)?[_c('div',{staticClass:"inline mb-24"},[_c('BaseSelect',{attrs:{"value":_vm.form.hubCaptainElectionStrategy.value,"value-key":"key","label-key":"value","label":_vm.$t('tournaments.choose_captain'),"list-position":"top","options":_vm.form.hubCaptainElectionStrategy.selectFrom,"required":true,"readonly":"","disabled":""},on:{"input":function($event){return _vm.updateFormData({
              name: _vm.form.hubCaptainElectionStrategy.name,
              value: $event,
            })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_choose_captain'))+" ")])],2)],1),_c('div',{staticClass:"inline mb-24"},[_c('BaseSelect',{attrs:{"value":_vm.form.hubPlayerPickStrategy.value,"value-key":"key","label-key":"value","label":_vm.$t('tournaments.choise_players'),"list-position":"top","options":_vm.form.hubPlayerPickStrategy.selectFrom,"required":true},on:{"input":function($event){return _vm.updateFormData({
              name: _vm.form.hubPlayerPickStrategy.name,
              value: $event,
            })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_choise_players'))+" ")])],2)],1),(_vm.isCaptainPick)?[_c('div',{staticClass:"inline mb-24"},[_c('BaseSelect',{attrs:{"value":_vm.form.hubCaptainPickTimeoutSec.value,"value-key":"key","label-key":"value","label":_vm.$t('tournaments.time_for_choise_players'),"list-position":"top","options":_vm.form.hubCaptainPickTimeoutSec.selectFrom,"required":true},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.hubCaptainPickTimeoutSec.name,
                value: $event,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_time_for_choise_players'))+" ")])],2)],1),_c('div',{staticClass:"inline mb-24"},[_c('BaseSelect',{attrs:{"value":_vm.form.hubCaptainPickTimeoutReaction.value,"value-key":"key","label-key":"value","label":_vm.$t('tournaments.time_for_no_pick'),"list-position":"top","options":_vm.form.hubCaptainPickTimeoutReaction.selectFrom,"required":true},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.hubCaptainPickTimeoutReaction.name,
                value: $event,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_time_for_no_pick'))+" ")])],2)],1)]:_vm._e()]:_vm._e(),_c('h2',{staticClass:"match-making-settings__header"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.automating_blocking'))+" ")]),_c('div',{staticClass:"match-making-settings__wrapper"},[_c('BaseSwitcher',{staticClass:"indent",attrs:{"value":_vm.switcherPenaltyNotAcceptGame},on:{"input":_vm.updateSwitcherPenaltyNotAcceptGame}},[_c('span',{staticClass:"info",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.penalty_not_accepted_game'))+" "),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_penalty_not_accepted_game'))+" ")])],2)],1)]),_c('div',{staticClass:"inline"},[_c('BaseSelect',{attrs:{"value":_vm.form.hubAutoblockCheckinTimeoutPenaltySec.value,"disabled":!_vm.switcherPenaltyNotAcceptGame,"value-key":"key","label-key":"value","label":_vm.$t('tournaments.timeout_penalty_no_game'),"list-position":"top","options":_vm.form.hubAutoblockCheckinTimeoutPenaltySec.selectFrom,"required":true},on:{"input":function($event){return _vm.updateFormData({
              name: _vm.form.hubAutoblockCheckinTimeoutPenaltySec.name,
              value: $event,
            })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_timeout_penalty_no_game'))+" ")])],2)],1)],1),(_vm.isCaptainPick)?_c('div',{staticClass:"match-making-settings__wrapper"},[(
          _vm.form.hubCaptainPickTimeoutReaction.value !== 'autopick'
        )?[_c('BaseSwitcher',{staticClass:"indent",attrs:{"value":_vm.switcherPenaltyNoPick},on:{"input":_vm.updateSwitcherPenaltyNoPick}},[_c('span',{staticClass:"info",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.penalty_for_no_pick'))+" "),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_penalty_for_no_pick'))+" ")])],2)],1)]),_c('div',{staticClass:"inline"},[_c('BaseSelect',{attrs:{"value":_vm.form.hubCaptainPickAutoblockTimeoutPenaltySec.value,"disabled":!_vm.switcherPenaltyNoPick,"value-key":"key","label-key":"value","label":_vm.$t('tournaments.timeout_penalty_no_pick'),"list-position":"top","options":_vm.form.hubCaptainPickAutoblockTimeoutPenaltySec
                .selectFrom,"required":true},on:{"input":function($event){return _vm.updateFormData({
                name: _vm.form.hubCaptainPickAutoblockTimeoutPenaltySec
                  .name,
                value: $event,
              })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_timeout_penalty_no_pick'))+" ")])],2)],1)]:_vm._e()],2):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }