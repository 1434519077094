<template>
  <label class="switcher">
    <input
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
    />
    <span class="switcher-button"></span>
    <span class="switcher-content">
      <template v-if="label">{{ label }}</template>
      <slot v-else name="label" />
      <span v-if="msg" :class="msg.type" class="message">
        {{ msg.text }}
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'BaseSwitcher',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    successMessage: {
      type: String,
      default: '',
    },
    warningMessage: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    checked() {
      return _.isEqual(this.trueValue, this.value);
    },
    isArr() {
      return _.isArray(this.value);
    },
    msg() {
      if (this.successMessage) {
        return {
          type: 'success',
          text: this.successMessage,
        };
      }
      if (this.warningMessage) {
        return {
          type: 'warning',
          text: this.warningMessage,
        };
      }
      if (this.errorMessage) {
        return {
          type: 'error',
          text: this.errorMessage,
        };
      }
      return false;
    },
  },
  methods: {
    onChange(event) {
      if (this.disabled) {
        return false;
      }

      let result = '';
      if (!this.checked) {
        result = this.isArr
          ? [...this.value, this.trueValue]
          : this.trueValue;
      } else {
        result = this.isArr
          ? this.value.filter((item) => !_.isEqual(item, this.value))
          : this.falseValue;
      }

      this.$emit('input', result);
      event.target.checked = this.checked;

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';
.switcher {
  display: inline-flex;

  input {
    display: none;

    &:checked ~ .switcher-button {
      background-color: $azure;

      &:before {
        left: calc(100% - #{em(21px)});
      }
    }

    &:disabled ~ span {
      opacity: 0.5;
    }
  }

  &-button {
    display: inline-block;
    align-items: center;
    flex-shrink: 0;
    transition: 0.2s;
    width: em(42px);
    height: em(24px);
    background-color: $slate-grey-two;
    border-radius: 12px;
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      display: block;
      width: em(18px);
      height: em(18px);
      border-radius: 9px;
      background-color: white;
      transition: 0.2s;
      top: em(3px);
      left: em(3px);
    }
  }

  &-content {
    margin-left: 8px;
    user-select: none;
  }
}

.message {
  font-size: em(13px);
  color: rgba(white, 0.4);
  margin-top: em(8px);
  line-height: 1.25;
  display: block;

  &.success {
    color: $dark-pastel-green;
  }

  &.warning {
    color: $gold-two;
  }

  &.error {
    color: $orangey-red;
  }
}
</style>
