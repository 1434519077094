<template>
  <div v-if="!isLoading" class="tournament-edit-activities">
    <InfoBox
      v-if="errorMessage.length"
      class="error-msg"
      variant="error"
    >
      {{ errorMessage }}
    </InfoBox>
    <template v-else>
      <div class="tournament-edit-activities__container">
        <h2 class="tournament-edit-activities__title">
          {{ $t('global.filter') }}
        </h2>
        <div class="tournament-edit-activities__filters">
          <Input
            :placeholder="$t('admin.inputPlaceholder')"
            before-icon="lens"
            size="regular"
            @change="inputChangeHandler"
          />
          <Select
            class="tournament-edit-activities__select"
            :placeholder="$t('admin.selectPlaceholder')"
            display-value="name"
            option-key="id"
            :options="selectOptions"
            @change="selectChangeHandler"
          />
        </div>
      </div>
      <template v-if="activisties.data?.items.length">
        <div class="tournament-edit-activities__table">
          <Table>
            <template #thead>
              <TableRow>
                <TableData :heading="true" text-align="left">{{
                  $t('global.changeTime')
                }}</TableData>
                <TableData :heading="true" text-align="left">{{
                  $t('global.action')
                }}</TableData>
              </TableRow>
            </template>
            <template #tbody>
              <TableRow
                v-for="item in activisties.data?.items"
                :key="item.id"
              >
                <TableData text-align="left">
                  {{ $dt(item.tsChange, 'dd_MM_YYYY_HH_mm') }}
                </TableData>
                <TableData text-align="left">
                  <div class="tournament-edit-activities__action">
                    {{ item.action_name }}
                    <span
                      class="tournament-edit-activities__accent"
                      >{{ item.nick }}</span
                    >
                    {{ $t('global.tournament') }}
                    <span
                      class="tournament-edit-activities__accent"
                      >{{ item.tournamentName }}</span
                    >
                  </div>
                </TableData>
              </TableRow>
            </template>
          </Table>
        </div>
        <ListItemPagination
          v-if="activisties.data.pagesCount > 1"
          :step="3"
          :items-amount="activisties.data.itemCount"
          :items-on-page-amount="activisties.data.numPerPage"
          @change="paginationChangeHandler"
        />
      </template>
      <div v-else class="tournament-edit-activities__container">
        <p class="tournament-edit-activities__text">
          {{ $t('global.noData') }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import Input from '@components/v2/ui/Input.vue';
import Select from '@components/v2/ui/Select.vue';
import ListItemPagination from '@components/v2/ListItemPagination.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'TournamentEditActivities',
  components: {
    Table,
    TableRow,
    TableData,
    Input,
    Select,
    ListItemPagination,
    InfoBox,
  },
  data() {
    return {
      isLoading: true,
      filters: {},
      activisties: {},
      selectOptions: [],
      matchFilterID: '',
      typeFilterID: '',
      currentPage: 0,
      errorMessage: '',
    };
  },
  async created() {
    try {
      await Promise.all([
        this.fetchFilters(),
        this.fetchActivities(),
      ]);
    } catch (err) {
      const error = JSON.parse(err.message);
      this.errorMessage = error.errorMessage;
    }
    this.isLoading = false;
  },
  methods: {
    inputChangeHandler(value) {
      this.matchFilterID = value;
      this.currentPage = 0;
      this.fetchActivities();
    },
    selectChangeHandler(value) {
      this.typeFilterID = value.id;
      this.currentPage = 0;
      this.fetchActivities();
    },
    paginationChangeHandler(data) {
      this.currentPage = data.currentPage - 1;
      this.fetchActivities();
    },
    async fetchFilters() {
      this.filters = await api.get(
        `organization/${this.$route.params.orgId}/tournament/${this.$route.params.tnId}/historyparams`,
      );
      this.selectOptions = Object.entries(
        this.filters.data.types,
      ).map((filterData) => {
        return { id: filterData[0], name: filterData[1] };
      });
    },
    async fetchActivities() {
      const url = `organization/${this.$route.params.orgId}/tournament/${this.$route.params.tnId}/historysearch`;
      const params = {
        page: this.currentPage,
        matchId: this.matchFilterID,
        type: this.typeFilterID,
      };
      this.activisties = await api.get(url, { params });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.tournament-edit-activities__select {
  max-width: unset !important; // уберает перегрузку из шаблона страницы (убрать когда уберем перегрузку)
}

.tournament-edit-activities__container {
  padding: 16px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background: var(--main-color-darkgray);
  margin-bottom: 8px;
  @include min-tablet() {
    margin-bottom: 12px;
  }
}

.tournament-edit-activities__table {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.tournament-edit-activities__title {
  margin-bottom: 16px;
}

.tournament-edit-activities__text,
.tournament-edit-activities__title {
  color: var(--main-color-white);
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
}

.tournament-edit-activities__text {
  text-align: center;
}

.tournament-edit-activities__filters {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  & > * {
    width: 100%;
    @include min-tablet() {
      width: calc(50% - 6px);
    }
  }
}

.tournament-edit-activities__action {
  white-space: normal;
}

.tournament-edit-activities__accent {
  color: #16a7ff;
}

// убрать перегрузку когда изолируем все стили и устраним протечки
::v-deep {
  .pagination__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
