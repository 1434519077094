<template>
  <div class="match-making-ban-list">
    <h2 class="match-making-ban-list__title">
      {{ $t('tournaments.match_making_ban_list') }}
    </h2>
    <div class="match-making-ban-list__header">
      <Input
        :placeholder="$t('admin.inputPlaceholder')"
        before-icon="lens"
        size="regular"
      />
      <Button
        tag="button"
        size="regular"
        type="secondary"
        :text="$t('tournaments.add_player_to_banlist')"
        @click="showModal"
      />
    </div>

    <div v-if="isVisibleModal" class="confirm-modal">
      <Modal
        type="regular"
        :title="$t('tournaments.add_player_to_banlist')"
        @close="hideModal"
      >
        <div class="match-making-ban-list__bar">
          <div class="match-making-ban-list__column">
            <label for="search-player">{{
              $t('rating.cell_player')
            }}</label>
            <UserAutocomplete id="search-player" />
          </div>
          <div class="match-making-ban-list__column">
            <label for="reason">{{
              $t('matches.complain_reason')
            }}</label>
            <Input
              id="reason"
              :placeholder="$t('matches.complain_reason')"
              size="regular"
            />
          </div>
          <div class="match-making-ban-list__column">
            <div>Автор</div>
            <div>{{ userName }}</div>
          </div>
          <div class="match-making-ban-list__column">
            <div>Дата начала бана</div>
            <pvp-datepicker
              :value="Date.now() / 1000"
              position="bottom"
              :has-time="true"
              :required="true"
              :placeholder="$t('tournaments.select_start_ban')"
              :label="$t('tournaments.time_ban-start')"
            />
          </div>
          <div class="match-making-ban-list__column">
            <div>Период блокировки</div>
            <div class="inline">
              <BaseSelect
                :label="$t('tournaments.select_time')"
                list-position="top"
                :options="pickbanTimeoutsList"
                :required="true"
                @input="
                  updateFormData({
                    name: '',
                    value: $event,
                  })
                "
              />
              <pvp-tooltip class="left">
                <icon name="info" />
                <template slot="tooltip">
                  {{ $t('tournaments.ban_periods') }}
                </template>
              </pvp-tooltip>
            </div>
          </div>
          <div class="match-making-ban-list__bar">
            <Button
              tag="button"
              size="regular"
              type="primary"
              :text="$t('actions.save')"
            />
            <Button
              tag="button"
              size="regular"
              type="secondary"
              :text="$t('actions.cancel')"
            />
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Input from '@components/v2/ui/Input.vue';
import Button from '@components/v2/ui/Button.vue';
import UserAutocomplete from '@src/components/BaseComponents/UserAutocomplete.vue';
import Modal from '@src/components/v2/Modal.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'MatchMakingBanList',
  components: {
    Input,
    Button,
    UserAutocomplete,
    Modal,
    BaseSelect,
    Icon,
  },
  data() {
    return {
      isVisibleModal: false,
    };
  },
  computed: {
    ...mapState('application', ['pickbanTimeoutsList']), // только для теста, потом удалить
    ...mapGetters('profile', ['getCurrentProfile']),

    profile() {
      return this.getCurrentProfile;
    },
    userName() {
      const { firstName, lastName } = this.profile;
      return `${firstName} ${lastName}`;
    },
  },
  methods: {
    showModal() {
      this.isVisibleModal = true;
    },
    hideModal() {
      this.isVisibleModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.match-making-ban-list {
  background-color: var(--main-color-darkgray);
  padding: 16px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
}
.match-making-ban-list__title {
  padding: 0 16px;
  margin-bottom: 24px;
}
.match-making-ban-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.match-making-ban-list__bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: top;
  gap: 12px;
}
.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}
.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;
  .select {
    width: 100%;
  }
  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 0.5em;
    font-size: 18px;
  }
}
</style>
