<template>
  <div class="heartstone-pick">
    <div class="heartstone-pick__filter">
      <h2 class="heartstone-pick__title">
        {{ $t('global.filter') }}
      </h2>
      <Input
        :placeholder="$t('global.insertTeamName')"
        @input="debounceInputHandler"
      />
    </div>
    <div class="heartstone-pick__table">
      <Table v-if="items.length">
        <template #thead>
          <TableRow>
            <TableData
              v-for="(header, idx) in tableHeaders"
              :key="idx"
              :heading="true"
              :text-align="idx === 0 ? 'left' : 'center'"
            >
              {{ header }}
            </TableData>
          </TableRow>
        </template>
        <template #tbody>
          <TableRow v-for="(row, rowIdx) in items" :key="rowIdx">
            <TableData text-align="left">
              <router-link
                :to="{ name: 'team', params: { hash: row.hash } }"
              >
                {{ row.name }}
              </router-link>
            </TableData>
            <TableData>
              {{ !row.country ? '-' : row.country.value }}
            </TableData>
            <TableData>
              {{ row.dateRegister }}
            </TableData>
            <TableData>
              <ul
                v-if="row.pick.length"
                class="heartstone-pick__list"
              >
                <li
                  v-for="(item, pIdx) in row.pick"
                  :key="pIdx"
                  class="heartstone-pick__list-item"
                >
                  <button
                    class="heartstone-pick-item"
                    @click="
                      pickClickHandler(
                        $event,
                        item.name,
                        item.value.cards,
                      )
                    "
                  >
                    <img
                      class="heartstone-pick-item__image"
                      :src="item.image"
                      alt=""
                    />
                  </button>
                </li>
              </ul>
              <template v-else>-</template>
            </TableData>
          </TableRow>
        </template>
      </Table>
      <p v-if="error" class="heartstone-pick__message">
        {{ error }}
      </p>
    </div>
    <div class="heartstone-pick__pagination">
      <ListItemPagination
        v-if="isShowPagination"
        :items-amount="pagination.itemsTotal"
        :items-on-page-amount="itemsOnPage"
        :step="3"
        @change="paginationChangeHandler"
      />
    </div>
    <!--  -->
    <Modal
      v-if="isShowModal"
      type="regular"
      :title="modalTitle"
      @close="modalToggler"
    >
      <div class="heartstone-pick__card-list">
        <div
          v-for="(card, idx) in activeDeckCards"
          :key="idx"
          class="heartstone-card"
          :class="`heartstone-card--${card.rarity.toLowerCase()}`"
        >
          <div class="heartstone-card__mana">{{ card.cost }}</div>
          <div class="heartstone-card__name">{{ card.name }}</div>
          <div class="heartstone-card__amount">x{{ card.count }}</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import Input from '@components/v2/ui/Input.vue';
import ListItemPagination from '@components/v2/ListItemPagination.vue';
import Modal from '@components/v2/Modal.vue';

export default {
  name: 'TournamentEditHeartstonePick',
  components: {
    Input,
    Table,
    TableRow,
    TableData,
    ListItemPagination,
    Modal,
  },
  data() {
    return {
      tableHeaders: ['Команда', 'Страна', 'Дата регистрации', 'Пик'],
      modalTitle: '',
      filterValue: '',
      error: '',
      items: [],
      itemsOnPage: 10,
      currentPage: 1,
      pagination: {},
      hsCardsJsonUrl: '',
      allCards: [],
      activeDeckCards: [],
      isShowModal: false,
    };
  },
  computed: {
    isShowPagination() {
      return (
        Boolean(this.pagination.pagesTotal) &&
        this.pagination.pagesTotal > 1
      );
    },
  },
  async mounted() {
    await this.fetchHeartstonePicks();
    await this.fetchAllCards();
  },
  methods: {
    async fetchHeartstonePicks() {
      const { orgId, tnId } = this.$route.params;
      this.error = '';
      try {
        const response = await api.get(
          `/organization/${orgId}/tournament/${tnId}/picks`,
          {
            params: {
              teamName: this.filterValue,
              perPage: this.itemsOnPage,
              page: this.currentPage - 1,
            },
          },
        );
        this.items = response.data.items;
        this.pagination = response.data.pagination;
        this.hsCardsJsonUrl = response.data.hsCardsJsonUrl;
      } catch (error) {
        this.items = [];
        this.pagination = {};
        this.error = error.message
          .replace('["', '')
          .replace('"]', '');
      }
    },
    async fetchAllCards() {
      const allCardsResponse = await fetch(this.hsCardsJsonUrl);
      this.allCards = await allCardsResponse.json();
    },
    modalToggler() {
      this.isShowModal = !this.isShowModal;
    },
    setActiveDeckCards(cards) {
      this.activeDeckCards = cards
        .map((el) => {
          return {
            count: el.count,
            ...this.allCards.find((e) => e.dbfId === el.id),
          };
        })
        .sort((a, b) => a.cost - b.cost);
    },
    debounceInputHandler: _.debounce(function (newValue) {
      this.items = [];
      this.pagination = {};
      this.currentPage = 1;
      this.filterValue = newValue;
      this.fetchHeartstonePicks();
    }, 600),
    pickClickHandler($event, modalTitle, cards) {
      this.setActiveDeckCards(cards);
      this.modalTitle = modalTitle;
      this.modalToggler();
    },
    paginationChangeHandler(data) {
      this.currentPage = data.currentPage;
      this.fetchHeartstonePicks();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.heartstone-pick__filter,
.heartstone-pick__message {
  padding: 16px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background: var(--main-color-darkgray);
  margin-bottom: 8px;
  @include min-tablet() {
    margin-bottom: 12px;
  }
}
.heartstone-pick__table {
  margin-bottom: 8px;
  @include min-tablet() {
    margin-bottom: 12px;
  }
}
.heartstone-pick__title {
  margin-bottom: 16px;
}
.heartstone-pick__list {
  display: flex;
  gap: 12px;
  list-style-type: none;
}
.heartstone-pick__list-item {
  max-width: 140px;
}
/*  */
.heartstone-pick-item {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: none;
  background: none;
}
.heartstone-pick-item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
/*  */
.heartstone-pick__card-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  @include min-tablet() {
    flex-wrap: wrap;
    min-height: 450px;
    max-height: 600px;
  }
}
.heartstone-card {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: 1px solid;
  border-radius: 2px;

  &--common {
    color: #c6c5c5;
    border-color: #c6c5c5;
  }
  &--rare {
    color: #2290df;
    border-color: #2290df;
  }
  &--epic {
    color: #cf55e8;
    border-color: #cf55e8;
  }
  &--legendary {
    color: #f5a623;
    border-color: #f5a623;
  }
}
.heartstone-card__mana {
  background-color: rgba(0, 0, 0, 0.1);
  color: #2290df;
}
.heartstone-card__name {
  padding: 8px;
  font-weight: 600;
}
.heartstone-card__amount {
  margin-left: auto;
}
.heartstone-card__mana,
.heartstone-card__amount {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heartstone-pick__message {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
</style>
