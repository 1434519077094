<template>
  <BaseSelect
    v-bind="$attrs"
    :label="$t('tournaments.select_format')"
    :options="pickBanSystems"
    :required="true"
    v-on="$listeners"
  >
    <template v-slot:option="{ option }">
      Best of {{ option }}
    </template>
    <template v-slot:selected-option="{ option }">
      Best of {{ option }}
    </template>
  </BaseSelect>
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'TournamentFormatSelector',
  components: {
    BaseSelect,
  },
  computed: {
    ...mapState('tournaments', ['pickBanSystems']),
  },
};
</script>
