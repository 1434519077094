<template>
  <div class="teams">
    <div class="teams-settings">
      <h2 class="teams-header">{{ $t('menu.teams') }}</h2>
      <div class="settings-container">
        <div
          class="search-container"
          :class="{
            'search-container--wide':
              !showAddTeamButton && !showUploadButton,
          }"
        >
          <Input
            v-model="participantTeam"
            size="regular"
            before-icon="lens"
            :placeholder="$t('tournament.search_placeholder')"
            @input="debouncedSearchParticipantTeam"
          />
        </div>
        <div
          v-if="showAddTeamButton || showUploadButton"
          class="btn-container"
        >
          <Button
            v-if="showUploadButton"
            tag="a"
            size="regular"
            type="tertiary"
            :text="$t('tournament.uploading')"
            :href="reportUrl"
            download
          >
            <template #slotBefore>
              <icon name="arrow-up" />
            </template>
          </Button>

          <Button
            v-if="isVisibleHashButton"
            tag="a"
            size="regular"
            type="secondary"
            :href="downloadHashes"
            :text="$t('tournament.team_hashes')"
          >
          </Button>

          <div v-click-outside="hideSearchPanel" class="search-teams">
            <Button
              v-if="showAddTeamButton"
              tag="button"
              size="regular"
              type="secondary"
              :text="addButtonText"
              @click="showSearchPanel"
            >
              <template #slotBefore><icon name="plus" /></template>
            </Button>
            <div v-if="isVisiblePanel" class="search-panel">
              <div class="search-panel__window">
                <div class="search-header">
                  <h2 class="search-panel__header">
                    {{ $t('menu.team_add') }}
                  </h2>
                  <div
                    class="icon-container"
                    @click="showSearchPanel"
                  >
                    <icon name="close" :inline="false" />
                  </div>
                </div>
                <template v-if="isVirtualTournament">
                  <div class="search-textarea">
                    <TextArea
                      ref="searchTeam"
                      class="search-textarea__textarea"
                      :rows="5"
                      :cols="40"
                      :placeholder="
                        $t('tournament.search_placeholder')
                      "
                      @change="updateVirtaulTeamsNames"
                    />
                    <Button
                      class="search-textarea__btn"
                      tag="button"
                      size="regular"
                      type="secondary"
                      :text="$t('menu.teams_add')"
                      @click="addVirtualTeams"
                    >
                    </Button>
                    <InfoBox v-if="hasErrors" variant="error">
                      {{ error }}
                    </InfoBox>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="search-input"
                    :class="{
                      'search-input--border-bottom':
                        teamsForAdd.length,
                    }"
                  >
                    <Input
                      ref="searchTeam"
                      v-model="teamNameForAdd"
                      size="regular"
                      before-icon="lens"
                      :placeholder="
                        $t('tournament.search_placeholder')
                      "
                      @input="debouncedSearchTeamForAdd({ page: 0 })"
                    />
                  </div>
                  <ul class="search-result">
                    <li
                      v-for="team in teamsForAdd"
                      :key="team.id"
                      class="search-result__item"
                    >
                      <BaseTeamLink :hash="team.hash" />
                      <Button
                        tag="button"
                        :icon-only="true"
                        size="regular"
                        type="secondary"
                        @click="addTeam(team.hash)"
                      >
                        <template #slotBefore>
                          <icon name="plus" />
                        </template>
                      </Button>
                    </li>
                    <li
                      v-if="teamsForAdd.length"
                      class="
                        search-result__item
                        search-result__item--show-more
                      "
                    >
                      <Button
                        v-if="isVisibleShowMoreButton"
                        tag="button"
                        size="regular"
                        type="secondary"
                        :text="$t('actions.show_more')"
                        @click="
                          debouncedSearchTeamForAdd({
                            page: (page += 1),
                            isMore: true,
                          })
                        "
                      >
                      </Button>
                    </li>
                  </ul>
                  <InfoBox v-if="hasErrors" variant="error">
                    {{ error }}
                  </InfoBox>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Table v-if="teams.length" class="teams-table">
      <template #thead>
        <tr>
          <th
            v-for="(header, idx) in tableHeaders"
            :key="idx"
            :class="{ alignLeft: idx === 1 }"
          >
            {{ header }}
          </th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="team in teams" :key="team.id">
          <td>
            <BaseTeamLink :hash="team.hash" />
          </td>
          <td
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 8px;
            "
          >
            <CountryFlag :code="team.arCountry.code" />
            {{ team.arCountry.value }}
          </td>
          <td>
            {{ team.dateRegister }}
          </td>
          <td>
            {{ team.status.name }}
          </td>
          <td v-if="hasExcludedField">
            <Button
              tag="button"
              size="regular"
              type="secondary"
              :text="$t('tournament.team_exclude')"
              @click="showConfirmModal(team.hash)"
            >
            </Button>
          </td>
        </tr>
      </template>
    </Table>
    <div v-if="isVisibleConfirmModal" class="confirm-modal">
      <Modal
        type="regular"
        :title="$t('actions.confirm')"
        @close="hideConfirmModal"
      >
        <InfoBox
          v-if="isSuccessExcludeTeam"
          class="confirm-message"
          variant="success"
        >
          {{ $t('tournaments.exclude_success') }}
        </InfoBox>
        <p class="confirm-text">
          {{ $t('tournaments.exclude_message') }}
        </p>
        <div v-if="isVisbleConfirmButtons" class="confirm-buttons">
          <Button
            tag="button"
            size="regular"
            type="secondary"
            :text="$t('actions.confirm')"
            @click="excludeTeam(currentTeamHash)"
          >
          </Button>
          <Button
            tag="button"
            size="regular"
            type="tertiary"
            :text="$t('actions.cancel')"
            @click="hideConfirmModal"
          >
          </Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Input from '@components/v2/ui/Input.vue';
import Button from '@components/v2/ui/Button.vue';
import Table from '@src/components/v2/Table.vue';
import TextArea from '@src/components/v2/ui/TextArea.vue';
import Modal from '@src/components/v2/Modal.vue';
import CountryFlag from '@components/BaseComponents/Country/CountryFlag';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import Icon from '@components/v2/utils/Icon.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'TournamentEditTeams',
  components: {
    Input,
    Table,
    Button,
    Modal,
    TextArea,
    CountryFlag,
    BaseTeamLink,
    Icon,
    InfoBox,
  },
  data() {
    return {
      page: 0,
      teamName: '',
      teamNameForAdd: '',
      virtualTeamsNames: '',
      participantTeam: '',
      isLoadingSave: false,
      tournamentId: '',
      organizationId: '',
      teams: [],
      error: null,
      teamsForAdd: [],
      isVisiblePanel: false,
      hasErrors: false,
      currentTeamHash: '',
      showSuccessMessage: false,
      isVisibleConfirmModal: false,
      isVisbleConfirmButtons: true,
      isVisibleShowMoreButton: true,
      isSuccessExcludeTeam: false,
      debouncedSearchTeamForAdd: null,
      debouncedSearchParticipantTeam: null,
    };
  },

  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapState('tournamentSettings', ['form', 'abilities']),

    addButtonText() {
      return this.isVirtualTournament
        ? this.$t('menu.teams_add')
        : this.$t('menu.team_add');
    },

    showUploadButton() {
      return this.teams.length && !this.isVirtualTournament;
    },

    showAddTeamButton() {
      return (
        (this.form?.organizationCanRegisterTeam.value ||
          this.isVirtualTournament) &&
        !this.isStartedTournament
      );
    },

    isVirtualTournament() {
      return this.form.isVirtual.value;
    },

    reportUrl() {
      const url = `/organization/${this.organizationId}/tournament/${this.tournamentId}/participant/report/`;
      return url;
    },

    downloadHashes() {
      const url = `/organization/${this.organizationId}/tournament/${this.tournamentId}/participant/hashlist/`;
      return url;
    },

    hasExcludedField() {
      const firstItem = 0;
      const teamObject = this.teams[firstItem].actions;
      return Object.prototype.hasOwnProperty.call(
        teamObject,
        'exclude',
      );
    },

    tableHeaders() {
      return this.hasExcludedField
        ? [
            this.$t('ratings.table_team'),
            this.$t('ratings.table_country'),
            this.$t('tournaments.registerDate'),
            this.$t('team.th_status'),
            this.$t('team.actions'),
          ]
        : [
            this.$t('ratings.table_team'),
            this.$t('ratings.table_country'),
            this.$t('tournaments.registerDate'),
            this.$t('team.th_status'),
          ];
    },

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    isStartedTournament() {
      const statusIsStarted = 7;
      return this.tournament?.idStatus === statusIsStarted;
    },

    isVisibleHashButton() {
      return (
        this.form.fksPdEnabled?.value && !this.isVirtualTournament
      );
    },
  },

  watch: {
    isVisiblePanel() {
      if (this.isVisiblePanel) {
        this.focusSearchTeams();
      }
    },
  },

  created() {
    this.debouncedSearchTeamForAdd = _.debounce(
      this.searchTeamForAdd,
      300,
    );
    this.debouncedSearchParticipantTeam = _.debounce(
      this.searchParticipantTeam,
      300,
    );
  },

  mounted() {
    this.tournamentId = this.$route.params.tnId;
    this.organizationId = this.$route.params.orgId;
    this.fetchTeams();
  },

  methods: {
    ...mapActions('tournamentSettings', [
      'fetchTournamentFields',
      'saveTournamentFields',
    ]),
    ...mapActions('tournaments', ['fetchTournamentPage']),

    showConfirmModal(teamHash) {
      this.isVisibleConfirmModal = true;
      this.currentTeamHash = teamHash;
      this.isSuccessExcludeTeam = false;
      this.isVisbleConfirmButtons = true;
    },

    hideConfirmModal() {
      this.isVisibleConfirmModal = false;
    },

    updateVirtaulTeamsNames(value) {
      this.virtualTeamsNames = value;
    },

    focusSearchTeams() {
      this.$nextTick(() => {
        const searchDiv = this.$refs.searchTeam.$el;
        const searchInput =
          searchDiv?.children[0]?.children[1]?.firstElementChild ??
          searchDiv;
        searchInput.focus();
      });
    },

    showSearchPanel() {
      this.isVisiblePanel = !this.isVisiblePanel;
    },

    hideSearchPanel() {
      this.hasErrors = false;
      this.isVisiblePanel = false;
    },

    async fetchTeams() {
      const { data } = await api.get(
        `tournament/${this.tournamentId}/teams`,
      );
      this.$store.commit('teams/UPDATE_TEAMS', data);
      this.teams = Object.values(data);
    },

    async searchTeamForAdd({ page, isMore }) {
      this.hasErrors = false;
      this.isVisibleShowMoreButton = true;
      if (!isMore) {
        this.page = 0;
      }
      const params = {
        name: this.teamNameForAdd,
        page,
      };
      const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/searchteams`;
      const { teams } = await api.get(url, { params });
      if (teams.length === 0) {
        this.isVisibleShowMoreButton = false;
      }
      this.$store.commit('teams/UPDATE_TEAMS', teams);
      this.teamsForAdd = isMore
        ? [...this.teamsForAdd, ...teams]
        : teams;
    },

    async searchParticipantTeam() {
      const params = {
        'f[name]': this.participantTeam,
      };
      const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/filterteams`;
      const { data } = await api.get(url, { params });
      this.teams = Object.values(data);
    },

    async addTeam(teamHash) {
      try {
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/register`;
        await api.post(url, { hash: teamHash });
        this.teamsForAdd = this.teamsForAdd.filter(
          (team) => team.hash !== teamHash,
        );
        await this.fetchTeams();
      } catch (err) {
        this.hasErrors = true;
        this.error = err.error[0];
      }
    },

    async addVirtualTeams() {
      this.hasErrors = false;
      const splittedNames = this.virtualTeamsNames.trim().split('\n');
      const params = {
        teams: splittedNames,
      };
      const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/virtualregister`;
      try {
        await api.post(url, params);
        this.$refs.searchTeam.$el.value = '';
        this.showSearchPanel();
        await this.fetchTeams();
      } catch (err) {
        this.hasErrors = true;
        this.error = err.error[0];
      }
    },

    async excludeTeam(teamHash) {
      this.isSuccessExcludeTeam = false;
      const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/exclude`;
      await api.post(url, { hash: teamHash });
      this.isSuccessExcludeTeam = true;
      this.isVisbleConfirmButtons = false;
      await this.fetchTeams();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.teams-header {
  line-height: 26px;
  margin-bottom: 12px;
  @include min-tablet() {
    margin-bottom: 16px;
  }
}

.teams-settings {
  background-color: var(--main-color-darkgray);
  padding: 16px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  margin-bottom: 12px;
}

.settings-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  @include min-tablet() {
    flex-wrap: nowrap;
    gap: 0 32px;
  }
}

.btn-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  @include min-tablet() {
    align-items: flex-start;
    flex-shrink: 0;
    min-width: 50%;
    justify-content: flex-end;
  }
}

.teams-save-changes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 12px 16px 32px 16px;
  position: relative;
  @include min-tablet() {
    justify-content: flex-end;
    padding-top: 12px;
    padding-right: 0;
  }
}

.save-status {
  position: absolute;
  bottom: 0;
  left: 25%;
  margin-top: 8px;
  color: var(--additional-color-green);
  opacity: 0;
  @include min-tablet() {
    top: 8px;
    left: 0;
  }
}

.save-button {
  width: 100%;
  @include min-tablet() {
    width: auto;
  }
}

.alignLeft {
  text-align: left;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fade-out;
  animation-duration: 2.5s;
}

.settings-container {
  position: relative;
}

.search-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  inset: 0;
  z-index: 100;
  padding-top: 100px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(245, 245, 247, 0.12);
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  @include min-tablet() {
    position: absolute;
    top: 100%;
    padding-top: 0;
    bottom: unset;
  }
}

.search-panel__window {
  background: var(--main-color-darkgray);
  margin-top: auto;
  max-height: 600px;
  height: 100%;
  overflow: overlay;
  border-radius: 6px;

  @include min-tablet() {
    height: unset;
    border: 1px solid rgba(245, 245, 247, 0.12);
  }
}

.search-header {
  background-color: #242429;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 110;
  @include min-tablet() {
    display: none;
  }
}

.search-container {
  width: 100%;
  @include min-tablet() {
    width: calc(50% - 16px);
  }
}

.search-container--wide {
  width: 100%;
}

.search-input {
  padding: 24px 16px;

  background-color: var(--main-color-darkgray);
  position: sticky;
  top: 65px;
  z-index: 110;
  @include min-tablet() {
    padding: 12px;
    top: 0;
  }

  &--border-bottom {
    border-bottom: 1px solid rgba(245, 245, 247, 0.12);
  }
}

.search-textarea {
  padding: 16px;

  &__btn {
    width: 100%;
    margin-bottom: 8px;
  }
  &__textarea {
    width: 100%;
    margin-bottom: 8px;
  }
}

.search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0 16px;
  overflow: auto;
  @include min-tablet() {
    padding: 0;
    max-height: 400px;
  }
}

.search-result__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  @include min-tablet() {
    padding: 12px;
  }
}

.search-result__item--show-more {
  padding: 12px;
}

.icon-container {
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.confirm-text,
.confirm-message {
  margin-bottom: 8px;
  @include min-tablet() {
    margin-bottom: 12px;
  }
}

.confirm-buttons {
  display: flex;
  gap: 12px;
}
</style>
