import { createMachine } from 'xstate';

const loadingMachine = createMachine({
  id: 'loading',
  initial: 'idle',
  states: {
    idle: {
      on: {
        FETCH: { target: 'loading' },
      },
    },
    loading: {
      on: {
        RESOLVE: { target: 'success' },
        REJECT: { target: 'failure' },
      },
    },
    failure: {
      on: {
        NOMATCHES: { target: 'noMatches' },
        ERRORFILTERS: { target: 'errorFilters' },
      },
    },
    noMatches: {
      on: { REFETCH: 'idle' },
    },
    errorFilters: {
      on: { REFETCH: 'idle' },
    },
    success: {
      on: { REFETCH: 'idle' },
    },
  },
});

export default loadingMachine;
