<template>
  <div>
    <tournament-edit-form>
      <h2>{{ $t('tournaments.settings_main') }}</h2>

      <!--  Игра  -->
      <GameSelect
        class="indent settings-control"
        :value="form.idGame.value"
        :required="true"
        :label="$t('games.single')"
        :disabled="true"
      />

      <!--  Формат  -->
      <div class="inline">
        <BaseSelect
          class="indent settings-control"
          list-position="top"
          :label="$t('tournaments.system')"
          :value="form.numTeamMembers.value"
          :disabled="!abilities.canEditNumTeamMembers"
          label-key="name"
          value-key="id"
          :required="true"
          :readonly="appIsMobile"
          :options="membersCount"
          :error-message="form.numTeamMembers.error"
          :placeholder="$t('tournaments.select_format')"
          @input="updateMembersCount($event)"
        />
        <pvp-tooltip>
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_format') }}
          </template>
        </pvp-tooltip>
      </div>

      <!-- Switcher "AntiCheat" -->
      <BaseSwitcher
        v-if="getIsHub && form.isAnticheatEnabled"
        :value="form.isAnticheatEnabled.value"
        :error-message="form.isAnticheatEnabled.error"
        class="indent indent-down flex"
        @input="toggleAnticheatEnable"
      >
        <span slot="label" class="info">
          {{ $t('global.anticheat') }}
          <pvp-tooltip>
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_is_anticheat') }}
            </template>
          </pvp-tooltip>
        </span>
      </BaseSwitcher>

      <template v-if="!getIsHub">
        <!--   Настройка запасных   -->
        <div class="inline">
          <BaseSelect
            class="indent"
            list-position="top"
            :label="$t('tournaments.reserve')"
            :value="defaultReserveCount"
            :disabled="!abilities.canEditMaxReservePlayersCount"
            label-key="name"
            value-key="id"
            :required="true"
            :readonly="appIsMobile"
            :options="reserveCount"
            :error-message="form.maxReservePlayersCount.error"
            :placeholder="$t('tournaments.select_reserve')"
            @input="
              updateFormData({
                name: form.maxReservePlayersCount.name,
                value: $event,
              })
            "
          />
          <pvp-tooltip>
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_reserve') }}
            </template>
          </pvp-tooltip>
        </div>

        <BaseSwitcher
          :value="form.checkinMatchEnable.value"
          :disabled="!abilities.canEditCheckinMatchEnable"
          :error-message="form.checkinMatchEnable.error"
          class="indent"
          @input="toggleMatchCheckin"
        >
          <span slot="label" class="info">
            {{ $t('matches.checkin_title') }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t('tournaments.tooltips_settings_match-confirm')
                }}
              </template>
            </pvp-tooltip>
          </span>
        </BaseSwitcher>
        <template v-if="form.checkinMatchEnable.value">
          <div class="label-text">
            {{ $t('tournaments.settings_confirmation-minutes') }}
          </div>
          <BaseSelect
            :required="true"
            :disabled="!abilities.canEditCheckinMatchTimeout"
            :value="form.checkinMatchTimeout.value"
            :options="confirmationTimeList"
            :error-message="form.checkinMatchTimeout.error"
            @input="
              updateFormData({
                name: form.checkinMatchTimeout.name,
                value: $event,
              })
            "
          >
            <template #selected-option="{ option }">
              {{ $tc('date.minutes_pluralization', option) }}
            </template>
            <template #option="{ option }">
              {{ $tc('date.minutes_pluralization', option) }}
            </template>
          </BaseSelect>
        </template>

        <!-- Switcher "AntiCheat" -->
        <BaseSwitcher
          v-if="form.isAnticheatEnabled"
          :value="form.isAnticheatEnabled.value"
          :error-message="form.isAnticheatEnabled.error"
          class="indent indent-down flex"
          @input="toggleAnticheatEnable"
        >
          <span slot="label" class="info">
            {{ $t('global.anticheat') }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{ $t('tournaments.tooltips_settings_is_anticheat') }}
              </template>
            </pvp-tooltip>
          </span>
        </BaseSwitcher>

        <div class="divider" />

        <h2 class="schedule-title">
          {{ $t('tournaments.settings_schedule') }}
        </h2>
        <BaseSwitcher
          :value="form.needCheckIn.value"
          :disabled="!abilities.canEditNeedCheckIn"
          :error-message="form.needCheckIn.error"
          class="indent"
          @input="
            updateFormData({
              name: form.needCheckIn.name,
              value: $event,
            })
          "
        >
          <span slot="label" class="info">
            {{ $t('tournaments.settings_confirmation') }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_tournament-confirm',
                  )
                }}
              </template>
            </pvp-tooltip>
          </span>
        </BaseSwitcher>

        <div class="label-text">
          {{ $t('tournaments.settings_confirmation-time') }}
        </div>
        <div class="time-check">
          <div class="time-item">
            <div class="time-label">
              {{ timeBeforeStart.day.text }}
            </div>
            <BaseInput
              :value="timeBeforeStart.day.value"
              :status="form.timeBeforeStart.error && 'error'"
              :disabled="!form.needCheckIn.value"
              type="number"
              @focus="
                updateFormData({
                  name: form.timeBeforeStart.name,
                  error: '',
                })
              "
              @change="(num) => changeDate('day', num)"
            />
          </div>
          <div class="time-item">
            <div class="time-label">
              {{ timeBeforeStart.hour.text }}
            </div>
            <BaseInput
              :value="timeBeforeStart.hour.value"
              :disabled="!form.needCheckIn.value"
              :status="form.timeBeforeStart.error && 'error'"
              type="number"
              @focus="
                updateFormData({
                  name: form.timeBeforeStart.name,
                  error: '',
                })
              "
              @change="(num) => changeDate('hour', num)"
            />
          </div>
          <div class="time-item">
            <div class="time-label">
              {{ timeBeforeStart.minute.text }}
            </div>
            <BaseInput
              :value="timeBeforeStart.minute.value"
              :disabled="
                !form.needCheckIn.value ||
                !abilities.canEditTimeBeforeStart
              "
              :status="form.timeBeforeStart.error && 'error'"
              type="number"
              @focus="
                updateFormData({
                  name: form.timeBeforeStart.name,
                  error: '',
                })
              "
              @change="(num) => changeDate('minute', num)"
            />
          </div>
        </div>
        <div v-if="form.timeBeforeStart.error" class="error-text">
          {{ form.timeBeforeStart.error }}
        </div>

        <div class="inline">
          <pvp-datepicker
            :value="form.tsStartReg.value"
            position="bottom"
            :has-time="true"
            :disabled="!abilities.canEditTsStartReg"
            :error-message="form.tsStartReg.error"
            :required="true"
            :placeholder="$t('tournaments.select_dateStart')"
            :label="$t('tournaments.time_reg-start')"
            @update:error-message="
              updateFormData({
                name: form.tsStartReg.name,
                error: '',
              })
            "
            @input="
              (value) =>
                updateFormData({ name: form.tsStartReg.name, value })
            "
          />
          <pvp-tooltip>
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_reg-start') }}
            </template>
          </pvp-tooltip>
        </div>

        <div class="inline">
          <pvp-datepicker
            :value="form.tsStartRun.value"
            position="bottom"
            :has-time="true"
            :disabled="!abilities.canEditTsStartRun"
            :error-message="form.tsStartRun.error"
            :required="true"
            :placeholder="$t('tournaments.select_dateStart')"
            :label="$t('tournaments.time_start')"
            @update:error-message="
              updateFormData({
                name: form.tsStartRun.name,
                error: '',
              })
            "
            @input="
              (value) =>
                updateFormData({ name: form.tsStartRun.name, value })
            "
          />
          <pvp-tooltip>
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_time-start') }}
            </template>
          </pvp-tooltip>
        </div>

        <div class="inline">
          <pvp-datepicker
            :value="form.tsEndRun.value"
            position="bottom"
            :has-time="true"
            :error-message="form.tsEndRun.error"
            :disabled="!abilities.canEditTsEndRun"
            :required="true"
            :placeholder="$t('tournaments.select_dateEnd')"
            :label="$t('tournaments.time_end')"
            @update:error-message="
              updateFormData({ name: form.tsEndRun.name, error: '' })
            "
            @input="
              (value) =>
                updateFormData({ name: form.tsEndRun.name, value })
            "
          />
          <pvp-tooltip>
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_time-end') }}
            </template>
          </pvp-tooltip>
        </div>
      </template>
    </tournament-edit-form>
  </div>
</template>

<script>
import TournamentEditForm from '@components/TournamentComponents/Settings/TournamentSettingsForm.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import GameSelect from '@components/BaseComponents/Select/GameSelect.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';

export default {
  components: {
    TournamentEditForm,
    Icon,
    GameSelect,
    BaseSwitcher,
    BaseInput,
    BaseSelect,
  },
  computed: {
    ...mapGetters('tournaments', ['getTournamentSettings']),
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapGetters('tournamentSettings', ['getIsHub']),
    ...mapGetters('application', ['appIsMobile']),

    tournamentSettings() {
      return this.getTournamentSettings(this.form.idGame.value) || {};
    },

    systemSettings() {
      return this.tournamentSettings?.systemSettings?.[
        this.form.idSystem.value
      ];
    },

    confirmationTimeList() {
      return [5, 10, 15, 20, 25, 30];
    },

    timeBeforeStart() {
      const format = (code, num) => {
        const regexp = new RegExp(num);
        return {
          value: num > 9 ? num : `0${num > 0 ? num : 0}`,
          text: this.$tc(`date.${code}_pluralization`, num).replace(
            regexp,
            '',
          ),
        };
      };

      const value = this.form?.timeBeforeStart?.value || 0;
      const minutes = value % 60;
      const hours = ((value - minutes) % 1440) / 60;
      const days = (value - hours * 60 - minutes) / (24 * 60);

      return {
        day: format('days', days),
        hour: format('hours', hours),
        minute: format('minutes', minutes),
      };
    },

    membersCount() {
      return this.systemSettings?.teamSize?.map((item) => ({
        id: item,
        name: `${item}x${item}`,
      }));
    },

    reserveCount() {
      const TEAM_MEMBERS = this.form.numTeamMembers.value;
      const RESERVE_MEMBERS =
        this.systemSettings?.reservePlayersMaxNumber[TEAM_MEMBERS];
      const RESERVE_OPTIONS = [];

      // eslint-disable-next-line
      for (let i = 0; i <= RESERVE_MEMBERS; i++) {
        RESERVE_OPTIONS.push({
          id: i,
          name: i,
        });
      }

      return RESERVE_OPTIONS;
    },

    defaultReserveCount() {
      return this.form.maxReservePlayersCount.value ?? 1;
    },
  },

  mounted() {
    if (this.form.maxReservePlayersCount.value === null) {
      this.form.maxReservePlayersCount.value = 1;
    }
  },

  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    changeDate(code, { target }) {
      let result = 0;

      Object.entries(this.timeBeforeStart).forEach(
        ([key, { value }]) => {
          value = key === code ? target.value : value;
          if (key === 'minute') {
            result += +value;
          }
          if (key === 'hour') {
            result += value * 60;
          }
          if (key === 'day') {
            result += value * 60 * 24;
          }
        },
      );

      this.updateFormData({
        name: this.form.timeBeforeStart.name,
        value: result,
      });
    },

    toggleMatchCheckin(value) {
      this.updateFormData({
        name: this.form?.pickBanSystem?.name,
        value,
      });
      this.updateFormData({
        name: this.form.checkinMatchEnable.name,
        value,
      });
    },

    toggleAnticheatEnable(value) {
      this.updateFormData({
        name: this.form?.isAnticheatEnabled.name,
        value,
      });
    },

    updateMembersCount(value) {
      this.updateFormData({
        name: this.form.numTeamMembers.name,
        value,
      });
      this.updateFormData({
        name: this.form.maxReservePlayersCount.name,
        value: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indent {
  &,
  &.select {
    margin-top: 30px;
  }
}

.label-text {
  color: rgba(white, 0.5);
  font-size: em(14px);
  margin-top: 8px;
  margin-bottom: em(6px);
}

.error-text {
  font-size: em(13px);
  color: $orangey-red;
  margin-top: em(8px);
  line-height: 1.25;
  max-width: 420px;
}

.time {
  &-check {
    display: flex;
    max-width: 420px;
    justify-content: space-between;
  }

  &-label {
    position: absolute;
    right: 1px;
    bottom: 0px;
    color: rgba(white, 0.5);
    background-color: $dark-two;
    font-size: 14px;
    padding: em(6px) em(12px);
  }

  &-item {
    width: 130px;
    position: relative;

    ::v-deep input {
      padding-right: 50%;
    }
  }
}

.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .select {
    margin-top: 1em;
    width: 100%;
    max-width: 420px;
  }

  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 18px;
  }
}

.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-bottom: 2px;
  }
}

.flex {
  display: flex;
}

.indent-down {
  margin-bottom: 30px;
}

.settings-control {
  max-width: 420px;
}
</style>
