<template>
  <div>
    <BaseSwitcher
      :value="form.hsLoadDeck.value"
      :error-message="form.hsLoadDeck.error"
      @input="tooglerClickHandler"
    >
      <span slot="label" class="info">
        {{ $t('pick.require_decks') }}
        <pvp-tooltip>
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_hs-upload-pick') }}
          </template>
        </pvp-tooltip>
      </span>
    </BaseSwitcher>

    <div v-if="form.hsLoadDeck.value">
      <div class="label">{{ $t('pick.select_count') }}</div>
      <pvp-btn
        v-for="(num, key) in [1, 2, 3, 4, 5]"
        :key="`${key}-pick`"
        :variant="
          form.hsPickCnt.value === num ? 'primary' : 'secondary'
        "
        :error-message="form.hsPickCnt.error"
        @click="
          updateFormData({ name: form.hsPickCnt.name, value: num })
        "
      >
        {{ num }}
      </pvp-btn>
      <pvp-tooltip class="left">
        <Icon name="info" inline />
        <template slot="tooltip">
          {{ $t('tournaments.tooltips_settings_hs-class-count') }}
        </template>
      </pvp-tooltip>
    </div>

    <div>
      <BaseSwitcher
        :value="form.pickBanSystem.value"
        :error-message="form.pickBanSystem.error"
        @input="tooglerClickHandler"
      >
        <span slot="label" class="info">
          {{ $t('matches.hs_block') }}
          <pvp-tooltip>
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_hs-ban-count') }}
            </template>
          </pvp-tooltip>
        </span>
      </BaseSwitcher>
    </div>

    <template v-if="form.pickBanSystem.value">
      <div class="label">{{ $t('matches.hs_blockcount') }}</div>
      <pvp-btn
        v-for="(num, key) in [1, 2, 3]"
        :key="`${key}-ban`"
        :variant="
          form.hsBanCnt.value === num ? 'primary' : 'secondary'
        "
        @click="
          updateFormData({ name: form.hsBanCnt.name, value: num })
        "
      >
        {{ num }}
      </pvp-btn>
    </template>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';

export default {
  name: 'HsSettings',
  components: {
    Icon,
    BaseSwitcher,
  },
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    tooglerClickHandler(newValue) {
      this.updateFormData({
        name: this.form.hsLoadDeck.name,
        value: newValue,
      });
      this.updateFormData({
        name: this.form.pickBanSystem.name,
        value: newValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.switcher {
  margin-top: 30px;
}

.label {
  font-size: 14px;
  color: rgba(white, 0.5);
  margin-bottom: 4px;
  margin-top: 16px;
}

.button {
  width: 32px;
  height: 32px;

  & + & {
    margin-left: 10px;
  }
}

.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.left {
  margin-left: 8px;
}

::v-deep .left {
  .icon {
    margin-bottom: -3px;
  }
}
</style>
