<template>
  <final-round-settings />
</template>

<script>
import FinalRoundSettings from '@components/TournamentComponents/Settings/FinalRoundSettings.vue';

export default {
  name: 'DeSettings',
  components: { FinalRoundSettings },
};
</script>

<style lang="scss" scoped></style>
