<template>
  <div>
    <tournament-settings-form>
      <h2>{{ $t('global.additionally') }}</h2>

      <template v-if="getIsHub">
        <BaseSwitcher
          :value="form.showTeamPriority.value"
          :disabled="!abilities.canEditShowTeamPriority"
          :error-message="form.showTeamPriority.error"
          @input="
            updateFormData({
              name: form.showTeamPriority.name,
              value: $event,
            })
          "
        >
          <template slot="label">
            {{
              $t('tournaments.settings_additionally_priority-show')
            }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-priority',
                  )
                }}
              </template>
            </pvp-tooltip>
          </template>
        </BaseSwitcher>

        <BaseSwitcher
          :value="form.oneTeamResultsEnabled.value"
          :disabled="!abilities.canEditOneTeamResultsEnabled"
          @input="
            updateFormData({
              name: form.oneTeamResultsEnabled.name,
              value: $event,
            })
          "
        >
          <template slot="label">
            {{
              $t(
                'tournaments.settings_additionally_score-auto-confirm',
              )
            }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-auto-apply',
                  )
                }}
              </template>
            </pvp-tooltip>
          </template>
        </BaseSwitcher>

        <BaseInput
          v-if="form.oneTeamResultsEnabled.value"
          type="number"
          class="settings-control"
          :label="
            $t('tournaments.settings_additionally_result-timeout')
          "
          :value="form.enterResultsAfter.value"
          :disabled="!abilities.canEditEnterResultsAfter"
          :error-message.sync="form.enterResultsAfter.error"
          @input="
            updateFormData({
              name: form.enterResultsAfter.name,
              value: Number($event),
            })
          "
        />
      </template>

      <template v-else>
        <BaseSwitcher
          :value="form.changeTeamRosterAfterRegister.value"
          :disabled="!abilities.canEditChangeTeamRosterAfterRegister"
          :error-message="form.changeTeamRosterAfterRegister.error"
          @input="
            updateFormData({
              name: form.changeTeamRosterAfterRegister.name,
              value: $event,
            })
          "
        >
          <template slot="label">
            {{ $t('tournaments.settings_additionally_team-change') }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-allow',
                  )
                }}
              </template>
            </pvp-tooltip>
          </template>
        </BaseSwitcher>

        <BaseSwitcher
          v-if="form.wfTeamRegCheckCompendium"
          :disabled="!abilities.canEditWfTeamRegCheckCompendium"
          :value="form.wfTeamRegCheckCompendium.value"
          :error-message="form.wfTeamRegCheckCompendium.error"
          @input="
            updateFormData({
              name: form.wfTeamRegCheckCompendium.name,
              value: $event,
            })
          "
        >
          <template slot="label">
            {{
              $t('tournaments.settings_additionally_compendium-check')
            }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-compendium',
                  )
                }}
              </template>
            </pvp-tooltip>
          </template>
        </BaseSwitcher>

        <BaseSwitcher
          :value="form.isVirtual.value"
          :warning-message="virtualWarning"
          :error-message="form.isVirtual.error"
          :disabled="
            form.isVirtual.defaultValue || !abilities.canEditIsVirtual
          "
          @input="
            updateFormData({
              name: form.isVirtual.name,
              value: $event,
            })
          "
        >
          <template slot="label">
            {{ $t('tournaments.virtual_create') }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-virtual',
                  )
                }}
              </template>
            </pvp-tooltip>
          </template>
        </BaseSwitcher>

        <BaseSwitcher
          :value="form.showTeamPriority.value"
          :disabled="!abilities.canEditShowTeamPriority"
          :error-message="form.showTeamPriority.error"
          @input="
            updateFormData({
              name: form.showTeamPriority.name,
              value: $event,
            })
          "
        >
          <template slot="label">
            {{
              $t('tournaments.settings_additionally_priority-show')
            }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-priority',
                  )
                }}
              </template>
            </pvp-tooltip>
          </template>
        </BaseSwitcher>

        <BaseSwitcher
          :value="form.oneTeamResultsEnabled.value"
          :disabled="!abilities.canEditOneTeamResultsEnabled"
          @input="
            updateFormData({
              name: form.oneTeamResultsEnabled.name,
              value: $event,
            })
          "
        >
          <template slot="label">
            {{
              $t(
                'tournaments.settings_additionally_score-auto-confirm',
              )
            }}
            <pvp-tooltip>
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-auto-apply',
                  )
                }}
              </template>
            </pvp-tooltip>
          </template>
        </BaseSwitcher>

        <BaseInput
          v-if="form.oneTeamResultsEnabled.value"
          type="number"
          class="settings-control"
          :label="
            $t('tournaments.settings_additionally_result-timeout')
          "
          :value="form.enterResultsAfter.value"
          :disabled="!abilities.canEditEnterResultsAfter"
          :error-message.sync="form.enterResultsAfter.error"
          @input="
            updateFormData({
              name: form.enterResultsAfter.name,
              value: Number($event),
            })
          "
        />

        <template v-if="isVisible">
          <BaseSwitcher
            :value="form.organizationCanRegisterTeam.value"
            :disabled="!abilities.canEditOrganizationCanRegisterTeam"
            @input="
              updateFormData({
                name: form.organizationCanRegisterTeam.name,
                value: $event,
              })
            "
          >
            <template slot="label">
              {{
                $t(
                  'tournaments.settings_additionally_team-add-manual',
                )
              }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{
                    $t(
                      'tournaments.tooltips_settings_additionally-manual',
                    )
                  }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>

          <BaseSwitcher
            :value="form.checkSimultaneous.value"
            :disabled="!abilities.canEditCheckSimultaneous"
            :error-message.sync="form.checkSimultaneous.error"
            @input="
              updateFormData({
                name: form.checkSimultaneous.name,
                value: $event,
              })
            "
          >
            <template slot="label">
              {{
                $t('tournaments.settings_additionally_restriction')
              }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{
                    $t(
                      'tournaments.tooltips_settings_additionally-same-time',
                    )
                  }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>

          <BaseInput
            v-if="form.checkSimultaneous.value"
            :value="form.simultaneousTag.value"
            :label="$t('tournaments.settings_additionally_series')"
            :disabled="!abilities.canEditSimultaneousTag"
            @input="
              updateFormData({
                name: form.simultaneousTag.name,
                value: $event,
              })
            "
          />

          <div class="divider" />

          <h5 class="label">{{ $t('players.restrictions') }}</h5>
          <div class="inline-group">
            <BaseInput
              :value="form.playerAge.value"
              :disabled="!abilities.canEditPlayerAge"
              :error-message.sync="form.playerAge.error"
              :placeholder="18"
              :label="$t('global.age')"
              @input="
                updateFormData({
                  name: form.playerAge.name,
                  value: Number($event) || null,
                })
              "
            />
            <pvp-tooltip class="left">
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t('tournaments.tooltips_settings_additionally-age')
                }}
              </template>
            </pvp-tooltip>
          </div>

          <div class="inline-group">
            <BaseSelect
              :value="form.playerRequired.value"
              :label="$t('players.required_field')"
              :options="userRequiredFields"
              :disabled="!abilities.canEditPlayerRequired"
              :placeholder="$t('players.required_select')"
              :multiple="true"
              label-key="name"
              value-key="code"
              list-position="top"
              @input="
                updateFormData({
                  name: form.playerRequired.name,
                  value: $event,
                })
              "
            />
            <pvp-tooltip class="left">
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-required',
                  )
                }}
              </template>
            </pvp-tooltip>
          </div>

          <div class="inline-group">
            <CountrySelect
              class="label"
              :value="form.playerCountry.value"
              :disabled="!abilities.canEditPlayerCountry"
              list-position="top"
              :label="$t('players.country_label')"
              @input="
                updateFormData({
                  name: form.playerCountry.name,
                  value: $event,
                })
              "
            />
            <pvp-tooltip class="left">
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-country',
                  )
                }}
              </template>
            </pvp-tooltip>
          </div>

          <div class="inline-group">
            <BaseInput
              :value="form.playerCity.value"
              :label="$t('players.city_label')"
              :disabled="!abilities.canEditPlayerCity"
              :error-message="form.playerCity.error"
              :placeholder="$t('players.city_label')"
              @input="
                updateFormData({
                  name: form.playerCity.name,
                  value: $event || null,
                })
              "
            />
            <pvp-tooltip class="left">
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-city',
                  )
                }}
              </template>
            </pvp-tooltip>
          </div>

          <BaseSwitcher
            :value="form.premiumFks.value"
            :disabled="!abilities.canEditPremiumFks"
            :error-message.sync="form.premiumFks.error"
            @input="
              updateFormData({
                name: form.premiumFks.name,
                value: $event,
              })
            "
          >
            <template slot="label">
              {{ $t('tournaments.settings_additionally_fcs') }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{
                    $t(
                      'tournaments.tooltips_settings_additionally-fcs-premium',
                    )
                  }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>

          <BaseSwitcher
            :value="form.needFksTerms.value"
            :disabled="!abilities.canEditNeedFksTerms"
            :error-message.sync="form.needFksTerms.error"
            @input="
              updateFormData({
                name: form.needFksTerms.name,
                value: $event,
              })
            "
          >
            <template slot="label">
              {{ $t('terms.accept_fcs_agreement') }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{
                    $t(
                      'tournaments.tooltips_settings_additionally-fcs-terms',
                    )
                  }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>

          <BaseSwitcher
            :value="form.needFksHash.value"
            :disabled="!abilities.canEditNeedFksHash"
            :error-message.sync="form.needFksHash.error"
            @input="
              updateFormData({
                name: form.needFksHash.name,
                value: $event,
              })
            "
          >
            <template slot="label">
              {{ $t('profile.fcs_title') }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{
                    $t(
                      'tournaments.tooltips_settings_additionally-fcs-account',
                    )
                  }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>

          <BaseSwitcher
            :value="getFksPdEnabled"
            :disabled="!abilities.canEditFksPdFields"
            @input="showPDList"
          >
            <template slot="label">
              {{ $t('profile.profile_pd') }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{ $t('tournaments.tooltips_settings_profile_pd') }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>

          <div v-if="getFksPdEnabled" class="pd indent">
            <div class="pd-label">
              {{ $t('profile.pd_select_label') }}
              <pvp-tooltip class="left">
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{
                    $t(
                      'tournaments.tooltips_settings_pd_select_tooltip',
                    )
                  }}
                </template>
              </pvp-tooltip>
            </div>
            <BaseSelect
              class="settings-control"
              :value="form.fksPdFields.value"
              placeholder="Список ПД для турнира"
              list-position="bottom"
              :multiple="true"
              :limit="29"
              value-key="value"
              label-key="label"
              :options="getFksPersonalDataList"
              @input="
                updateFormData({
                  name: form.fksPdFields.name,
                  value: $event,
                })
              "
            />
          </div>

          <div class="divider" />

          <h5 class="label">{{ $t('teams.restrictions') }}</h5>
          <BaseSwitcher
            :value="form.teamLogo.value"
            :disabled="!abilities.canEditTeamLogo"
            @input="
              updateFormData({
                name: form.teamLogo.name,
                value: $event,
              })
            "
          >
            <template slot="label">
              {{ $t('teams.logo_title') }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{
                    $t(
                      'tournaments.tooltips_settings_additionally-logo',
                    )
                  }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>

          <div class="inline-group">
            <CountrySelect
              class="label"
              :value="form.teamCountry.value"
              :disabled="!abilities.canEditTeamCountry"
              list-position="top"
              :label="$t('players.country_label')"
              @input="
                updateFormData({
                  name: form.teamCountry.name,
                  value: $event,
                })
              "
            />
            <pvp-tooltip class="left">
              <Icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t(
                    'tournaments.tooltips_settings_additionally-country',
                  )
                }}
              </template>
            </pvp-tooltip>
          </div>

          <BaseSwitcher
            :value="form.captainDiscordRequired.value"
            :disabled="!abilities.canEditCaptainDiscordRequired"
            @input="
              updateFormData({
                name: form.captainDiscordRequired.name,
                value: $event,
              })
            "
          >
            <template #label>
              {{ $t('teams.captain_discord_check') }}
              <pvp-tooltip>
                <Icon name="info" inline />
                <template slot="tooltip">
                  {{ $t('teams.captain_discord_check_tooltip') }}
                </template>
              </pvp-tooltip>
            </template>
          </BaseSwitcher>
        </template>

        <pvp-btn
          class="toggle-btn"
          variant="link"
          @click="isVisible = !isVisible"
        >
          {{ $t(isVisible ? 'global.collapse' : 'global.expand') }}
        </pvp-btn>
      </template>
    </tournament-settings-form>
  </div>
</template>

<script>
import TournamentSettingsForm from '@components/TournamentComponents/Settings/TournamentSettingsForm.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import CountrySelect from '@components/BaseComponents/Select/CountrySelect.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';

export default {
  name: 'TournamentEditOther',
  components: {
    TournamentSettingsForm,
    CountrySelect,
    Icon,
    BaseSwitcher,
    BaseInput,
    BaseSelect,
  },

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    ...mapState('users', ['userRequiredFields']),
    ...mapState('tournamentSettings', [
      'form',
      'abilities',
      'getFksPdEnabled',
    ]),
    ...mapGetters('application', ['getFksPersonalDataList']),
    ...mapGetters('tournamentSettings', [
      'getFksPdEnabled',
      'getIsHub',
    ]),

    virtualWarning() {
      const isVisible =
        this.form.isVirtual.value &&
        !this.form.isVirtual.defaultValue;
      return isVisible ? this.$t('tournaments.virtual_warning') : '';
    },
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),

    showPDList(value) {
      this.updateFormData({
        name: this.form.fksPdEnabled.name,
        value,
      });
      this.$store.commit(
        'tournamentSettings/SET_FKS_PD_ENABLED',
        value,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.switcher {
  margin-top: 20px;
  display: flex;
}

.select,
.label {
  margin-top: 20px;
}

.toggle-btn {
  display: block;
  margin-top: 20px;
}

.inline-group {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 420px;

  .select {
    width: 100%;
  }

  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 18px;
  }
}

.indent {
  margin-top: 20px;
}

.pd {
  .select {
    margin-top: 4px;
  }

  &-label {
    color: rgba(white, 0.5);
    font-size: em(14px);
  }
}
.settings-control {
  max-width: 420px;
}

::v-deep .switcher {
  .icon {
    margin-left: 2px;
    margin-bottom: -3px;
  }
}
</style>
