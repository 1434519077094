<template>
  <div>
    <div class="inline">
      <BaseInput
        :label="$t('tournaments.group_count_title')"
        :value="form.count.value"
        :error-message.sync="form.count.error"
        type="number"
        class="settings-control"
        @input="
          updateFormData({
            name: form.count.name,
            value: Number($event || 0),
          })
        "
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{ $t('tournaments.tooltips_settings_subgroups-count') }}
        </template>
      </pvp-tooltip>
    </div>

    <div class="inline">
      <BaseInput
        class="settings-control"
        :label="$t('tournaments.group_count_team')"
        :value="form.countOnGroup.value"
        :error-message.sync="form.countOnGroup.error"
        type="number"
        @change="validateCountOnGroup"
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{
            $t('tournaments.tooltips_settings_subgroups-teams-count')
          }}
        </template>
      </pvp-tooltip>
    </div>

    <div class="rounds-title">
      {{ $t('tournaments.group_count_round') }}
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{
            $t('tournaments.tooltips_settings_subgroups-rounds-count')
          }}
        </template>
      </pvp-tooltip>
    </div>
    <div class="rounds">
      <pvp-btn
        v-for="round in roundCount"
        :key="round"
        :variant="
          form.countRound.value === round ? 'primary' : 'secondary'
        "
        class="rounds-btn"
        @click="
          updateFormData({ name: form.countRound.name, value: round })
        "
      >
        {{ round }}
      </pvp-btn>
    </div>

    <div class="divider" />

    <h4 class="points-title">{{ $t('scores.distribution') }}</h4>
    <div class="inline">
      <BaseSelect
        class="settings-control"
        :label="$t('scores.for_win')"
        :value="form.pointWin.value"
        :required="true"
        :options="points.win"
        @input="
          updateFormData({
            name: form.pointWin.name,
            value: Number($event),
          })
        "
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{
            $t('tournaments.tooltips_settings_subgroups-win-count')
          }}
        </template>
      </pvp-tooltip>
    </div>

    <div class="inline">
      <BaseSelect
        class="settings-control"
        :label="$t('scores.for_tie')"
        :value="form.pointTie.value"
        :required="true"
        :options="points.tie"
        @input="
          updateFormData({
            name: form.pointTie.name,
            value: Number($event),
          })
        "
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{
            $t('tournaments.tooltips_settings_subgroups-tie-count')
          }}
        </template>
      </pvp-tooltip>
    </div>

    <div class="inline">
      <BaseSelect
        :label="$t('scores.for_defeat')"
        class="settings-control"
        :value="form.pointDefeat.value"
        :required="true"
        :options="points.defeat"
        @input="
          updateFormData({
            name: form.pointDefeat.name,
            value: Number($event),
          })
        "
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{
            $t('tournaments.tooltips_settings_subgroups-defeat-count')
          }}
        </template>
      </pvp-tooltip>
    </div>
  </div>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'SubgroupsSettings',
  components: {
    BaseInput,
    BaseSelect,
    Icon,
  },
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    roundCount() {
      return _.range(1, 11);
    },
    points() {
      return {
        win: _.range(0, 21),
        defeat: _.range(-10, 21),
        tie: _.range(-5, 21),
      };
    },
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    validateCountOnGroup(e) {
      let num = Number(e?.target?.value);

      if (num < 3) {
        num = 3;
      }

      this.updateFormData({
        name: this.form?.countOnGroup?.name,
        value: num,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-top: 20px;
}

.rounds {
  display: flex;
  flex-wrap: wrap;
  max-width: 420px;
  margin-bottom: 24px;

  &-title {
    margin-top: 20px;
    font-size: em(14px);
    color: rgba(white, 0.5);

    .tt-wrapper {
      vertical-align: middle;
      margin-left: 4px;
    }
  }

  &-btn {
    width: 32px;
    height: 32px;
    margin: 12px 4px 0;
  }
}

.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .select {
    margin-top: 1em;
    width: 100%;
  }

  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 0.5em;
    font-size: 18px;
  }
}
.settings-control {
  max-width: 420px;
}
</style>
