<template>
  <div class="match-making-leaderboard">
    <div class="match-making-leaderboard__heading">
      <h3 class="match-making-leaderboard__title">
        {{ leaderboard.name }}
      </h3>
      <p
        v-if="leaderboard.isDefault"
        class="match-making-leaderboard__subtitle"
      >
        Общий Лидерборд за все время существования хаба.
      </p>
      <div class="match-making-leaderboard__info">
        <div
          v-if="leaderboard.periodFromTs"
          class="match-making-leaderboard__col"
        >
          <p class="match-making-leaderboard__date-label">
            Дата начала
          </p>
          <p class="match-making-leaderboard__date">
            {{ $dt(leaderboard.periodFromTs, 'dd_MM_YYYY_HH_mm') }}
          </p>
        </div>
        <div
          v-if="leaderboard.periodToTs"
          class="match-making-leaderboard__col"
        >
          <p class="match-making-leaderboard__date-label">
            Дата окончания
          </p>
          <p class="match-making-leaderboard__date">
            {{ $dt(leaderboard.periodToTs, 'dd_MM_YYYY_HH_mm') }}
          </p>
        </div>
      </div>
    </div>
    <div class="match-making-leaderboard__controls">
      <Button
        tag="button"
        size="regular"
        type="secondary"
        icon-only
        @click="goToLeaderboardSettings(leaderboard.id)"
      >
        <template #slotBefore>
          <icon name="settings" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'MatchMakingLeaderboard',
  components: { Button, Icon },
  props: {
    leaderboard: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goToEditSettings(leaderboardId) {
      this.$emit('gotoEditSettings', { leaderboardId });
    },
    goToLeaderboardSettings(leaderboardId) {
      this.$emit('goToLeaderboardSettings', { leaderboardId });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.match-making-leaderboard {
  padding: 16px;
  border: 1px solid var(--additional-color-gray);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  &:hover {
    cursor: pointer;
    border: 1px solid var(--additional-color-blue);
  }
}
.match-making-leaderboard__title {
  width: 100%;
  word-break: break-word;
  margin-bottom: 4px;
}
.match-making-leaderboard__controls {
  display: flex;
  align-items: center;
  gap: 12px;
}
.match-making-leaderboard__info {
  display: flex;
  align-items: center;
  gap: 24px;
}
.match-making-leaderboard__date-label {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.match-making-leaderboard__date {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
</style>
