<template>
  <div class="menu">
    <div class="menu-wrapper">
      <template v-for="(item, key) in menu">
        <BaseLink
          v-if="item.to"
          :key="key"
          class="menu-item"
          :class="{ 'has-icon': item.icon }"
          :icon-left="item.icon"
          :to="item.to"
        >
          {{ item.title }}
        </BaseLink>
        <a
          v-else-if="item.href"
          :key="key"
          :href="item.href"
          class="menu-item"
        >
          {{ item.title }}
        </a>
        <p v-else :key="key" class="menu-item">
          {{ item.title }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import cookies from '@utils/cookies';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'TournamentSettingsMenu',
  components: {
    BaseLink,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    gameCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('organizations', ['getOrganization']),
    ...mapState('tournamentSettings', ['abilities']),
    ...mapGetters('tournamentSettings', ['getIsHub']),
    ...mapGetters('tournaments', ['getTournamentPage']),

    orgId() {
      return this.$route?.params?.orgId;
    },

    tnId() {
      return this.$route?.params?.tnId;
    },

    tournamentAdditionalInfo() {
      return this.getTournamentPage(this.tnId);
    },

    getLink() {
      return (path) =>
        `/organization/${this.orgId}/tournament/${this.tnId}${path}`;
    },

    organization() {
      return this.getOrganization(this.orgId);
    },

    menu() {
      const menu = [
        {
          to: { name: 'tournament-edit' },
          title: this.$t('tournaments.about'),
        },
        {
          title: this.$t('tournaments.settings_title'),
        },
        {
          to: { name: 'tournament-edit-main' },
          title: this.$t('tournaments.settings_main'),
          icon: 'settings-range',
        },
      ];

      if (
        ['wf', 'cf', 'hs', 'lar', 'csg', 'cs2'].includes(
          this.gameCode,
        )
      ) {
        menu.push({
          to: { name: 'tournament-edit-game' },
          title: this.$t('tournaments.settings_games'),
          icon: 'settings',
        });
      }

      if (!this.getIsHub) {
        menu.push({
          to: { name: 'tournament-edit-grid' },
          title: this.$t('tournaments.settings_bracket'),
          icon: 'settings-square',
        });
      }

      if (this.getIsHub) {
        // Роут для настроек матчмейкинга (Показывать по условию, что тип турнира - Хаб, вместо настроек сетки)
        menu.push(
          {
            to: { name: 'tournament-edit-match-making' },
            title: this.$t('tournaments.settings_match_making'),
            icon: 'settings-square',
          },

          {
            to: { name: 'tournament-edit-leaderboard-list' },
            title: this.$t('tournaments.settings_leaderboard'),
            icon: 'settings-square',
          },
          // Временно скрыть, пока не сделают эту логику на беке
          // {
          //   to: { name: 'tournament-edit-ban-list' },
          //   title: this.$t('menu.ban_list'),
          // },
        );
      }

      if (this.organization?.isTrust) {
        menu.push({
          to: { name: 'tournament-edit-other' },
          title: this.$t('global.additionally'),
          icon: 'dots-horizontal',
        });
      }

      menu.push(
        {
          to: { name: 'tournament-edit-teams' },
          title: this.$t('menu.teams'),
        },
        {
          to: { name: 'tournament-edit-activities' },
          title: this.$t('tournament.activities'),
        },
        {
          to: {
            name: 'tournament-edit-match-control',
            query: this.$route.query,
          },
          title: this.$t('tournament.match_management'),
        },
      );

      if (cookies.getItem('show_participants')) {
        menu.push({
          to: { name: 'tournament-edit-participants' },
          title: this.$t('participants.few'),
        });
      }

      if (this.abilities?.canSeeding) {
        menu.push({
          to: { name: 'tournament-edit-seeding' },
          title: this.$t('tournament.seed'),
        });
      }

      if (this.abilities?.canEditPrizes) {
        menu.push({
          href: this.getLink('/prize/'),
          title: this.$t('global.prizes'),
        });
      }

      if (this.abilities?.canEditPicks) {
        menu.push({
          to: { name: 'tournament-edit-heartstone-pick' },
          title: this.$t('main.picks'),
        });
      }

      return menu;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  margin-bottom: 12px;
  @include min-desktop() {
    border-radius: 8px;
    background: var(--main-color-darkgray);
    border: 1px solid rgba(245, 245, 247, 0.12);
    padding: 28px 24px;
    flex-shrink: 0;
    width: 380px;
    position: sticky;
    top: 0;
    left: 0;
  }
  @include max-desktop() {
    background-color: rgba($dark-two, 0.6);
    overflow: hidden;
    p.menu-item {
      display: none;
    }
  }
  @include max-laptop() {
    margin-left: -12px;
    margin-right: -12px;
  }

  &-wrapper {
    @include max-desktop() {
      overflow: auto;
      white-space: nowrap;
      padding: 12px;
    }
  }

  &-item {
    font-size: 18px;
    color: white;

    &:hover {
      text-decoration: none;
      color: white;
    }

    @include min-desktop() {
      display: block;
      padding: 12px 8px 12px 26px;

      &.has-icon {
        font-size: 15px;
        color: rgba(white, 0.5);
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: rgba(white, 0);
        border-radius: 4px;

        &.router-link-exact-active {
          color: white;
        }

        ::v-deep .icon {
          color: white;
        }
      }
    }

    @include max-desktop() {
      display: inline;
      color: rgba(white, 0.3);
      & + & {
        margin-left: 20px;
      }
      ::v-deep .icon {
        display: none;
      }
    }

    &.router-link-exact-active {
      @include min-desktop() {
        background-color: $charcoal-grey;
        border-radius: 6px;
      }
      @include max-desktop() {
        color: white;
        padding-bottom: 5px;
        border-bottom: 5px solid $azure;
      }
    }
  }
}
</style>
