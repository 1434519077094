<template>
  <div>
    <tournament-edit-form>
      <h2>{{ $t('tournaments.about') }}</h2>
      <BaseInput
        :label="$t('tournaments.name')"
        :value="form.name.value"
        :error-message.sync="form.name.error"
        :disabled="!abilities.canEditName"
        @input="
          updateFormData({ name: form.name.name, value: $event })
        "
      />

      <div class="edit-avatar-logo">{{ $t('global.logo') }}</div>
      <edit-logo
        :id="params.tnId"
        :max-file-size="0.5"
        :allowed-formats="['png', 'jpg']"
        :helper-text="$t('uploader.maxSize', { from: 498, to: 498 })"
        type="tournamentlogo"
        @updated="onLogoUploaded"
      >
        <img
          v-if="tournament.logo"
          class="img-logo"
          :src="tournament.logo"
        />
        <div v-else class="default-logo">
          <game-icon :name="game.code" inline />
        </div>
      </edit-logo>

      <wysiwyg-editor
        :value="form.description.value"
        :disabled="!abilities.canEditDescription"
        @input="
          updateFormData({
            name: form.description.name,
            value: $event,
          })
        "
      >
        <div slot="label">
          {{ $t('global.description') }}
          <pvp-tooltip>
            <Icon class="info" name="info" :size="16" />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_about') }}
            </template>
          </pvp-tooltip>
        </div>
      </wysiwyg-editor>

      <wysiwyg-editor
        :key="pasteKey"
        :value="form.rules.value"
        :disabled="!abilities.canEditRules"
        @input="
          updateFormData({ name: form.rules.name, value: $event })
        "
      >
        <div slot="label">
          {{ $t('tournaments.rules') }}
          <pvp-tooltip>
            <Icon class="info" name="info" :size="16" />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_rules') }}
            </template>
          </pvp-tooltip>
        </div>
      </wysiwyg-editor>

      <pvp-btn
        v-if="rules.length"
        class="rules"
        @click="toggleRulesModal(true)"
      >
        {{ $t('rules.standart') }}
      </pvp-btn>

      <div class="divider" />

      <h5 class="contacts">
        {{ $t('global.contacts') }}
        <pvp-tooltip>
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_contacts') }}
          </template>
        </pvp-tooltip>
      </h5>

      <BaseInput
        label="Discord"
        :value="form.discordContact.value"
        :disabled="!abilities.canEditDiscordContact"
        :error-message.sync="form.discordContact.error"
        placeholder="example#1337"
        @input="
          updateFormData({
            name: form.discordContact.name,
            value: $event,
          })
        "
      />

      <BaseInput
        :label="$t('global.site')"
        :value="form.siteContact.value"
        :disabled="!abilities.canEditSiteContact"
        :error-message.sync="form.siteContact.error"
        placeholder="url"
        @input="
          updateFormData({
            name: form.siteContact.name,
            value: $event,
          })
        "
      />

      <BaseInput
        :label="$t('global.additionally')"
        :value="form.anyContact.value"
        :error-message.sync="form.anyContact.error"
        :placeholder="$t('info.additional_players')"
        :rows="4"
        :is-area="true"
        @input="
          updateFormData({
            name: form.anyContact.name,
            value: $event,
          })
        "
      />

      <pvp-modal v-model="isRulesModalVisible" :width="580">
        <template slot="modal-title">{{
          $t('rules.title')
        }}</template>
        <pvp-btn
          v-for="(rule, key) in rules"
          :key="key"
          variant="clear"
          class="rules-button"
          :class="{ active: ruleId === rule.id }"
          @click="ruleId = rule.id"
        >
          <Icon name="file-add" inline />
          {{ rule.name }}
        </pvp-btn>
        <div slot="modal-footer" class="rules-footer">
          <pvp-btn
            variant="secondary"
            @click="toggleRulesModal(false)"
            >{{ $t('global.cancel') }}</pvp-btn
          >
          <pvp-btn :disabled="!ruleId" @click="uploadRules">{{
            $t('uploader.upload')
          }}</pvp-btn>
        </div>
      </pvp-modal>
    </tournament-edit-form>
  </div>
</template>

<script>
import EditLogo from '@components/BaseComponents/EditLogo.vue';
import TournamentEditForm from '@components/TournamentComponents/Settings/TournamentSettingsForm.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TournamentEditAbout',
  components: {
    TournamentEditForm,
    EditLogo,
    BaseInput,
    Icon,
  },

  data: () => ({
    rules: [],
    ruleId: null,
    isRulesModalVisible: false,
    pasteKey: 0,
  }),

  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapGetters('application', ['getGame']),

    params() {
      const { tnId, orgId } = this.$route?.params || {};
      return {
        tnId: Number(tnId),
        orgId: Number(orgId),
      };
    },

    tournament() {
      return this.getTournament(this.params?.tnId);
    },

    game() {
      return this.getGame(this.form?.idGame?.value);
    },
  },

  created() {
    this.fetchRules();
  },

  methods: {
    ...mapActions('tournaments', ['fetchTournamentPage']),
    ...mapActions('tournamentSettings', ['updateFormData']),

    toggleRulesModal(isOpen) {
      this.isRulesModalVisible = isOpen;
      this.ruleId = null;
    },

    fetchRules() {
      api
        .get(
          `/organization/${this.params.orgId}/tournament/${this.params.tnId}/rules`,
        )
        .then(({ items }) => {
          this.rules = items || [];
        });
    },

    uploadRules() {
      const rule = this.rules?.find(({ id }) => id === this.ruleId);
      this.updateFormData({
        name: this.form?.rules?.name,
        value: rule.text,
      });
      this.toggleRulesModal(false);
      this.pasteKey += 1;
    },

    onLogoUploaded() {
      this.fetchTournamentPage(this.tournament?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .edit-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  overflow: hidden;
  border-radius: 6px;

  &-logo {
    margin-top: 25px;
    margin-bottom: 4px;
    font-size: em(13px);
    color: rgba(white, 0.4);
  }

  .game-icon,
  .img-logo {
    max-width: 128px;
    height: 128px;
  }
}

.editor {
  .info {
    font-size: 20px;
    margin-left: 4px;
    margin-bottom: 2px;
    vertical-align: middle;
  }
}

.rules {
  margin-top: 20px;

  &-button {
    color: white;
    width: 100%;
    justify-content: flex-start;
    font-size: 16px;

    &,
    &:focus {
      padding: 1em;
    }

    &.active {
      background-color: $azure;
      border-radius: 0.5em;
    }

    .icon {
      margin-right: 10px;
      font-size: em(28px);
    }
  }

  &-footer {
    .button {
      width: 160px;

      & + .button {
        margin-left: 12px;
      }
    }
  }
}

.contacts {
  margin-top: 36px;
}

.editor,
.label {
  margin-top: 20px;
}

.default-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  font-size: 72px;
  color: rgba(white, 0.5);
  background-color: rgba(black, 0.5);
}
</style>
