<template>
  <pvp-btn
    :disabled="!canFinishRound"
    :is-loading="isFinishProccess"
    @click="handleButtonClick"
  >
    {{ text }}
  </pvp-btn>
</template>

<script>
import { i18n } from '@src/localization/config';

export default {
  name: 'FinishRoundButton',

  data() {
    return {
      isFinishProccess: false,
      canFinishRound: false,
      text: i18n.t('global.finishRound'),
    };
  },
  computed: {
    tournamentId() {
      return this.$route.params.tnId;
    },
  },

  mounted() {
    this.fetchCanFinishRoundApi();
  },

  methods: {
    async fetchCanFinishRoundApi() {
      try {
        const response = await api.get(
          `/tournament/${this.tournamentId}/bracket/swiss/canFinishRound`,
        );
        this.canFinishRound = response.data.canFinishRound;
      } catch (error) {
        throw error.message;
      }
    },
    async finishRoundApi() {
      try {
        this.isFinishProccess = true;
        await api.post(
          `/tournament/${this.tournamentId}/bracket/swiss/finishRound`,
        );
        this.$emit('done');
        this.fetchCanFinishRoundApi();
      } catch (error) {
        throw error.message;
      }
      this.isFinishProccess = false;
    },

    handleButtonClick() {
      this.finishRoundApi();
    },
  },
};
</script>
