<template>
  <tournament-edit-form>
    <bracket-select
      class="settings-control"
      :value="form.idSystem.value"
      :label="$t('tournaments.bracketType')"
      :required="true"
      :id-game="form.idGame.value"
      :disabled="true"
    />

    <template v-if="tnSystem !== 'swiss'">
      <BaseSelect
        v-if="isTeamCountSelect"
        class="indent settings-control"
        :value="form.numLimitParticipants.value"
        :disabled="!abilities.canEditNumLimitParticipants"
        :required="true"
        :label="$t('participants.count')"
        :error-message.sync="form.numLimitParticipants.error"
        :options="participantsLimitList"
        @input="
          updateFormData({
            name: form.numLimitParticipants.name,
            value: Number($event),
          })
        "
      />

      <BaseInput
        v-else
        class="settings-control"
        :label="`${$t('participants.count')} *`"
        :value="form.numLimitParticipants.value"
        :error-message.sync="form.numLimitParticipants.error"
        :disabled="!abilities.canEditNumLimitParticipants"
        @input="
          updateFormData({
            name: form.numLimitParticipants.name,
            value: Number($event),
          })
        "
        @change="validateNumLimitParticipants"
      />
    </template>

    <component :is="componentName" />
  </tournament-edit-form>
</template>

<script>
import BracketSelect from '@components/BaseComponents/Select/BracketSelect.vue';
import SeSettings from '@components/TournamentComponents/Settings/Bracket/SeSettings.vue';
import TffaSettings from '@components/TournamentComponents/Settings/Bracket/TffaSettings.vue';
import SubgroupsSettings from '@components/TournamentComponents/Settings/Bracket/SubgroupsSettings.vue';
import RatingSettings from '@components/TournamentComponents/Settings/Bracket/RatingSettings.vue';
import BracketScheduleSettings from '@components/TournamentComponents/Settings/Bracket/BracketScheduleSettings.vue';
import TournamentEditForm from '@components/TournamentComponents/Settings/TournamentSettingsForm.vue';
import DeSettings from '@components/TournamentComponents/Settings/Bracket/DeSettings.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import SwissSettings from '@components/TournamentComponents/Settings/Bracket/SwissSettings.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  components: {
    DeSettings,
    TournamentEditForm,
    BracketScheduleSettings,
    RatingSettings,
    SubgroupsSettings,
    TffaSettings,
    SeSettings,
    BracketSelect,
    BaseInput,
    SwissSettings,
    BaseSelect,
  },
  data: () => ({
    isBracketMatchVisible: false,
  }),
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapState('tournaments', ['availableSystems']),
    ...mapGetters('tournaments', ['getTournamentBracket']),

    rounds() {
      return this.getTournamentBracket(this.$route.params?.tnId);
    },

    participantsLimitList() {
      return _.range(2, 12).map((num) => 2 ** num);
    },

    tnSystem() {
      const { code } =
        this.availableSystems[this.form.idSystem.value] || {};
      return code;
    },

    isTeamCountSelect() {
      return ['single', 'double_elim'].includes(this.tnSystem);
    },

    componentName() {
      switch (this.tnSystem) {
        case 'single':
          return 'se-settings';
        case 'double_elim':
          return 'de-settings';
        case 'team_ffa':
          return 'tffa-settings';
        case 'subgroups':
          return 'subgroups-settings';
        case 'rating':
          return 'rating-settings';
        case 'swiss':
          return 'swiss-settings';
        default:
          return '';
      }
    },
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    validateNumLimitParticipants() {
      let num = Number(this.form?.numLimitParticipants?.value);

      if (_.isNaN(num) || num < 4) {
        num = 4;
      }

      switch (this.tnSystem) {
        case 'team_ffa':
          if (num > 30000) {
            num = 30000;
          }
          break;
        default:
      }

      this.updateFormData({
        name: this.form?.numLimitParticipants?.name,
        value: num,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

::v-deep .padded {
  @include min-laptop() {
    padding: 20px 48px;
  }
  @include max-laptop() {
    padding: 20px 12px;
  }
}
.settings-control {
  max-width: 420px;
}
</style>
