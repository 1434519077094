<template>
  <div class="match-making-settings">
    <tournament-settings-form>
      <h2 class="match-making-settings__header">
        {{ $t('tournaments.settings_match_making') }}
      </h2>

      <!-- Свитчер "Включение матчмейкинга" -->
      <BaseSwitcher
        :value="form.hubIsMatchmakingEnabled.value"
        class="indent"
        @input="
          updateFormData({
            name: form.hubIsMatchmakingEnabled.name,
            value: $event,
          })
        "
      >
        <span slot="label" class="info">
          {{ $t('tournaments.matchmaking_is_enabled') }}
          <pvp-tooltip>
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_matchmaking_is_enabled') }}
            </template>
          </pvp-tooltip>
        </span>
      </BaseSwitcher>

      <!-- Свитчер "Включение инфы о создателе Лобби" -->
      <BaseSwitcher
        v-if="form.isIngameLobbyFlowEnabled"
        :value="form.isIngameLobbyFlowEnabled.value"
        class="indent"
        @input="
          updateFormData({
            name: form.isIngameLobbyFlowEnabled.name,
            value: $event,
          })
        "
      >
        <span slot="label" class="info">
          {{ $t('tournaments.lobby_info_is_enabled') }}
          <pvp-tooltip>
            <icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_lobby_info_is_enabled') }}
            </template>
          </pvp-tooltip>
        </span>
      </BaseSwitcher>

      <!-- Селект "Время чекина" -->
      <div class="inline mb-24">
        <BaseSelect
          :value="form.hubCheckinTimeoutSec.value"
          value-key="key"
          label-key="value"
          :label="$t('tournaments.checkin_timeout')"
          list-position="top"
          :options="form.hubCheckinTimeoutSec.selectFrom"
          :required="true"
          @input="
            updateFormData({
              name: form.hubCheckinTimeoutSec.name,
              value: $event,
            })
          "
        />
        <pvp-tooltip class="left">
          <icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_checkin_timeout') }}
          </template>
        </pvp-tooltip>
      </div>

      <!-- Свитчер "Соло матчмейкинг" -->
      <!-- <BaseSwitcher
        :value="form.hubIsSoloSupported.value"
        class="indent mb-24"
        @input="
          updateFormData({
            name: form.hubIsSoloSupported.name,
            value: $event,
          })
        "
      >
        <span slot="label" class="info">
          {{ $t('tournaments.matchmaking_is_solo') }}
          <pvp-tooltip>
            <icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_matchmaking_is_solo') }}
            </template>
          </pvp-tooltip>
        </span>
      </BaseSwitcher> -->

      <template v-if="form.hubIsSoloSupported.value">
        <!-- Селект "Способ выбора капитана" -->
        <div class="inline mb-24">
          <BaseSelect
            :value="form.hubCaptainElectionStrategy.value"
            value-key="key"
            label-key="value"
            :label="$t('tournaments.choose_captain')"
            list-position="top"
            :options="form.hubCaptainElectionStrategy.selectFrom"
            :required="true"
            readonly
            disabled
            @input="
              updateFormData({
                name: form.hubCaptainElectionStrategy.name,
                value: $event,
              })
            "
          />
          <pvp-tooltip class="left">
            <icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_choose_captain') }}
            </template>
          </pvp-tooltip>
        </div>

        <!-- Селект "Формат подбора игроков" -->
        <div class="inline mb-24">
          <BaseSelect
            :value="form.hubPlayerPickStrategy.value"
            value-key="key"
            label-key="value"
            :label="$t('tournaments.choise_players')"
            list-position="top"
            :options="form.hubPlayerPickStrategy.selectFrom"
            :required="true"
            @input="
              updateFormData({
                name: form.hubPlayerPickStrategy.name,
                value: $event,
              })
            "
          />
          <pvp-tooltip class="left">
            <icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_choise_players') }}
            </template>
          </pvp-tooltip>
        </div>

        <template v-if="isCaptainPick">
          <!-- Селект "Капитанский-пик, время на выбор игрока " -->
          <div class="inline mb-24">
            <BaseSelect
              :value="form.hubCaptainPickTimeoutSec.value"
              value-key="key"
              label-key="value"
              :label="$t('tournaments.time_for_choise_players')"
              list-position="top"
              :options="form.hubCaptainPickTimeoutSec.selectFrom"
              :required="true"
              @input="
                updateFormData({
                  name: form.hubCaptainPickTimeoutSec.name,
                  value: $event,
                })
              "
            />
            <pvp-tooltip class="left">
              <icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t('tournaments.tooltips_time_for_choise_players')
                }}
              </template>
            </pvp-tooltip>
          </div>

          <!-- Селект "Капитанский-пик, реакция на отсутствие пика" -->
          <div class="inline mb-24">
            <BaseSelect
              :value="form.hubCaptainPickTimeoutReaction.value"
              value-key="key"
              label-key="value"
              :label="$t('tournaments.time_for_no_pick')"
              list-position="top"
              :options="form.hubCaptainPickTimeoutReaction.selectFrom"
              :required="true"
              @input="
                updateFormData({
                  name: form.hubCaptainPickTimeoutReaction.name,
                  value: $event,
                })
              "
            />
            <pvp-tooltip class="left">
              <icon name="info" inline />
              <template slot="tooltip">
                {{ $t('tournaments.tooltips_time_for_no_pick') }}
              </template>
            </pvp-tooltip>
          </div>
        </template>
      </template>

      <!-- Автоматическая блокировка -->
      <h2 class="match-making-settings__header">
        {{ $t('tournaments.automating_blocking') }}
      </h2>

      <div class="match-making-settings__wrapper">
        <!-- Свитчер "Штраф за неподтверждение участия в игре" -->
        <BaseSwitcher
          :value="switcherPenaltyNotAcceptGame"
          class="indent"
          @input="updateSwitcherPenaltyNotAcceptGame"
        >
          <span slot="label" class="info">
            {{ $t('tournaments.penalty_not_accepted_game') }}
            <pvp-tooltip>
              <icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t('tournaments.tooltips_penalty_not_accepted_game')
                }}
              </template>
            </pvp-tooltip>
          </span>
        </BaseSwitcher>
        <!-- Селект "Штраф за неподтверждение участия в игре" -->
        <div class="inline">
          <BaseSelect
            :value="form.hubAutoblockCheckinTimeoutPenaltySec.value"
            :disabled="!switcherPenaltyNotAcceptGame"
            value-key="key"
            label-key="value"
            :label="$t('tournaments.timeout_penalty_no_game')"
            list-position="top"
            :options="
              form.hubAutoblockCheckinTimeoutPenaltySec.selectFrom
            "
            :required="true"
            @input="
              updateFormData({
                name: form.hubAutoblockCheckinTimeoutPenaltySec.name,
                value: $event,
              })
            "
          />
          <pvp-tooltip class="left">
            <icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_timeout_penalty_no_game') }}
            </template>
          </pvp-tooltip>
        </div>
      </div>

      <div
        v-if="isCaptainPick"
        class="match-making-settings__wrapper"
      >
        <!-- Свитчер "Штраф за отсутствие пика" -->
        <template
          v-if="
            form.hubCaptainPickTimeoutReaction.value !== 'autopick'
          "
        >
          <BaseSwitcher
            :value="switcherPenaltyNoPick"
            class="indent"
            @input="updateSwitcherPenaltyNoPick"
          >
            <span slot="label" class="info">
              {{ $t('tournaments.penalty_for_no_pick') }}
              <pvp-tooltip>
                <icon name="info" inline />
                <template slot="tooltip">
                  {{ $t('tournaments.tooltips_penalty_for_no_pick') }}
                </template>
              </pvp-tooltip>
            </span>
          </BaseSwitcher>
          <!-- Селект "Штраф за отсутствие пика" -->
          <div class="inline">
            <BaseSelect
              :value="
                form.hubCaptainPickAutoblockTimeoutPenaltySec.value
              "
              :disabled="!switcherPenaltyNoPick"
              value-key="key"
              label-key="value"
              :label="$t('tournaments.timeout_penalty_no_pick')"
              list-position="top"
              :options="
                form.hubCaptainPickAutoblockTimeoutPenaltySec
                  .selectFrom
              "
              :required="true"
              @input="
                updateFormData({
                  name: form.hubCaptainPickAutoblockTimeoutPenaltySec
                    .name,
                  value: $event,
                })
              "
            />
            <pvp-tooltip class="left">
              <icon name="info" inline />
              <template slot="tooltip">
                {{
                  $t('tournaments.tooltips_timeout_penalty_no_pick')
                }}
              </template>
            </pvp-tooltip>
          </div>
        </template>
      </div>
    </tournament-settings-form>
  </div>
</template>

<script>
import TournamentSettingsForm from '@components/TournamentComponents/Settings/TournamentSettingsForm.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';

import { isNull } from 'lodash';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'MatchMakingSettings',
  components: {
    TournamentSettingsForm,
    BaseSwitcher,
    BaseSelect,
    Icon,
  },
  data() {
    return {
      switcherPenaltyNotAcceptGame: false,
      switcherPenaltyNoPick: false,
    };
  },
  computed: {
    ...mapState('tournamentSettings', [
      'form',
      'hubSelectedByDefault',
      'abilities',
    ]),

    isCaptainPick() {
      return this.form.hubPlayerPickStrategy.value === 'pick';
    },

    hubAutoblockCheckinTimeoutPenaltyValue() {
      return (
        this.form.hubAutoblockCheckinTimeoutPenaltySec.value ??
        this.hubSelectedByDefault.hubAutoblockCheckinTimeoutPenaltySec
      );
    },

    hubCaptainPickAutoblockTimeoutPenaltyValue() {
      return (
        this.form.hubCaptainPickAutoblockTimeoutPenaltySec.value ??
        this.hubSelectedByDefault
          .hubCaptainPickAutoblockTimeoutPenaltySec
      );
    },
  },
  created() {
    this.switcherPenaltyNotAcceptGame = !isNull(
      this.form.hubAutoblockCheckinTimeoutPenaltySec.value,
    );

    this.switcherPenaltyNoPick = !isNull(
      this.form.hubCaptainPickAutoblockTimeoutPenaltySec.value,
    );
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),

    updateSwitcherPenaltyNotAcceptGame() {
      this.switcherPenaltyNotAcceptGame =
        !this.switcherPenaltyNotAcceptGame;
      if (this.switcherPenaltyNotAcceptGame) {
        this.updateFormData({
          name: this.form.hubAutoblockCheckinTimeoutPenaltySec.name,
          value: this.hubAutoblockCheckinTimeoutPenaltyValue,
        });
      } else {
        this.updateFormData({
          name: this.form.hubAutoblockCheckinTimeoutPenaltySec.name,
          value: null,
        });
      }
    },

    updateSwitcherPenaltyNoPick() {
      this.switcherPenaltyNoPick = !this.switcherPenaltyNoPick;
      if (this.switcherPenaltyNoPick) {
        this.updateFormData({
          name: this.form.hubCaptainPickAutoblockTimeoutPenaltySec
            .name,
          value: this.hubCaptainPickAutoblockTimeoutPenaltyValue,
        });
      } else {
        this.updateFormData({
          name: this.form.hubCaptainPickAutoblockTimeoutPenaltySec
            .name,
          value: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}
.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 420px;
  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 18px;
  }
}
.match-making-settings__header {
  margin-bottom: 24px;
}
.match-making-settings__wrapper {
  align-items: center;
  margin-bottom: 16px;
  gap: 24px;
}
.indent {
  display: block;
  margin-bottom: 12px;
}
</style>
