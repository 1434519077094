<template>
  <pvp-modal v-bind="$attrs" :width="580" v-on="$listeners">
    <template #modal-title>{{
      $t('tournaments.round_change')
    }}</template>
    <BaseForm @submit="editRound">
      <pvp-datepicker
        v-model="date"
        :has-time="true"
        :error-message="errors.time"
        :label="$t('tournaments.round_timeStart')"
        position="fixed"
      />

      <template v-if="isTffa">
        <BaseInput
          v-model="teamsCountMoveNextRound"
          :error-message="errors.teamsCountMoveNextRound"
          :label="$t('tournaments.round_teamsCountNext')"
        />

        <template v-if="round.current === false">
          <BaseInput
            v-model="gamesCountInMatch"
            :error-message="errors.gamesCountInMatch"
            :label="$t('games.match_count')"
          />

          <BaseInput
            v-model="playersCountInMatch"
            :error-message="errors.playersCountInMatch"
            :label="$t('players.match_count')"
          />
        </template>
      </template>

      <tournament-format-selector
        v-else-if="!isHs"
        v-model="systemFormat"
        :error-message="errors.systemFormat"
        list-position="top"
      />

      <BaseSelect
        v-if="isLastMapPickSide && !isCsGame"
        v-model="lastMapPickSideFormat"
        :label="$t('games.last_map_pick_side_label')"
        :options="lastMapPickSide"
        :required="true"
      >
        <template v-slot:option="{ option }">
          {{ $t(`games.last_map_pick_side_${option}`) }}
        </template>
        <template v-slot:selected-option="{ option }">
          {{ $t(`games.last_map_pick_side_${option}`) }}
        </template>
      </BaseSelect>

      <BaseSelect
        v-if="isPickban && mapsList.length"
        v-model="maps"
        :multiple="true"
        :label="$t('matches.wf_map_list')"
        :placeholder="$t('games.select_map')"
        :error-message="errors.pickBanMaps"
        value-key="code"
        label-key="name"
        :options="mapsList"
      />

      <BaseInput
        v-model="description"
        :error-message="errors.description"
        :is-area="true"
        :label="$t('tournaments.round_description')"
      />

      <pvp-btn type="submit">{{ $t('global.apply') }}</pvp-btn>

      <InfoBox v-if="errors.common" variant="error">
        {{ errors.common }}
      </InfoBox>
    </BaseForm>
  </pvp-modal>
</template>

<script>
import TournamentFormatSelector from '@components/TournamentComponents/TournamentFormatSelector.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'RoundEditPopup',
  components: {
    TournamentFormatSelector,
    BaseSelect,
    BaseForm,
    BaseInput,
    InfoBox,
  },
  props: {
    round: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    date: null,
    description: '',
    systemFormat: null,
    lastMapPickSideFormat: null,
    maps: [],
    mapsList: [],
    errors: {},
    playersCountInMatch: null,
    teamsCountMoveNextRound: null,
    gamesCountInMatch: null,
  }),

  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('application', ['getGameCode']),

    gameCode() {
      return this.getGameCode(this.tournament?.idGame);
    },

    tnId() {
      return this.$route.params.tnId;
    },

    tournament() {
      return this.getTournament(this.tnId);
    },

    isCsGame() {
      const csgoGameId = 24;
      const cs2Code = 'cs2';
      return this.gameId === csgoGameId || this.gameCode === cs2Code;
    },

    isTffa() {
      return this.tournament?.system?.code === 'team_ffa';
    },

    isPickban() {
      return this.tournament?.restrictions?.pickBan;
    },

    isHs() {
      return this.tournament.idGame === 23;
    },

    lastMapPickSide() {
      return [0, 1];
    },

    isLastMapPickSide() {
      return (
        this.systemFormat &&
        this.systemFormat !== 2 &&
        typeof this.lastMapPickSideFormat === 'number'
      );
    },
  },

  created() {
    if (this.isTffa) {
      this.playersCountInMatch = this.round?.countPlayersOnMap;
      this.teamsCountMoveNextRound =
        this.round?.matchesInfo?.movedTeamsFromMatch;
      this.gamesCountInMatch =
        this.round?.matchesInfo?.matchGamesCount;
    }

    this.systemFormat = this.round?.gameType;
    this.lastMapPickSideFormat = this.round?.lastMapPickSideFormat;
    this.description = this.round?.description || '';
    this.date = this.round?.tsStartTime;
    if (this.isPickban && !this.isHs) {
      this.fetchMapList(this.tournament.idGame).then(({ maps }) => {
        this.mapsList = maps;
      });
      this.maps = Object.keys(this.round?.maps || {});
    }
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournamentBracket']),
    ...mapActions('matches', ['fetchMapList']),
    editRound() {
      this.errors = {};
      const additionalData = {};

      if (this.isPickban) {
        additionalData.pickBanMaps = this.maps;
      }
      if (this.isLastMapPickSide) {
        additionalData.lastMapPickSideFormat =
          this.lastMapPickSideFormat;
      }

      if (this.isTffa) {
        additionalData.playersCountInMatch = Number(
          this.playersCountInMatch,
        );
        additionalData.teamsCountMoveNextRound = Number(
          this.teamsCountMoveNextRound,
        );
        additionalData.gamesCountInMatch = Number(
          this.gamesCountInMatch,
        );
      }

      api
        .patch(`/round/${this.round.id}/tuning/edit`, {
          ...additionalData,
          time: this.date,
          description: this.description,
          systemFormat: this.systemFormat,
        })
        .then((data) => {
          if (data.success) {
            this.fetchTournamentBracket(this.tnId);
            this.$emit('input', false);
          }
        })
        .catch(({ error }) => {
          if (_.isArray(error)) {
            this.errors = {
              common: error.join(', '),
            };
          } else {
            this.errors = error;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-form {
  margin-top: 20px;
}

.button {
  margin: 20px auto 0;
  display: block;
  width: 180px;
}

.pvp-info-box {
  margin-top: 12px;
}
</style>
