<template>
  <div class="match-making-leaderboard">
    <div class="match-making-leaderboard__wrapper">
      <h2 class="match-making-leaderboard__header">
        {{ $t('tournaments.match_making_leaderboard') }}
      </h2>
      <div class="match-making-leaderboard__controls">
        <Button
          tag="button"
          size="regular"
          type="secondary"
          text="Добавить лидерборд"
          @click="addNewLeaderboard"
        >
          <template #slotBefore>
            <icon name="plus" />
          </template>
        </Button>
      </div>
      <loader
        v-if="isLoading.page"
        class="match-making-leaderboard__loader"
      />
      <div v-else class="match-making-leaderboard__main">
        <ul class="match-making-leaderboard__list">
          <li
            v-for="(leaderboard, idx) in leaderboards"
            :key="idx"
            class="match-making-leaderboard__item"
          >
            <MatchMakingLeaderboard
              :leaderboard="leaderboard"
              @goToLeaderboardSettings="
                goToLeaderboardSettings(leaderboard.id)
              "
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import MatchMakingLeaderboard from './MatchMakingLeaderboard.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'MatchMakingLeaderboardList',
  components: { Button, MatchMakingLeaderboard, Icon },
  data() {
    return {
      leaderboards: [],
      supportedSystems: [],
      defaultSystemId: 1,
      defaultSettings: {},
      leaderboardDefaultSettings: {},
      isLoading: {
        page: false,
      },
    };
  },
  computed: {
    tnId() {
      return this.$route.params.tnId;
    },
    organizationId() {
      return this.$route.params.orgId;
    },
  },
  async mounted() {
    try {
      this.isLoading.page = true;
      await Promise.all([
        this.fetchAllLeaderboards(),
        this.fetchSystemIds(),
      ]);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      this.isLoading.page = false;
    }
  },
  methods: {
    goToLeaderboardSettings(leaderboardId) {
      this.$router.push({
        name: 'leaderboard-settings',
        params: {
          id: leaderboardId,
          tnId: this.tnId,
        },
      });
    },
    async fetchAllLeaderboards() {
      const url = `organization/${this.organizationId}/tournament/${this.tnId}/rating`;
      const { data } = await api.get(url);
      const formatedLeaderboards = data.map(
        ({ isDefault, isEditable, rating }) => ({
          ...rating,
          isDefault,
          isEditable,
        }),
      );
      this.leaderboards = formatedLeaderboards;
      return data;
    },
    addNewLeaderboard() {
      this.$router.push({
        name: 'leaderboard-settings',
        params: {
          id: 'new',
          tnId: this.tnId,
          defaultSettings: this.defaultSettings,
          supportedSystems: this.supportedSystems,
        },
      });
    },
    async fetchSystemIds() {
      const url = `organization/${this.organizationId}/tournament/${this.tnId}/rating/params`;
      const {
        data: { supportedSystems, defaultSystemId },
      } = await api.get(url);

      this.supportedSystems = supportedSystems;
      this.defaultSystemId = defaultSystemId;
      this.defaultSettings = supportedSystems[0].settings;
      return { supportedSystems, defaultSystemId };
    },
  },
};
</script>

<style lang="scss" scoped>
.match-making-leaderboard {
  background: var(--main-color-darkgray);
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
}
.match-making-leaderboard__wrapper {
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 42px;
  padding-bottom: 20px;
}
.match-making-leaderboard__header {
  width: 100%;
  word-break: break-word;
  margin-bottom: 24px;
}

.match-making-leaderboard__list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}
.match-making-leaderboard__controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
}
</style>
