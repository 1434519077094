import { createMachine, assign } from 'xstate';
import api from '@utils/api';
import store from '@state/store';

async function changeHubWorkflow (params) {
  const { tnId, currentState } = params;
  const url = `/tournament/${tnId}/workflow/run`;
  const payload = {
    state: currentState,
    tournamentId: tnId,
  };
  const res = await api.post(url, payload);

  if (res.status) {
    await Promise.all(
      store.dispatch('tournamentSettings/fetchTournamentWorkflow', tnId, { root: true }),
      store.dispatch('tournamentSettings/fetchTournamentAbilities', tnId, { root: true }),
      store.dispatch('tournamentSettings/fetchTournamentFields', tnId, { root: true }),
    );
  }
  return res;
};

export const hubLoading = (hubState) => {
  return createMachine({
    id: 'hubChangeState',
    context: {
      ...hubState,
      hubData: {},
    },
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          id: 'fetch-hub-state',
          src: changeHubWorkflow,
          onDone: {
            target: 'loaded',
            actions: assign({
              hubData: (_, event) => event,
            }),
          },
          onError: 'failure',
        },
      },
      loaded: {
        on: {
          REFRESH: 'loading',
        },
      },
      failure: {
        on: {
          RETRY: 'loading',
        },
      },
    },
  });
};
