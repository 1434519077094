<template>
  <div
    v-click-outside="closeSearchedPlayers"
    class="user-autocomplete"
  >
    <div v-if="selectedPlayers.length" class="selected-players">
      <pvp-btn
        v-for="(player, idx) in selectedPlayers"
        :key="idx"
        type="button"
        icon-right="cross"
        @click="clearPlayer(idx)"
      >
        {{ player.name }}
      </pvp-btn>
    </div>

    <div v-if="hasError" class="not-found">
      {{ $t('errors.notFoundRequest') }}
    </div>

    <InfoBox v-if="error" class="error-msg" variant="error">
      {{ error }}
    </InfoBox>

    <BaseInput
      v-model="searchPhrase"
      :error-message="errorMessage"
      :placeholder="$t('players.name_single')"
      @input="debouncedSearchUsers"
    >
      <Icon
        slot="before-input"
        class="icon-left"
        name="search"
        inline
      />
      <loader v-if="isLoading" slot="after-input" :size="16" />
      <div
        v-if="searchPhrase.length"
        slot="after-input"
        class="icon-right"
        @click="clearSearchPhrase()"
      >
        <Icon name="cross" />
      </div>
    </BaseInput>
    <div v-if="players.length" class="users">
      <div
        v-for="(player, key) in players"
        :key="key"
        class="users__item"
        @click="selectPlayer(player)"
      >
        <user-item :hash="player.hash" :image-size="32" />
      </div>
    </div>
  </div>
</template>

<script>
import UserItem from '@components/BaseComponents/UserItem.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'UserAutocomplete',
  components: { UserItem, Icon, BaseInput, InfoBox },
  props: {
    defaultSelectedPlayers: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchPhrase: '',
      players: [],
      isLoading: false,
      error: '',
      hasError: false,
      selectedPlayers: [],
    };
  },

  async created() {
    this.debouncedSearchUsers = _.debounce(
      async () => await this.searchUsers(),
      300,
    );
    this.selectedPlayers = [...this.defaultSelectedPlayers];
  },

  methods: {
    async searchUsers() {
      this.isLoading = true;
      this.hasError = false;
      this.error = '';
      const params = {
        searchPhrase: this.searchPhrase,
        recordsLimit: 5,
      };

      if (this.searchPhrase === '') {
        this.players = [];
        this.clearError();
      }

      try {
        const response = await api.get('/search/autocomplete', {
          params,
        });
        if (response.items.Player === null) {
          this.players = [];
          this.hasError = true;
        } else {
          const {
            items: {
              Player: { items: players },
            },
          } = response;

          this.$store.dispatch('users/storeUsers', players, {
            root: true,
          });
          this.players = players;
        }
      } catch (err) {
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    },

    selectPlayer(player) {
      const hasPlayer = this.selectedPlayers.some(
        (user) => user.hash === player.hash,
      );
      if (!hasPlayer) {
        if (this.selectedPlayers.length > 9) {
          this.error = this.$t('errors.max_user_length');
        } else {
          this.selectedPlayers.push(player);
          this.$emit('selectPlayers', this.selectedPlayers);
        }
      } else {
        this.error = this.$t('errors.user_already_added');
      }
    },

    clearPlayer(idx) {
      this.selectedPlayers.splice(idx, 1);
      this.$emit('selectPlayers', this.selectedPlayers);
    },

    closeSearchedPlayers() {
      this.players = [];
    },

    clearError() {
      this.error = '';
      this.hasError = false;
    },

    clearSearchPhrase() {
      this.searchPhrase = '';
      this.clearError();
    },
  },
};
</script>

<style lang="scss" scoped>
.label ::v-deep {
  .input {
    padding-left: 40px;
    padding-right: 60px;
  }

  .icon-left {
    color: rgba(white, 0.3);
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    left: 15px;
  }

  .icon-right {
    position: absolute;
    top: 15%;
    right: 10px;
    z-index: 10;
  }
}

.loader {
  position: absolute;
  top: 25%;
  right: 35px;
}

.users__item {
  width: inherit;
  background-color: rgba(black, 0.15);
  padding: 10px 15px;
  &:hover {
    background-color: #464854;
  }
}

.selected-players {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}

.not-found,
.error-msg {
  margin-bottom: 8px;
}
</style>
