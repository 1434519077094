<template>
  <div>
    <template v-if="form.pickBanSystem && form.pickBanSystem.value">
      <div v-if="form.pickBanTimeout" class="inline">
        <!-- Селект Время для выбора карт (в секундах)  -->
        <BaseSelect
          :value="form.pickBanTimeout.value"
          :label="$t('matches.wf_map_picking-time')"
          :options="pickbanTimeoutsList"
          :error-message="form.pickBanTimeout.error"
          :required="true"
          @input="
            updateFormData({
              name: form.pickBanTimeout.name,
              value: $event,
            })
          "
        >
          <template v-slot:option="{ option }">
            {{ $tc('date.seconds_pluralization', option) }}
          </template>
          <template v-slot:selected-option="{ option }">
            {{ $tc('date.seconds_pluralization', option) }}
          </template>
        </BaseSelect>
        <pvp-tooltip class="left">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-pick-time') }}
          </template>
        </pvp-tooltip>
      </div>

      <!-- Селект "BestOf" -->
      <div v-if="form.gameType" class="inline">
        <tournament-format-selector
          :value="form.gameType.value"
          :disabled="!form.pickBanSystem.value"
          :error-message="form.gameType.error"
          class="indent settings-control"
          @input="
            updateFormData({
              name: form.gameType.name,
              value: $event,
            })
          "
        />
        <pvp-tooltip class="left">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-best-of') }}
          </template>
        </pvp-tooltip>
      </div>

      <!-- Селект "Выбор сервера CSGO" -->
      <div
        v-if="form.serverPoolId && serverPools.length"
        class="inline"
      >
        <BaseSelect
          class="mt-26 settings-control"
          :value="form.serverPoolId.value"
          value-key="id"
          label-key="name"
          :label="$t('tournaments.choose_server')"
          list-position="top"
          :options="serverPoolsFull"
          :required="true"
          @input="
            updateFormData({
              name: form.serverPoolId.name,
              value: $event,
            })
          "
        />
        <pvp-tooltip class="left">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_check_server') }}
          </template>
        </pvp-tooltip>
      </div>

      <div v-if="maps.length" class="maps indent">
        <div class="maps-label">
          {{ $t('matches.wf_map_list') }}
          <pvp-tooltip class="left">
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_wf-maps-list') }}
            </template>
          </pvp-tooltip>
        </div>
        <BaseSelect
          v-if="maps.length"
          class="settings-control"
          :value="form.gameMaps.value"
          :placeholder="$t('games.select_map')"
          list-position="bottom"
          :multiple="true"
          :limit="7"
          :error-message="form.gameMaps.error"
          value-key="code"
          label-key="name"
          :options="maps"
          @input="
            updateFormData({
              name: form.gameMaps.name,
              value: $event,
            })
          "
        />
      </div>

      <!-- Селект "Команда с правом вето" -->
      <div v-if="matchTypes.length">
        <BaseSelect
          class="mt-26 settings-control"
          :value="form.matchTeamPriorityStrategy.value"
          value-key="id"
          label-key="name"
          :label="$t('tournaments.team_with_veto')"
          list-position="top"
          :options="matchTypes"
          :required="true"
          @input="
            updateFormData({
              name: form.matchTeamPriorityStrategy.name,
              value: $event,
            })
          "
        />
      </div>

      <!-- Селект "Ножевой раунд" -->
      <BaseSelect
        v-if="form.csgoServerConfigSideRoundType && sideType.length"
        class="mt-26 settings-control"
        :value="form.csgoServerConfigSideRoundType.value"
        value-key="id"
        label-key="name"
        :label="$t('tournaments.knife_round')"
        list-position="top"
        :options="sideType"
        :required="true"
        @input="
          updateFormData({
            name: form.csgoServerConfigSideRoundType.name,
            value: $event,
          })
        "
      />
    </template>

    <!-- Чекбокс "Friendly Fire" -->
    <BaseCheckbox
      v-if="form.csgoServerConfigIsFriendlyFireEnabled"
      v-model="form.csgoServerConfigIsFriendlyFireEnabled.value"
      class="mt-26 frendlyFire"
    >
      {{ $t('tournaments.friendly_fire') }}
    </BaseCheckbox>

    <!-- Селект "Количество пауз/таймаутов" -->
    <BaseSelect
      v-if="
        form.csgoServerConfigMaxPausesPerTeam &&
        maxPausesPerTeam.length
      "
      class="mt-26 settings-control"
      :value="form.csgoServerConfigMaxPausesPerTeam.value"
      value-key="id"
      label-key="name"
      :label="$t('tournaments.number_of_pauses')"
      list-position="top"
      :options="maxPausesPerTeam"
      :required="true"
      @input="
        updateFormData({
          name: form.csgoServerConfigMaxPausesPerTeam.name,
          value: $event,
        })
      "
    />

    <!-- Селект "Длительность паузы/таймаута" -->
    <BaseSelect
      v-if="
        form.csgoServerConfigPauseDurationSec &&
        pauseDurationSec.length
      "
      class="mt-26 settings-control"
      :value="form.csgoServerConfigPauseDurationSec.value"
      value-key="id"
      label-key="name"
      :label="$t('tournaments.pause_duration')"
      list-position="top"
      :options="pauseDurationSec"
      :required="true"
      @input="
        updateFormData({
          name: form.csgoServerConfigPauseDurationSec.name,
          value: $event,
        })
      "
    />

    <!-- Чекбокс "Техническая пауза" -->
    <BaseCheckbox
      v-if="form.csgoServerConfigIsTechPauseEnabled"
      v-model="form.csgoServerConfigIsTechPauseEnabled.value"
      class="mt-26"
      >{{ $t('tournaments.tech_pause') }}</BaseCheckbox
    >

    <!-- Селект "Длительность технической паузы" -->
    <BaseSelect
      v-if="
        form.csgoServerConfigTechPauseDurationSec &&
        techPauseDurationSec.length
      "
      class="mt-26 settings-control"
      :value="form.csgoServerConfigTechPauseDurationSec.value"
      value-key="id"
      label-key="name"
      :label="$t('tournaments.pause_tech_duration')"
      list-position="top"
      :options="techPauseDurationSec"
      :required="true"
      @input="
        updateFormData({
          name: form.csgoServerConfigTechPauseDurationSec.name,
          value: $event,
        })
      "
    />

    <!-- Чекбокс "GOTV" -->
    <BaseCheckbox
      v-if="form.csgoServerConfigIsGotvEnabled"
      v-model="form.csgoServerConfigIsGotvEnabled.value"
      class="mt-26"
      >{{ $t('tournaments.gotv') }}</BaseCheckbox
    >

    <!-- Селект "Задержка GOTV" -->
    <BaseSelect
      v-if="form.csgoServerConfigGotvDelaySec && gotvDelaySec.length"
      class="mt-26 settings-control"
      :value="form.csgoServerConfigGotvDelaySec.value"
      value-key="id"
      label-key="name"
      :label="$t('tournaments.gotv_delay')"
      list-position="top"
      :options="gotvDelaySec"
      :required="true"
      @input="
        updateFormData({
          name: form.csgoServerConfigGotvDelaySec.name,
          value: $event,
        })
      "
    />

    <!-- Автокомплит "Добавление спектаторов(наблюдателей)" -->
    <div v-if="form.csgoServerConfigSpectators" class="spectators">
      <div class="label mt-26">
        {{ $t('tournaments.spectators') }}
      </div>

      <div class="settings-form">
        <UserAutocomplete
          class="settings-control"
          :error-message="form.csgoServerConfigSpectators.error"
          :default-selected-players="
            form.csgoServerConfigSpectators.value
          "
          @selectPlayers="addSpectators"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TournamentFormatSelector from '@components/TournamentComponents/TournamentFormatSelector.vue';
import UserAutocomplete from '@src/components/BaseComponents/UserAutocomplete.vue';
import BaseCheckbox from '@components/BaseComponents/Form/BaseCheckbox.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'CsgoSettings',
  components: {
    TournamentFormatSelector,
    UserAutocomplete,
    BaseCheckbox,
    Icon,
    BaseSelect,
  },
  data: () => ({
    maps: [],
    matchTypes: [],
    gotvDelaySec: [],
    maxPausesPerTeam: [],
    pauseDurationSec: [],
    sideType: [],
    techPauseDurationSec: [],
    error: {
      teamMembers: '',
    },
  }),
  computed: {
    ...mapState('tournamentSettings', [
      'form',
      'abilities',
      'serverPools',
    ]),
    ...mapState('application', ['pickbanTimeoutsList']),
    ...mapGetters('application', [
      'getTournamentMatchTeamPriorityStrategies',
    ]),

    serverPoolsFull() {
      return [
        { id: 0, name: `${this.$t('tournaments.by_default')}` },
        ...this.serverPools,
      ];
    },
  },
  created() {
    this.fetchMapList(this.form.idGame.value).then(
      ({ maps, csgoServerConfig }) => {
        const {
          gotvDelaySec,
          maxPausesPerTeam,
          pauseDurationSec,
          sideType,
          techPauseDurationSec,
        } = csgoServerConfig;
        this.maps = maps;
        this.gotvDelaySec = gotvDelaySec;
        this.maxPausesPerTeam = maxPausesPerTeam;
        this.pauseDurationSec = pauseDurationSec;
        this.sideType = sideType;
        this.techPauseDurationSec = techPauseDurationSec;
      },
    );
  },
  mounted() {
    this.matchTypes = this.getTournamentMatchTeamPriorityStrategies;
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    ...mapActions('matches', ['fetchMapList']),

    addSpectators(players) {
      this.updateFormData({
        name: this.form.csgoServerConfigSpectators.name,
        value: players,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-26 {
  margin-top: 26px;
}
.settings-form {
  max-width: 420px;
}
.switcher {
  margin-top: 26px;
}
.mt-26 {
  margin-top: 26px;
}

.select,
.indent {
  margin-top: 20px;
}

.maps {
  .select {
    margin-top: 4px;
  }

  &-label {
    color: rgba(white, 0.5);
    font-size: em(14px);

    .tt-wrapper {
      vertical-align: middle;
    }
  }
}

.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 420px;

  .select {
    width: 100%;
  }

  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 0.5em;
    font-size: 18px;
  }
}

.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.frendlyFire > span,
.label {
  display: block;
  color: rgba(white, 0.5);
  font-size: em(14px);
  text-align: left;
}

.settings-control {
  max-width: 420px;
}
</style>
