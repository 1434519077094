<template>
  <div class="pvp-status-text">
    <Icon :name="icon" :class="status" />
    <div class="text">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'PvpStatusText',
  components: {
    Icon,
  },
  props: {
    status: {
      type: String,
      default: 'successs',
    },
  },
  computed: {
    icon() {
      return {
        error: 'cross-circle',
        success: 'check-circle',
        warning: 'info-triangle',
      }[this.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-status-text {
  display: flex;
}

.text {
  margin-left: 8px;
  font-size: 14px;
}

.icon {
  font-size: 20px;

  &.success {
    color: $dark-pastel-green;
  }

  &.warning {
    color: $gold;
  }

  &.error {
    color: $orangey-red;
  }
}
</style>
