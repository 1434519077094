<template>
  <div>
    <div class="inline indent">
      <BaseInput
        class="settings-control"
        :value="form.preferNumRounds.value"
        :label="$t('tournaments.settings_round-count')"
        :error-message.sync="form.preferNumRounds.error"
        type="number"
        @change="
          (e) =>
            validateInput(e.target.value, form.preferNumRounds.name)
        "
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{ $t('tournaments.tooltips_settings_tffa-round-counts') }}
        </template>
      </pvp-tooltip>
    </div>

    <div class="inline indent">
      <BaseInput
        class="settings-control"
        :value="form.countPlayersOnMap.value"
        :label="$t('players.match_count')"
        :error-message.sync="form.countPlayersOnMap.error"
        type="number"
        @change="
          (e) =>
            validateInput(e.target.value, form.countPlayersOnMap.name)
        "
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{
            $t('tournaments.tooltips_settings_tffa-players-counts')
          }}
        </template>
      </pvp-tooltip>
    </div>

    <div class="inline indent">
      <BaseInput
        class="settings-control"
        :value="form.ffaCountGamesInSeries.value"
        :label="$t('games.match_count')"
        :error-message.sync="form.ffaCountGamesInSeries.error"
        type="number"
        @change="
          (e) =>
            validateInput(
              e.target.value,
              form.ffaCountGamesInSeries.name,
            )
        "
      />
      <pvp-tooltip>
        <icon name="info" inline />
        <template slot="tooltip">
          {{ $t('tournaments.tooltips_settings_tffa-games-count') }}
        </template>
      </pvp-tooltip>
    </div>

    <final-round-settings />

    <div class="divider" />

    <h4 class="indent">{{ $t('scores.distribution') }}</h4>

    <BaseSwitcher
      :value="form.ffaDisableKills.value"
      :disabled="
        form.ffaMassStart.value || form.ffaMassStartPlacePoints.value
      "
      :error-message="form.ffaDisableKills.error"
      class="indent"
      @input="
        updateFormData({
          name: form.ffaDisableKills.name,
          value: $event,
        })
      "
    >
      <span slot="label" class="info">
        {{ $t('scores.for_kills') }}
        <pvp-tooltip>
          <icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_tffa-kills-score') }}
          </template>
        </pvp-tooltip>
      </span>
    </BaseSwitcher>

    <BaseInput
      class="settings-control"
      :disabled="!form.ffaDisableKills.value"
      :value="form.teamFfaKills.value"
      :error-message.sync="form.teamFfaKills.error"
      :label="$t('scores.count_kills')"
      type="number"
      @input="validateKillsCount"
    />

    <BaseSwitcher
      v-model="hasPoints"
      class="indent"
      :disabled="form.ffaMassStart.value"
      @input="changePlace('skip')"
    >
      <span slot="label" class="info">
        {{ $t('scores.for_places') }}
        <pvp-tooltip>
          <icon name="info" inline />
          <template slot="tooltip">
            {{
              $t('tournaments.tooltips_settings_tffa-places-score')
            }}
          </template>
        </pvp-tooltip>
      </span>
    </BaseSwitcher>

    <div v-if="hasPoints" class="score">
      <div v-if="places.list.length" class="score-row">
        <span class="score-input">{{ $t('places.single') }}</span>
        <span class="score-value">{{ $t('scores.few') }}</span>
      </div>
      <div
        v-for="(place, key) in places.list"
        :key="key"
        class="score-row"
      >
        <div class="score-input">
          <BaseInput
            class="settings-control"
            :disabled="true"
            :placeholder="$t('global.from')"
            :value="place.from"
          />

          <BaseInput
            v-if="place.from !== place.to"
            class="settings-control"
            :placeholder="$t('global.to')"
            :disabled="!hasPoints || key !== places.count"
            :value="place.to"
            type="number"
            @change="
              ({ target }) =>
                changePlace('change-range', key, target.value)
            "
          />
        </div>

        <BaseInput
          :value="place.value"
          class="score-value settings-control"
          type="number"
          @input="changePlace('change-value', key, Number($event))"
        />

        <pvp-btn
          v-if="key === places.count"
          variant="clear"
          icon-right="cross"
          @click="changePlace('remove', key)"
        />
      </div>

      <div class="score-btns">
        <pvp-btn icon-left="plus" @click="changePlace('add')">
          {{ $t('places.add_title') }}
        </pvp-btn>
        <pvp-btn icon-left="plus" @click="changePlace('add-range')">
          {{ $t('places.add_range') }}
        </pvp-btn>
        <pvp-btn
          v-if="places.list.length"
          variant="secondary"
          @click="isWarningModalVisible = true"
        >
          {{ $t('global.skip') }}
        </pvp-btn>
      </div>
    </div>

    <pvp-modal
      v-model="isWarningModalVisible"
      :width="580"
      class="warning-modal"
    >
      <template #modal-title>{{ $t('places.skip_title') }}</template>
      <p class="desc">{{ $t('places.skip_description') }}</p>
      <template #modal-footer>
        <pvp-btn
          variant="secondary"
          @click="isWarningModalVisible = false"
        >
          {{ $t('global.back') }}
        </pvp-btn>
        <pvp-btn @click="changePlace('skip')">
          {{ $t('global.confirm') }}
        </pvp-btn>
      </template>
    </pvp-modal>
  </div>
</template>

<script>
import FinalRoundSettings from '@components/TournamentComponents/Settings/FinalRoundSettings.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';

export default {
  name: 'TffaSettings',
  components: { FinalRoundSettings, BaseSwitcher, Icon, BaseInput },
  data: () => ({
    hasPoints: false,
    isWarningModalVisible: false,
    timeFfa: false,
  }),

  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    places() {
      const list = this.form?.teamFfaPlaces?.value || [];
      return {
        list,
        count: list?.length - 1,
      };
    },
  },

  created() {
    this.hasPoints = this.places?.list?.length > 0;
  },

  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    changePlace(variant, key, value) {
      let places = _.cloneDeep(this.form?.teamFfaPlaces?.value) || [];
      const lastPlace = places[this.places.count] || {};
      switch (variant) {
        case 'add':
          places.push({
            from: Number(lastPlace.to || 0) + 1,
            to: Number(lastPlace.to || 0) + 1,
            value: 0,
          });
          break;

        case 'add-range':
          places.push({
            from: Number(lastPlace.to || 0) + 1,
            to: Number(lastPlace.to || 0) + 2,
            value: 0,
          });
          break;

        case 'change-range': {
          const from = Number(places[key].from);
          places[key].to = from >= value ? from + 1 : value;
          break;
        }

        case 'remove':
          places = places.filter((item, index) => key !== index);
          break;

        case 'skip':
          places = [];
          this.isWarningModalVisible = false;
          break;

        default:
          places[key] = {
            ...places[key],
            value,
          };
      }

      this.updateFormData({
        name: this.form.teamFfaPlaces.name,
        value: places,
      });
    },

    validateInput(value, name) {
      if (+value < 0) {
        value = 0;
      } else if (+value > 1000) {
        value = 1000;
      } else {
        value = Number(value);
      }
      this.updateFormData({ name, value });
    },

    validateKillsCount(value) {
      let validatedValue = Number(value);
      const maxValue = 100;
      if (validatedValue > maxValue) {
        validatedValue = maxValue;
      }
      this.updateFormData({
        name: this.form.teamFfaKills.name,
        value: validatedValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indent {
  margin-top: 20px;
}

.label {
  display: block;
}

.score {
  max-width: 420px;

  &-row {
    display: flex;
    margin-top: 8px;
    position: relative;

    span {
      font-size: 14px;
      color: rgba(white, 0.5);
    }

    .button {
      top: 0;
      height: 100%;
      position: absolute;
      left: calc(100% + 4px);
      color: rgba(white, 0.5);
    }
  }

  &-input {
    display: flex;
    flex-grow: 1;

    .label {
      margin-top: 0;
      width: 100%;

      & + .label {
        margin-left: 8px;
      }
    }
  }

  &-value {
    width: 100px;
    flex-shrink: 0;
    margin-left: 12px;
  }

  &-btns {
    margin-top: 12px;

    .button {
      display: block;
      margin-top: 8px;
    }
  }
}

.inline {
  display: flex;
  width: 100%;

  .select {
    margin-top: 1em;
    width: 100%;
  }

  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-top: 2em;
    font-size: 18px;
  }
}

.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.warning-modal {
  .desc {
    text-align: center;
    color: rgba(white, 0.4);
  }

  .button {
    @include min-tablet() {
      width: 160px;
    }
    @include max-tablet() {
      width: 120px;
    }

    & + .button {
      margin-left: 1em;
    }
  }
}

.flex {
  display: flex;
}
.settings-control {
  max-width: 420px;
}
</style>
