var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tournament-edit-form',[_c('bracket-select',{staticClass:"settings-control",attrs:{"value":_vm.form.idSystem.value,"label":_vm.$t('tournaments.bracketType'),"required":true,"id-game":_vm.form.idGame.value,"disabled":true}}),(_vm.tnSystem !== 'swiss')?[(_vm.isTeamCountSelect)?_c('BaseSelect',{staticClass:"indent settings-control",attrs:{"value":_vm.form.numLimitParticipants.value,"disabled":!_vm.abilities.canEditNumLimitParticipants,"required":true,"label":_vm.$t('participants.count'),"error-message":_vm.form.numLimitParticipants.error,"options":_vm.participantsLimitList},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.numLimitParticipants, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.numLimitParticipants, "error", $event)},"input":function($event){_vm.updateFormData({
          name: _vm.form.numLimitParticipants.name,
          value: Number($event),
        })}}}):_c('BaseInput',{staticClass:"settings-control",attrs:{"label":`${_vm.$t('participants.count')} *`,"value":_vm.form.numLimitParticipants.value,"error-message":_vm.form.numLimitParticipants.error,"disabled":!_vm.abilities.canEditNumLimitParticipants},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.numLimitParticipants, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.numLimitParticipants, "error", $event)},"input":function($event){_vm.updateFormData({
          name: _vm.form.numLimitParticipants.name,
          value: Number($event),
        })},"change":_vm.validateNumLimitParticipants}})]:_vm._e(),_c(_vm.componentName,{tag:"component"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }