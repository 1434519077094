var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.form.pickBanSystem && _vm.form.pickBanSystem.value)?[(_vm.form.pickBanTimeout)?_c('div',{staticClass:"inline"},[_c('BaseSelect',{attrs:{"value":_vm.form.pickBanTimeout.value,"label":_vm.$t('matches.wf_map_picking-time'),"options":_vm.pickbanTimeoutsList,"error-message":_vm.form.pickBanTimeout.error,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.pickBanTimeout.name,
            value: $event,
          })}},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$tc('date.seconds_pluralization', option))+" ")]}},{key:"selected-option",fn:function({ option }){return [_vm._v(" "+_vm._s(_vm.$tc('date.seconds_pluralization', option))+" ")]}}],null,false,2712626445)}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-pick-time'))+" ")])],2)],1):_vm._e(),(_vm.form.gameType)?_c('div',{staticClass:"inline"},[_c('tournament-format-selector',{staticClass:"indent settings-control",attrs:{"value":_vm.form.gameType.value,"disabled":!_vm.form.pickBanSystem.value,"error-message":_vm.form.gameType.error},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.gameType.name,
            value: $event,
          })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-best-of'))+" ")])],2)],1):_vm._e(),(_vm.form.serverPoolId && _vm.serverPools.length)?_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"mt-26 settings-control",attrs:{"value":_vm.form.serverPoolId.value,"value-key":"id","label-key":"name","label":_vm.$t('tournaments.choose_server'),"list-position":"top","options":_vm.serverPoolsFull,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.serverPoolId.name,
            value: $event,
          })}}}),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_check_server'))+" ")])],2)],1):_vm._e(),(_vm.maps.length)?_c('div',{staticClass:"maps indent"},[_c('div',{staticClass:"maps-label"},[_vm._v(" "+_vm._s(_vm.$t('matches.wf_map_list'))+" "),_c('pvp-tooltip',{staticClass:"left"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_wf-maps-list'))+" ")])],2)],1),(_vm.maps.length)?_c('BaseSelect',{staticClass:"settings-control",attrs:{"value":_vm.form.gameMaps.value,"placeholder":_vm.$t('games.select_map'),"list-position":"bottom","multiple":true,"limit":7,"error-message":_vm.form.gameMaps.error,"value-key":"code","label-key":"name","options":_vm.maps},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.gameMaps.name,
            value: $event,
          })}}}):_vm._e()],1):_vm._e(),(_vm.matchTypes.length)?_c('div',[_c('BaseSelect',{staticClass:"mt-26 settings-control",attrs:{"value":_vm.form.matchTeamPriorityStrategy.value,"value-key":"id","label-key":"name","label":_vm.$t('tournaments.team_with_veto'),"list-position":"top","options":_vm.matchTypes,"required":true},on:{"input":function($event){return _vm.updateFormData({
            name: _vm.form.matchTeamPriorityStrategy.name,
            value: $event,
          })}}})],1):_vm._e()]:_vm._e(),(_vm.form.cs2ServerConfigIsFriendlyFireEnabled)?_c('BaseCheckbox',{staticClass:"mt-26 frendlyFire",model:{value:(_vm.form.cs2ServerConfigIsFriendlyFireEnabled.value),callback:function ($$v) {_vm.$set(_vm.form.cs2ServerConfigIsFriendlyFireEnabled, "value", $$v)},expression:"form.cs2ServerConfigIsFriendlyFireEnabled.value"}},[_vm._v(_vm._s(_vm.$t('tournaments.friendly_fire')))]):_vm._e(),(_vm.form.cs2ServerConfigSpectators)?_c('div',{staticClass:"spectators"},[_c('div',{staticClass:"label mt-26"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.spectators'))+" ")]),_c('div',{staticClass:"settings-form"},[_c('UserAutocomplete',{staticClass:"settings-control",attrs:{"error-message":_vm.form.cs2ServerConfigSpectators.error,"default-selected-players":_vm.form.cs2ServerConfigSpectators.value},on:{"selectPlayers":_vm.addSpectators}})],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }