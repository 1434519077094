<template>
  <InfoBox v-if="isErrorTeams" variant="error">
    {{ errorMessage }}
  </InfoBox>
  <div v-else class="seeding">
    <loader v-if="isLoading" :size="32" />
    <template v-else>
      <InfoBox
        v-if="isSavedData"
        class="seeding-info"
        :class="{ 'fade-out': isSavedData }"
        variant="success"
        >{{ $t('tournament.data_save_ok') }}</InfoBox
      >
      <InfoBox
        v-if="isError"
        :class="{ 'fade-out': isError }"
        variant="error"
      >
        {{ errorFromBackend }}
      </InfoBox>

      <div class="seeding-settings">
        <Button
          v-for="(button, actionType, idx) in buttons"
          :key="idx"
          tag="button"
          size="regular"
          type="secondary"
          :text="button.title"
          @click="clickHandler(actionType)"
        />
      </div>

      <div
        v-if="Object.keys(stat).length"
        class="seeding-table-group"
      >
        <Table>
          <template #thead>
            <TableRow>
              <TableData
                v-for="(header, idx) in tableHeadersGroup"
                :key="idx"
                heading
              >
                {{ header }}
              </TableData>
            </TableRow>
          </template>
          <template #tbody>
            <TableRow v-for="(value, key, idx) in stat" :key="idx">
              <TableData>{{ key }}</TableData>
              <TableData>{{ value.count }}</TableData>
              <TableData>{{ value.total }}</TableData>
            </TableRow>
          </template>
        </Table>
      </div>

      <div class="seeding-table">
        <Table>
          <template #thead>
            <TableRow>
              <TableData
                v-for="(header, idx) in tableHeaders"
                :key="idx"
                heading
              >
                {{ header }}
              </TableData>
            </TableRow>
          </template>
          <template #tbody>
            <TableRow v-for="team in seedingTeams" :key="team.id">
              <TableData
                text-align="left"
                class="seeding-table__data"
              >
                {{ team.order }}
              </TableData>
              <TableData
                :padding="false"
                text-align="left"
                class="seeding-table__data"
              >
                <BaseTeamLink :hash="team.hash" />
              </TableData>
              <TableData
                v-if="!isRatingTournament"
                text-align="center"
                class="seeding-table__data"
              >
                <div class="seeding-table__input">
                  <Input
                    :key="team.bracketNumber + refreshkey"
                    :max-length="4"
                    :disabled="team.isDisabledInput"
                    :error-text="team.errorInputMessage"
                    :value="team.bracketNumber"
                    @change="setBracketNumber($event, team.id)"
                  />
                </div>
              </TableData>
              <TableData
                text-align="center"
                class="seeding-table__data"
              >
                <Checkbox
                  :key="team.bracketNumber + refreshkey"
                  v-model="team.isParticipant"
                  :name="team.name"
                  @change="checkboxHandler($event, team.id)"
                />
              </TableData>
              <TableData class="seeding-table__data">
                <div v-if="isPick(team)">{{ 'Пик: да' }}</div>
              </TableData>

              <TableData
                v-if="isVisibleActionColumn"
                class="seeding-table__data"
              >
                <div
                  v-if="isVisibleActionButtons(team)"
                  class="info-btns"
                >
                  <Button
                    v-if="team.isLockedVisible"
                    tag="button"
                    size="small"
                    type="secondary"
                    :icon-only="true"
                    @click="pinNumber(team.id)"
                  >
                    <template #slotBefore>
                      <icon name="unlock" />
                    </template>
                  </Button>
                  <Button
                    v-else
                    tag="button"
                    size="small"
                    type="secondary"
                    icon-only
                    @click="unpinNumber(team.id)"
                  >
                    <template #slotBefore>
                      <icon name="lock2" />
                    </template>
                  </Button>
                </div>
              </TableData>
            </TableRow>
          </template>
        </Table>
      </div>
    </template>
    <Modal
      ref="confirmModal"
      class="error-modal"
      type="regular"
      :is-open="isOpenModal"
      :title="$t('global.error')"
    >
      <p style="margin-bottom: 12px">{{ errorMessage }}</p>
      <div class="error-modal__settings-btns">
        <Button
          tag="button"
          size="small"
          type="tertiary"
          :text="$t('actions.cancel')"
          @click="close"
        >
        </Button>
        <Button
          tag="button"
          size="small"
          type="secondary"
          :text="$t('actions.confirm')"
          @click="confirm"
        >
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import Input from '@components/v2/ui/Input.vue';
import Checkbox from '@components/v2/ui/Checkbox.vue';
import Table from '@src/components/v2/ui/Table.vue';
import TableRow from '@src/components/v2/ui/TableRow.vue';
import TableData from '@src/components/v2/ui/TableData.vue';
import Modal from '@src/components/v2/Modal.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import Icon from '@components/v2/utils/Icon.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'TournamentEditSeeding',
  components: {
    Button,
    Table,
    TableRow,
    TableData,
    Input,
    Checkbox,
    Icon,
    Modal,
    BaseTeamLink,
    InfoBox,
  },
  data() {
    return {
      refreshkey: 0,
      buttons: [],
      stat: {},
      tableHeadersGroup: [
        this.$t('subgroups.group_index'),
        this.$t('subgroups.teams_count'),
        this.$t('subgroups.teams_on_group'),
      ],
      tournamentId: '',
      organizationId: '',
      seedingTeams: [],
      isLockedVisible: true,
      isCurrentOrder: false,
      isOpenModal: false,
      isSavedData: false,
      isLoading: false,
      isError: false,
      isErrorTeams: false,
      errorMessage: '',
      errorFromBackend: '',
    };
  },

  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('tournamentSettings', ['getWorkflow']),

    workflow() {
      return this.getWorkflow(this.tournamentId);
    },

    tournament() {
      return this.getTournament(this.tournamentId);
    },
    bracketNumbers() {
      return this.seedingTeams.map(({ bracketNumber }) =>
        Number(bracketNumber),
      );
    },
    maxBracketNumber() {
      return this.seedingTeams.length;
    },
    isVisibleActionColumn() {
      return !this.isGroupStage && !this.isRatingTournament;
    },
    isGroupStage() {
      const groupStage = 8;
      return this.tournament?.idSystem === groupStage;
    },
    isRatingTournament() {
      const ratingStage = 7;
      return this.tournament?.idSystem === ratingStage;
    },
    totalGroupsCount() {
      return Object.keys(this.stat).length - 1;
    },
    maxTeamsInGroup() {
      return this.stat['1'].total;
    },
    countRegisteredTeams() {
      return this.seedingTeams.length;
    },
    groupsCount() {
      return Object.keys(this.stat).length - 1;
    },
    groupsDict() {
      return this.seedingTeams
        .filter((team) => team.isParticipant)
        .reduce((acc, team) => {
          acc[team.bracketNumber]
            ? (acc[team.bracketNumber] += 1)
            : (acc[team.bracketNumber] = 1);
          return acc;
        }, {});
    },
    typeVariant() {
      return this.isError ? 'error' : 'success';
    },
    tableHeaders() {
      if (this.isGroupStage) {
        return [
          this.$t('tournaments.reg_order'),
          this.$t('tournament.team_name'),
          this.$t('subgroups.group_index'),
          this.$t('tournament.accessed_to_tournament'),
          this.$t('team.info_title'),
        ];
      } else if (this.isRatingTournament) {
        return [
          this.$t('tournaments.reg_order'),
          this.$t('tournament.team_name'),
          this.$t('tournament.accessed_to_tournament'),
          this.$t('team.info_title'),
        ];
      } else {
        return [
          this.$t('tournaments.reg_order'),
          this.$t('tournament.team_name'),
          this.$t('tournament.position_on_grid'),
          this.$t('tournament.accessed_to_tournament'),
          this.$t('team.info_title'),
          this.$t('team.actions'),
        ];
      }
    },
  },

  watch: {
    groupsDict: {
      handler(newVal) {
        if (this.isGroupStage) {
          Object.keys(newVal)
            .filter((key) => key > 0)
            .forEach((key) => {
              this.stat[key].count = newVal[key];
            });
          const keysStat = Object.keys(this.stat);
          const diff = this.difference(keysStat, Object.keys(newVal));

          diff.forEach((group) => {
            if (group === 'free') {
              this.stat.free.count = this.groupsDict[0] ?? 0;
            } else {
              this.stat[group].count = 0;
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
    'workflow.currentState': {
      handler(newVal) {
        if (newVal === 'executing') {
          this.$router.push({ name: 'tournament-edit' });
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.tournamentId = this.$route.params.tnId;
    this.organizationId = this.$route.params.orgId;
    this.fetchTeams();
  },

  methods: {
    difference(arr1, arr2) {
      return arr1.filter((item) => !arr2.includes(item));
    },
    async fetchTeams() {
      try {
        this.isErrorTeams = false;
        this.isLoading = true;
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/seedingteams`;
        const {
          data: { buttons, stat, teams },
        } = await api.get(url);
        this.buttons = buttons;
        this.stat = stat;
        if (this.isGroupStage) {
          this.stat.free.total = '-';
        }
        this.teams = teams;
        const teamsResult = teams.map((obj, idx) => ({
          ...obj,
          order: idx + 1,
          teamId: obj.id,
          isLockedVisible: true,
          isParticipant: obj.status === 'selected',
          isDisabledInput: obj.status === 'registered',
          errorInputMessage: '',
        }));
        this.seedingTeams =
          this.filterTeamsAsPerticipant(teamsResult);
        this.$store.dispatch('teams/storeTeams', teams, {
          root: true,
        });
      } catch (err) {
        if (err.error[0]) {
          this.errorMessage = err.error[0];
        } else {
          const error = JSON.parse(err.message);
          this.errorMessage = error.errorMessage;
        }
        this.isErrorTeams = true;
      } finally {
        this.isLoading = false;
      }
    },

    checkboxHandler(value, teamId) {
      const currentTeam = this.seedingTeams.find(
        (team) => team.id === teamId,
      );
      currentTeam.isDisabledInput = !value;
      if (!value) {
        currentTeam.bracketNumber = 0;
        currentTeam.errorInputMessage = '';
        if (currentTeam.fixedNumber) {
          currentTeam.isLockedVisible = true;
          delete currentTeam.fixedNumber;
        }
        this.refreshkey += 1;
      }
    },

    clickHandler(actionType) {
      switch (actionType) {
        case 'allowAll':
          this.allowAll();
          break;
        case 'allowAllAndSave':
          this.allowAllAndSave();
          break;
        case 'lockInCurrentOrder':
          this.lockInCurrentOrder();
          break;
        case 'randomly':
          this.randomly(this.seedingTeams);
          break;
        case 'resetAll':
          this.resetAll();
          break;
        case 'save':
          this.save();
          break;
        default:
          break;
      }
    },

    isVisibleActionButtons(team) {
      return (
        !this.isGroupStage &&
        !this.isRatingTournament &&
        team.isParticipant
      );
    },

    isPick(team) {
      if (team.pick !== null) {
        const unicodeString = team.pick;
        const pickObject = JSON.parse(
          decodeURIComponent(unicodeString),
        );
        return Object.prototype.hasOwnProperty.call(
          pickObject,
          'heroes',
        );
      }
      return false;
    },

    confirm() {
      this.$refs.confirmModal.$options.modalController.resolve(true);
      this.isOpenModal = false;
    },

    close() {
      this.$refs.confirmModal.$options.modalController.resolve(false);
      this.isOpenModal = false;
    },

    swapElements(array, index1, index2) {
      array[index1] = array.splice(index2, 1, array[index1])[0];
    },

    distributeAmongGroups(groupsQty, limitPerTeam, teamsArr) {
      const result = {
        groups: [],
        ungroupedTeams: [],
      };
      let pointer = 0;

      for (let i = 0; i < teamsArr.length; i += 1) {
        !Array.isArray(result.groups[pointer]) &&
          (result.groups[pointer] = []);
        if (result.groups[pointer].length !== limitPerTeam) {
          result.groups[pointer].push(teamsArr[i]);
        } else {
          result.ungroupedTeams.push(teamsArr[i]);
        }
        pointer = pointer === groupsQty - 1 ? 0 : pointer + 1;
      }
      return result;
    },

    validateInput(value) {
      const regExp = /^\d+$/;
      const isNumber = regExp.test(value);
      if (this.isGroupStage) {
        if (value <= 0) {
          return {
            isValid: false,
            errorMessage: this.$t('errors.input_digit'),
          };
        } else if (!isNumber) {
          return {
            isValid: false,
            errorMessage: this.$t('errors.input_digit'),
          };
        } else if (value > this.totalGroupsCount) {
          return {
            isValid: false,
            errorMessage: this.$t('errors.group_number_not_exist'),
          };
        } else if (this.bracketNumbers.includes(Number(value))) {
          if (this.stat[value].count === this.stat[value].total) {
            return {
              isValid: false,
              errorMessage: this.$t('errors.group_already_full'),
            };
          } else {
            return {
              isValid: true,
            };
          }
        } else {
          return {
            isValid: true,
          };
        }
      } else if (!isNumber) {
        return {
          isValid: false,
          errorMessage: this.$t('errors.input_digit'),
        };
      } else if (value <= 0) {
        return {
          isValid: false,
          errorMessage: this.$t('errors.input_digit'),
        };
      } else if (value > this.maxBracketNumber) {
        return {
          isValid: false,
          errorMessage: this.$t('errors.number_in_bracket_not_exist'),
        };
      } else if (this.bracketNumbers.includes(Number(value))) {
        const sameBracketNumberTeam = this.seedingTeams.find(
          (team) => Number(team.bracketNumber) === Number(value),
        );
        const hasFixedBracketNumber =
          Object.prototype.hasOwnProperty.call(
            sameBracketNumberTeam,
            'fixedNumber',
          );
        if (hasFixedBracketNumber) {
          return {
            isValid: false,
            errorMessage: this.$t('errors.number_is_already_taken'),
          };
        }
        this.isVisibleErrorModal = true;
        this.errorMessage = this.$t('errors.swap_question');
        return {
          isValid: true,
          isSameIndex: true,
        };
      } else {
        return { isValid: true, errorMessage: '' };
      }
    },

    async setBracketNumber(value, teamId) {
      const { isValid, errorMessage, isSameIndex } =
        this.validateInput(value);
      const currentTeam = this.seedingTeams.find(
        (team) => team.id === teamId,
      );
      const currentIndex = this.seedingTeams.findIndex(
        (team) => team.id === teamId,
      );
      currentTeam.errorInputMessage = errorMessage;
      const insertIndex = Number(value) - 1;

      if (!isValid) {
        return;
      }
      if (isSameIndex) {
        const sameTeamIndex = this.bracketNumbers.findIndex(
          (item) => item === Number(value),
        );
        if (sameTeamIndex === currentIndex) return;
        const sameTeam = this.seedingTeams[sameTeamIndex];
        this.isOpenModal = true;
        const confirmModalPromise =
          await this.$refs.confirmModal.openModal();
        if (confirmModalPromise) {
          this.swapElements(
            this.seedingTeams,
            sameTeamIndex,
            currentIndex,
          );
          currentTeam.bracketNumber = sameTeamIndex + 1;
          sameTeam.bracketNumber = currentIndex + 1;
          this.refreshkey += 1;
        } else {
          currentTeam.bracketNumber = currentIndex + 1;
          this.refreshkey += 1;
        }
      } else {
        if (this.isGroupStage) {
          currentTeam.bracketNumber = Number(value);
        } else {
          currentTeam.bracketNumber = Number(value);
          this.seedingTeams.splice(currentIndex, 1);
          this.seedingTeams.splice(insertIndex, 0, currentTeam);
        }
      }
    },

    allowAll() {
      this.seedingTeams.forEach((team) => {
        team.isParticipant = true;
        team.isDisabledInput = false;
      });
      this.refreshkey += 1;
    },

    async allowAllAndSave() {
      this.allowAll();
      await this.save();
    },

    randomly(arr) {
      if (this.isCurrentOrder) return;
      const arrParticipants = arr.filter(
        (team) => team.isParticipant,
      );
      const arrNoParticipants = arr.filter(
        (team) => !team.isParticipant,
      );
      const randomTeams = arrParticipants.filter(
        (team) =>
          !Object.prototype.hasOwnProperty.call(team, 'fixedNumber'),
      );
      const fixedTeams = arrParticipants.filter((team) =>
        Object.prototype.hasOwnProperty.call(team, 'fixedNumber'),
      );
      const shuffled = randomTeams
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

      for (let team of fixedTeams) {
        shuffled.splice(team.fixedNumber, 0, team);
      }
      this.seedingTeams = shuffled.concat(arrNoParticipants);
    },

    resetAll() {
      if (this.isGroupStage) {
        Object.keys(this.stat).forEach((key) => {
          if (key === 'free') {
            this.stat.free.count = this.maxBracketNumber;
          } else {
            this.stat[key].count = 0;
          }
        });
        this.seedingTeams.forEach((team) => {
          team.isDisabledInput = false;
          team.errorInputMessage = '';
        });
      }
      const teams = this.seedingTeams
        .map((team) => {
          delete team?.fixedNumber;
          return team;
        })
        .map((team) => ({
          ...team,
          bracketNumber: 0,
          errorInputMessage: '',
        }));
      this.seedingTeams = this.filterTeamsAsPerticipant(teams);

      this.isCurrentOrder = false;
    },

    lockInCurrentOrder() {
      this.isCurrentOrder = true;
      if (this.isGroupStage) {
        const onlyParticipants = this.seedingTeams.filter(
          (obj) => obj.isParticipant,
        );
        const { groups, ungroupedTeams } = this.distributeAmongGroups(
          this.groupsCount,
          this.maxTeamsInGroup,
          onlyParticipants,
        );
        this.stat.free.count = ungroupedTeams.length;
        groups.forEach((group, groupIndex) => {
          this.stat[groupIndex + 1].count = group.length;
          group.forEach((team) => {
            team.bracketNumber = groupIndex + 1;
            team.isDisabledInput = true;
            team.errorInputMessage = '';
            this.refreshkey += 1;
          });
        });
      } else {
        this.seedingTeams.forEach((team, index) => {
          if (team.isParticipant) {
            team.bracketNumber = index + 1;
            team.errorInputMessage = '';
            this.refreshkey += 1;
          }
        });
      }
    },

    pinNumber(teamId) {
      const currentTeam = this.seedingTeams.find(
        (team) => team.id === teamId,
      );
      const currentIndex = this.seedingTeams.findIndex(
        (team) => team.id === currentTeam.id,
      );
      currentTeam.isLockedVisible = false;
      currentTeam.fixedNumber = currentIndex;
      currentTeam.isDisabledInput = true;
      currentTeam.bracketNumber = currentIndex + 1;
      currentTeam.errorInputMessage = '';
      this.refreshkey += 1;
    },

    unpinNumber(teamId) {
      const currentTeam = this.seedingTeams.find(
        (team) => team.id === teamId,
      );
      currentTeam.isDisabledInput = false;
      currentTeam.isLockedVisible = true;
      delete currentTeam.fixedNumber;
    },

    filterTeamsAsPerticipant(teams) {
      const filteredIsParticipant = teams
        .filter((obj) => obj.isParticipant)
        .sort((a, b) => {
          const value = (el) => {
            let x = parseFloat(el);
            return x === 0 ? Infinity : x;
          };
          return value(a.bracketNumber) - value(b.bracketNumber);
        });
      const filteredNoParticipant = teams.filter(
        (obj) => !obj.isParticipant,
      );
      return filteredIsParticipant.concat(filteredNoParticipant);
    },

    async save() {
      const params = this.seedingTeams.map((obj) => ({
        teamId: obj.id,
        bracketNumber: obj.bracketNumber,
        seeded: obj.seeded,
        isParticipant: obj.isParticipant ? 'Y' : 'N',
      }));

      try {
        this.isError = false;
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/seedingsave`;
        const result = await api.post(url, params);
        const teams = Array.isArray(result.data.teams)
          ? result.data.teams
          : Object.values(result.data.teams);
        this.teams = teams;
        const teamsResult = teams.map((obj, idx) => ({
          ...obj,
          order: idx + 1,
          teamId: obj.id,
          isLockedVisible: true,
          isParticipant: obj.status === 'selected',
          isDisabledInput: obj.status === 'registered',
          errorInputMessage: '',
        }));
        this.refreshkey += 1;
        this.seedingTeams =
          this.filterTeamsAsPerticipant(teamsResult);
        this.isSavedData = true;
        // TODO: выпилить когда будет нормальный дизайн нотификаторов
        setTimeout(() => {
          this.isSavedData = false;
        }, 3000);
      } catch (err) {
        this.isError = true;
        this.errorFromBackend = err.error[0];
        setTimeout(() => {
          this.isError = false;
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.seeding {
  padding: 0 12px;
  @include min-tablet() {
    padding: 0;
  }
}

.seeding-info {
  margin-bottom: 8px;
  opacity: 0;
}

.seeding-table-group {
  margin-bottom: 8px;
  max-height: 330px;
  overflow: auto;
}

.seeding-settings {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  background-color: var(--main-color-darkgray);
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  gap: 8px;
  margin-bottom: 8px;
}

.info-btns {
  display: flex;
  gap: 8px;
}

.seeding-table__input {
  white-space: normal;
  width: 120px;
}

.error-modal__settings-btns {
  display: flex;
  gap: 8px;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fade-out;
  animation-duration: 3s;
}
</style>
