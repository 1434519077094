<template>
  <div>
    <InfoBox v-if="isError" class="error-msg" variant="error">
      {{ errorMessage }}
    </InfoBox>
    <div v-if="isShowComponent" class="match-control">
      <div class="match-control__container">
        <div class="filters">
          <div class="filters__item">
            <p class="filters__title">Матч завершен</p>
            <Select
              :value="getFilterParams['f[finished]']"
              class="filters__select"
              display-value="name"
              option-key="value"
              :options="optionsChoose"
              @input="updateFilterFinished"
            />
          </div>
          <div class="filters__item">
            <p class="filters__title">Статус матча</p>
            <Select
              :value="getFilterParams['f[specialStatus]']"
              class="filters__select"
              display-value="name"
              option-key="value"
              :options="optionsStatus"
              @input="updateFilterStatus"
            />
          </div>
          <div class="filters__item">
            <p class="filters__title">Состав матча определен</p>
            <Select
              :value="getFilterParams['f[teamsDefined]']"
              class="filters__select"
              display-value="name"
              option-key="value"
              :options="optionsChoose"
              @input="updateFilterTeamsDefined"
            />
          </div>
          <div class="filters__item">
            <p class="filters__title">На странице</p>
            <Select
              :value="getFilterParams['c']"
              class="filters__select"
              display-value="name"
              option-key="value"
              :options="optionsOnPage"
              @input="updateFilterOnPage"
            />
          </div>
          <div class="filters__item">
            <p class="filters__title">
              Номер раунда или список раундов
            </p>
            <Input
              :value="getFilterParams['f[rounds]'].value"
              class="filters__input"
              :error-text="errorInputFilter1"
              placeholder="пример: 1, 1U, 1L"
              @change="
                validateInputFilter($event, 'errorInputFilter1')
              "
              @input="updateFilterRounds"
            />
          </div>
          <div class="filters__item">
            <p class="filters__title">
              ID матча (игнорирует остальные фильтры)
            </p>
            <Input
              :value="getFilterParams['f[idMatch]'].value"
              class="filters__input"
              @input="updateFilterIdMatch"
            />
          </div>
        </div>
        <div class="match-control__list">
          <div
            v-for="match in matches"
            :key="match.id"
            class="match-control__list-item"
          >
            <div class="match-card-header" data-header>
              Раунд: {{ match.numRound }}
              <p>{{ match.name }}</p>
              {{ match.startTime }}
              <div v-if="match.pausedAt" class="pause-duration">
                <p>Пауза:</p>
                <Timer :initial="match.pausedAt" />
              </div>
            </div>
            <div class="special-status">
              <span
                v-if="match.specialStatus.length"
                class="special-status__label"
                :class="{
                  'special-status--alert':
                    match.isSpecialStatusCritical,
                }"
              >
                {{ match.specialStatus }}</span
              >
              <span
                v-if="match.finished === 'да'"
                class="special-status__label special-status--success"
              >
                Завершен
              </span>
            </div>
            <template v-if="isTeamFFA">
              <div class="match-card-results__players">
                Количество игроков: {{ match.teams.length }}
                <button
                  v-if="!match.isVisibleTeams"
                  class="match-card-server__toggle-btn"
                  @click="showAllTeams(match)"
                >
                  {{ $t('actions.show') }}
                </button>
                <template v-else>
                  <button
                    class="match-card-server__toggle-btn"
                    @click="hideAllTeams(match)"
                  >
                    {{ $t('actions.hide') }}
                  </button>
                  <ul class="teams-list">
                    <li v-for="team in match.teams" :key="team.hash">
                      <BaseTeamLink
                        :hash="team.hash"
                        :image-size="24"
                      />
                    </li>
                  </ul>
                </template>
              </div>
            </template>
            <div v-else class="match-card-results">
              <div class="match-card-results__team">
                <div v-if="match.team1.name === 'FreeSlot'">
                  {{ match.team1.name }}
                </div>
                <BaseTeamLink v-else :hash="match.team1.hash" />
                <div
                  v-if="match.team1.teamResultLabel"
                  class="match-card-results__team-additional"
                >
                  {{ match.team1.teamResultLabel.value }}
                </div>
                <div
                  v-if="match.team1.tpStatusLabel"
                  class="match-card-results__team-additional"
                >
                  <div>{{ match.team1.tpStatusLabel }}</div>
                  <div>{{ `Причина: ${match.team1.tpReason}` }}</div>
                </div>
                <div
                  v-if="Object.keys(match.team1.result).length"
                  class="match-card-results__team-additional"
                >
                  <div
                    v-if="getTeamScore(match.team1.result)"
                    class="match-score"
                  >
                    {{ `Счёт ${getTeamScore(match.team1.result)}` }}
                  </div>
                  <ul class="match-card-results__screenshot-list">
                    <li
                      v-for="(screenshot, idx) in match.team1.result
                        .screens"
                      :key="screenshot.url"
                      class="match-card-results__screenshot-list-item"
                    >
                      <a
                        :href="screenshot.url"
                        target="_blank"
                        class="match-card-results__screenshot"
                      >
                        {{ `Скриншот ${idx + 1}` }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="match-card-results__total">
                {{ getScore(match) }}
              </div>
              <div class="match-card-results__team">
                <div v-if="match.team2.name === 'FreeSlot'">
                  {{ match.team2.name }}
                </div>
                <BaseTeamLink v-else :hash="match.team2.hash" />
                <div
                  v-if="match.team2.teamResultLabel"
                  class="match-card-results__team-additional"
                >
                  {{ match.team2.teamResultLabel.value }}
                </div>
                <div
                  v-if="match.team2.tpStatusLabel"
                  class="match-card-results__team-additional"
                >
                  <div>{{ match.team2.tpStatusLabel }}</div>
                  <div>{{ `Причина: ${match.team2.tpReason}` }}</div>
                </div>
                <div
                  v-if="Object.keys(match.team2.result).length"
                  class="match-card-results__team-additional"
                >
                  <div
                    v-if="getTeamScore(match.team2.result)"
                    class="match-score"
                  >
                    {{ `Счёт ${getTeamScore(match.team2.result)}` }}
                  </div>
                  <ul class="match-card-results__screenshot-list">
                    <li
                      v-for="(screenshot, idx) in match.team2.result
                        .screens"
                      :key="screenshot.url"
                      class="match-card-results__screenshot-list-item"
                    >
                      <a
                        :href="screenshot.url"
                        target="_blank"
                        class="match-card-results__screenshot"
                      >
                        {{ `Скриншот ${idx + 1}` }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              v-if="hasServerInfo(match.id)"
              class="match-card-server"
            >
              <p>
                {{ `${$t('global.server')}: ` }}
                {{ matchServersInfo[match.id].server.id }} /
                {{ matchServersInfo[match.id].server.value }}
              </p>
              <div class="match-card-server__status">
                <div class="server-info-wrapper">
                  <span>{{ `${$t('tournament.stage')}: ` }}</span>
                  <span
                    class="server-info-status"
                    :class="`server-info-status--${
                      matchServersInfo[match.id].stage.class
                    }`"
                  >
                    {{ matchServersInfo[match.id].stage.value }}
                  </span>
                </div>
                <div class="server-info-wrapper">
                  <span>{{ `${$t('global.status')}: ` }}</span>
                  <span
                    class="server-info-status"
                    :class="`server-info-status--${
                      matchServersInfo[match.id].status.class
                    }`"
                  >
                    {{ matchServersInfo[match.id].status.value }}
                  </span>
                </div>
              </div>
            </div>
            <div class="match-card-results__footer">
              <template v-if="match.reports.length">
                <Button
                  v-for="report in match.reports"
                  :key="report.url"
                  tag="a"
                  :href="report.url"
                  size="small"
                  type="secondary"
                  :text="report.label"
                />
              </template>
              <Button
                v-if="hasServerInfo(match.id)"
                size="small"
                type="secondary"
                :text="$t('global.server')"
                class="table__button"
                @click="showServerStatusModal(match.id)"
              />
              <div v-for="(action, idx) in match.actions" :key="idx">
                <Button
                  size="small"
                  type="secondary"
                  :text="action.label"
                  @click="makeAction(action.type, match.id)"
                >
                </Button>
              </div>
              <div v-if="match.showUrl">
                <Button
                  tag="a"
                  size="small"
                  type="secondary"
                  text="Перейти к матчу"
                  :href="match.url"
                  target="_blank"
                />
              </div>
              <div>
                <Button
                  v-if="
                    canAdminPauseMatch(match.id) && !match.isPaused
                  "
                  size="small"
                  type="secondary"
                  :text="pauseText"
                  @click="showPausedModal(match)"
                />
                <Button
                  v-if="
                    canAdminUnpauseMatch(match.id) && match.isPaused
                  "
                  size="small"
                  type="primary"
                  :text="unpauseText"
                  @click="showPausedModal(match)"
                />
              </div>
              <div>
                <Button
                  v-if="canAdminSendMessage(match.id)"
                  size="small"
                  type="secondary"
                  text="Сообщение"
                  @click="showAdminMsgModal(match.id)"
                />
              </div>
              <div v-if="canAdminRestoreBackup(match.id)">
                <Button
                  tag="button"
                  size="small"
                  type="secondary"
                  text="Бекап к раунду"
                  @click="showBackupModal(match.id)"
                />
              </div>
            </div>
          </div>
        </div>
        <pagination
          class="pagination"
          :params="paginationParams"
          @onchange="setPage"
        />
      </div>
      <Modal
        v-if="isVisibleModal"
        class="modal"
        type="regular"
        :title="modalTitle"
        @close="closeModal"
      >
        <div v-if="isEnterMatchScore" class="enter-match-score">
          <InfoBox v-if="isError" class="error-msg" variant="error">
            {{ error }}
          </InfoBox>
          <InfoBox
            v-if="isSuccess"
            class="success-msg"
            variant="success"
          >
            {{ successMessage }}
          </InfoBox>
          <div class="scores-team">
            <p class="scores-team__team-name">{{ team1Name }}</p>
            <Input
              :key="inputKey"
              v-model="setScoreParams.form.pointsTeam1"
              class="scores-team__score"
              :error-text="errorTeam1"
              max-length="4"
              :disabled="setScoreParams.form.tpTeam1"
              @input="validateInput($event, 'errorTeam1')"
            />
            <Checkbox
              v-model="setScoreParams.form.tpTeam1"
              class="scores-team__tech-defeat"
              name="tech-defeat-team1"
              text="Техническое поражение"
              @change="selectTpTeam($event, 1)"
            ></Checkbox>
            <Input
              v-if="setScoreParams.form.tpTeam1"
              v-model="setScoreParams.form.tpReasonTeam1"
              class="scores-team__defeat-reason"
              placeholder="Введите причину"
            />
          </div>

          <div class="scores-team">
            <p class="scores-team__team-name">{{ team2Name }}</p>
            <Input
              :key="inputKey"
              v-model="setScoreParams.form.pointsTeam2"
              class="scores-team__score"
              :error-text="errorTeam2"
              max-length="4"
              :disabled="setScoreParams.form.tpTeam2"
              @input="validateInput($event, 'errorTeam2')"
            />
            <Checkbox
              v-model="setScoreParams.form.tpTeam2"
              class="scores-team__tech-defeat"
              name="tech-defeat-team2"
              text="Техническое поражение"
              @change="selectTpTeam($event, 2)"
            ></Checkbox>
            <Input
              v-if="setScoreParams.form.tpTeam2"
              v-model="setScoreParams.form.tpReasonTeam2"
              class="scores-team__defeat-reason"
              placeholder="Введите причину"
            />
          </div>

          <p class="enter-match-score__info">
            {{ $t('tournaments.winner_set_auto_hint') }}
          </p>
          <div
            v-if="!isVisibleCloseButton"
            class="enter-match-score__settings-btns"
          >
            <Button
              tag="button"
              size="small"
              type="secondary"
              :text="$t('actions.confirm')"
              :disabled="isDisabledConfirmButton"
              @click="setScoreMatch"
            >
            </Button>
            <Button
              tag="button"
              size="small"
              type="tertiary"
              :text="$t('actions.cancel')"
              @click="closeModal"
            >
            </Button>
          </div>
          <div v-else>
            <Button
              tag="button"
              size="small"
              type="secondary"
              :text="$t('actions.close')"
              @click="closeModal"
            />
          </div>
        </div>

        <div
          v-else-if="isCancelMatchScore"
          class="cancel-match-score"
        >
          <InfoBox v-if="isError" class="error-msg" variant="error">
            {{ error }}
          </InfoBox>
          <InfoBox
            v-if="isSuccess"
            class="success-msg"
            variant="success"
          >
            {{ successMessage }}
          </InfoBox>
          <p style="margin-bottom: 12px">
            {{
              'Это действие сбросит счет матча и данные матча от пользователей, восстановить его будет нельзя'
            }}
          </p>
          <div
            v-if="isVisibleSettingButtons"
            class="cancel-match-score__settings-btns"
          >
            <Button
              tag="button"
              size="small"
              type="secondary"
              :text="$t('actions.confirm')"
              @click="resetScoreMatch"
            >
            </Button>
            <Button
              tag="button"
              size="small"
              type="tertiary"
              :text="$t('actions.cancel')"
              @click="closeModal"
            >
            </Button>
          </div>
        </div>

        <div v-else-if="isWfMatchScore" class="wf-match-score">
          <div class="wf-match-score__header">
            <InfoBox v-if="isError" class="error-msg" variant="error">
              {{ error }}
            </InfoBox>
            <InfoBox
              v-if="isSuccess"
              class="success-msg"
              variant="success"
            >
              {{ successMessage }}
            </InfoBox>
          </div>
          <div class="match-card__select-block">
            <Select
              v-model="selectedWfOption"
              display-value="name"
              option-key="value"
              :options="optionsWfSelect"
              class="match-card__select"
              :disabled="isDisabledSelect"
            />
          </div>
          <div class="wf-match-score__content">
            <div
              v-for="(match, idx) in wfData"
              :key="idx"
              class="match-card"
            >
              <input
                :id="match.id"
                v-model="selectedMatches"
                type="checkbox"
                class="match-card__input"
                :name="match.map"
                :value="match"
              />
              <label :for="match.id" class="match-card__label">
                <p>{{ match.team1.name }} - {{ match.team2.name }}</p>
                <p>
                  Счет
                  {{ `${match.team1.score} : ${match.team2.score}` }}
                </p>
                <p>
                  <span>Карта: </span>
                  <span>{{ match.map }}</span>
                </p>
                <p>
                  <span>Время начала: </span>
                  <span>{{
                    $dt(match.start, 'dd_MM_YYYY_HH_mm_ss')
                  }}</span>
                </p>
                <p>
                  <span>Длительность: </span>
                  <span>{{ match.duration }}</span>
                </p>
              </label>
            </div>
          </div>
          <div class="wf-match-score__buttons">
            <template v-if="isVisibleWfButtons">
              <Button
                tag="button"
                size="small"
                type="secondary"
                :text="$t('actions.confirm')"
                :disabled="selectedWfOption.value === 'choose'"
                @click="saveWfResult(matchId)"
              />
              <Button
                tag="button"
                size="small"
                type="tertiary"
                :text="$t('actions.cancel')"
                @click="closeModal"
              />
            </template>
            <template v-else>
              <Button
                tag="button"
                size="small"
                type="secondary"
                :text="$t('actions.close')"
                @click="closeModal"
              />
            </template>
          </div>
        </div>

        <div v-else-if="isServerCsgo" class="server-csgo">
          <template v-if="hasNoActiveServer">
            <div class="server-csgo__item">
              <div class="server-csgo__status-title">Статус</div>
              <span
                class="
                  server-info-status server-info-status--attention
                "
              >
                {{ 'Не используется' }}
              </span>
            </div>
          </template>
          <template v-else>
            <div class="server-csgo__item control-wrapper">
              <span class="server-csgo__status-title">Статус</span>
              <span
                class="server-info-status"
                :class="`server-info-status--${serverInfo.status.class}`"
              >
                {{ serverInfo.status.name }}
              </span>
            </div>
            <Button
              v-if="serverInfo.canRestart"
              tag="button"
              size="regular"
              type="primary"
              :text="$t('server.reload')"
              @click="isVisibleConfirmBlock = !isVisibleConfirmBlock"
            />
            <div v-if="isVisibleConfirmBlock">
              <div class="restart-server__warning">
                'Это действие перезапустит матч на сервере. Оно не
                обратимо. Вы точно хотите перезапустить сервер ?'
              </div>
              <Button
                v-if="isVisibleRestartServerButton"
                class="restart-server__confirm-button"
                tag="button"
                size="small"
                type="secondary"
                :text="$t('actions.confirm')"
                :loading="isRestartingServer"
                @click="restartServer(matchId)"
              />
            </div>
            <div class="server-csgo__item">
              <span>{{ $t('rating.cell_team') }}</span>
              <div class="control-wrapper">
                <Select
                  v-model="selectedServerAction"
                  display-value="name"
                  option-key="name"
                  :options="serverInfo.actions"
                />
                <Button
                  tag="button"
                  size="regular"
                  type="primary"
                  :text="$t('server.exec')"
                  :loading="isLoadingServerData"
                  @click="
                    makeServerAction(matchId, payloadServerAction)
                  "
                />
              </div>
            </div>
            <div class="server-csgo__item">
              <span>Результат</span>
              <TextArea
                ref="serverResult"
                class="command-result"
                :rows="5"
                :cols="40"
                :text="resultServerAction"
                disabled
              />
            </div>
          </template>
        </div>

        <div v-else-if="isRestartServerCsgo" class="restart-server">
          <div class="restart-server__warning">
            {{
              'Это действие перезапустит матч на сервере. Это действие не обратимо.'
            }}
          </div>

          <div
            v-if="isVisibleRestartButtons"
            class="enter-match-score__settings-btns"
          >
            <Button
              tag="button"
              size="regular"
              type="primary"
              :text="$t('server.reload')"
              @click="restartServer(matchId)"
            />
            <Button
              tag="button"
              size="regular"
              type="tertiary"
              :text="$t('actions.cancel')"
              @click="closeModal"
            />
          </div>
        </div>

        <div v-else-if="isNoWfData">
          <div class="restart-server__warning">
            {{ 'Данные из API по матчу не найдены' }}
          </div>
        </div>

        <div v-else-if="isVisibleAdminMsgModal">
          <div class="admin-msg-modal">
            <p class="admin-msg-modal__desc">
              {{ $t('server.type_admin_message') }}
            </p>
            <Input
              ref="adminMessage"
              :key="updateKey"
              v-model="adminMessage"
              :max-length="128"
              :placeholder="$t('chat.enter_message')"
              class="admin-msg-modal__msg"
            />
            <Button
              tag="button"
              class="admin-msg-modal__send-button"
              size="regular"
              type="secondary"
              :text="$t('actions.send')"
              :loading="isLoading"
              @click="sendAdminMessage()"
            />
            <div v-if="isError">
              <InfoBox
                v-for="(err, idx) in error"
                :key="idx"
                class="error-msg"
                variant="error"
              >
                {{ err.detail }}
              </InfoBox>
            </div>

            <InfoBox
              v-if="isSuccess"
              class="success-msg"
              variant="success"
            >
              {{ successSendMessage }}
            </InfoBox>
          </div>
        </div>

        <div v-else-if="isPauseModal" class="pause-match">
          <p class="pause-match__desc">
            {{
              currentMatch?.isPaused
                ? $t('server.confirm_unpause_match')
                : $t('server.confirm_pause_match')
            }}
          </p>
          <div class="pause-match__buttons">
            <Button
              size="regular"
              type="primary"
              :loading="isLoading"
              :text="$t('actions.confirm')"
              @click="setMatchPause(isPausedText)"
            />
            <Button
              size="regular"
              type="tertiary"
              :text="$t('actions.cancel')"
              @click="closeModal"
            />
          </div>
          <InfoBox v-if="isError" class="error-msg" variant="error">
            {{ error }}
          </InfoBox>
        </div>

        <div v-else-if="isVisibleBackupModal" class="backup-modal">
          <InfoBox v-if="isError" class="error-msg" variant="error">
            {{ error }}
          </InfoBox>
          <loader
            v-if="isLoading"
            class="backup-modal__loading"
            :size="32"
          />
          <div v-else>
            <h3 class="backup-modal__title">Выберите раунд</h3>
            <p class="backup-modal__desc">
              Выберите раунд, к которому хотите вернуться. Обратите
              внимание, после подтверждения действие необратимо.
            </p>
            <div
              v-if="matchBackups.length"
              class="backup-modal__select"
            >
              <Select
                v-model="selectedBackupRound"
                display-value="info"
                option-key="round"
                :options="matchBackups"
                placeholder="Выберите раунд"
              />
            </div>
            <Button
              tag="button"
              size="regular"
              type="secondary"
              long
              :loading="isLoadingRestore"
              :disabled="
                Object.keys(selectedBackupRound).length === 0
              "
              :text="$t('actions.confirm')"
              class="backup-modal__btn"
              @click="restoreMatchFromBackup()"
            />
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { useMachine } from 'xstate-vue2';
import loadingMachine from '@src/machines/loading.js';
import Input from '@components/v2/ui/Input.vue';
import Select from '@components/v2/ui/Select.vue';
import Pagination from '@src/components/v2/Pagination.vue';
import Button from '@components/v2/ui/Button.vue';
import Checkbox from '@components/v2/ui/Checkbox.vue';
import Modal from '@src/components/v2/Modal.vue';
import TextArea from '@src/components/v2/ui/TextArea.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import Timer from '@components/v2/Timer.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'TournamentEditMatchControl',
  components: {
    Input,
    Select,
    Button,
    Modal,
    Checkbox,
    Pagination,
    TextArea,
    BaseTeamLink,
    Timer,
    InfoBox,
  },

  setup() {
    const { state, send } = useMachine(loadingMachine);
    return {
      state,
      send,
    };
  },
  data() {
    return {
      currentPage: this.$route.query.p || 1,
      perPage: 8,
      totalMatches: 0,
      tableData: [],
      tournamentId: '',
      organizationId: '',
      matchId: '',
      currentMatch: {},
      matches: [],
      isLoading: false,
      isLoadingRestore: false,
      isEnterMatchScore: false,
      isCancelMatchScore: false,
      isWfMatchScore: false,
      isServerCsgo: false,
      isVisibleModal: false,
      isVisibleSettingButtons: true,
      isVisibleWfButtons: true,
      isRestartServerCsgo: false,
      isVisibleRestartButtons: true,
      isVisibleCloseButton: false,
      isLoadingServerData: false,
      isVisibleConfirmBlock: false,
      isRestartingServer: false,
      isDisabledSelect: false,
      isVisibleRestartServerButton: true,
      team1Name: '',
      team2Name: '',
      pointsTeam1: '',
      pointsTeam2: '',
      isTechDefeatTeam1: false,
      isTechDefeatTeam2: false,
      isNoWfData: false,
      successMessage: 'Данные успешно сохранены',
      successSendMessage: 'Сообщение успешно отправлено',
      isError: false,
      error: '',
      errorMessage: '',
      errorTeam1: '',
      errorTeam2: '',
      errorInputFilter1: '',
      isSuccess: false,
      selectedMatches: [],
      matchServersInfo: {},
      serverInfo: {},
      hasNoActiveServer: false,
      resultServerAction: '',
      timerId: null,
      inputKey: 0,
      initialFilterParams: {
        c: '8',
        p: '1',
      },
      optionsChoose: [
        { id: 1, name: 'Выбрать' },
        { id: 2, name: 'да', value: 'Y' },
        { id: 3, name: 'нет', value: 'N' },
      ],
      optionsStatus: [
        { id: 0, name: 'Выбрать' },
        { id: 1, name: 'Подтвержден одной командой', value: '1' },
        { id: 2, name: 'Подтвержден двумя командами', value: '2' },
        { id: 3, name: 'Требует подтверждения судьей', value: '3' },
        {
          id: 4,
          name: 'Судья не успел подтвердить матч',
          value: '4',
        },
        { id: 5, name: 'Подтвержден судьей', value: '5' },
      ],
      optionsOnPage: [
        { id: 1, name: '2', value: '2' },
        { id: 2, name: '4', value: '4' },
        { id: 3, name: '8', value: '8' },
        { id: 4, name: '16', value: '16' },
        { id: 5, name: '32', value: '32' },
        { id: 6, name: '64', value: '64' },
        { id: 7, name: '128', value: '128' },
      ],
      optionsWfSelect: [
        { id: 1, name: 'Выбрать', value: 'choose' },
        { id: 2, name: 'Разница по раундам', value: 'rounds' },
        { id: 3, name: 'Разница по картам', value: 'maps' },
      ],
      setScoreParams: {
        form: {
          id: this.matchId,
          pointsTeam1: '',
          pointsTeam2: '',
          tpTeam1: false,
          tpTeam2: false,
          tpReasonTeam1: '',
          tpReasonTeam2: '',
        },
      },
      wfData: [],
      selectedWfOption: {
        id: 1,
        name: 'Выбрать',
        value: 'choose',
      },
      selectedServerAction: {
        name: 'Получить статус',
        code: 'status',
      },
      isVisibleAdminMsgModal: false,
      adminMessage: '',
      updateKey: 0,
      isPauseModal: false,
      matchBackups: [],
      selectedBackupRound: {},
      isVisibleBackupModal: false,
    };
  },

  computed: {
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentPage',
    ]),
    ...mapGetters('tournamentSettings', ['getFilterParams']),

    queryParams() {
      return this.$route.query;
    },

    isShowComponent() {
      return ['success', 'errorFilters'].some(this.state.matches);
    },

    paramsForFetchMatches() {
      const filterParams = this.normalizeParams(this.getFilterParams);
      return filterParams;
    },

    paginationParams() {
      return {
        page: this.$route.query.p,
        perPage: this.perPage,
        total: this.totalMatches,
        perPageSizes: [this.getFilterParams?.c?.value],
        showBy: 'Показывать по',
      };
    },

    modalTitle() {
      if (this.isEnterMatchScore) {
        return this.$t('tournaments.specify_score');
      } else if (this.isServerCsgo) {
        return this.$t('global.server');
      } else if (this.isNoWfData || this.isWfMatchScore) {
        return this.$t('rating.data');
      } else if (this.isPauseModal) {
        return this.currentMatch?.isPaused
          ? this.$t('server.unpause_match')
          : this.$t('server.pause_match');
      } else if (this.isVisibleBackupModal) {
        return this.$t('server.backup_round');
      } else {
        return this.$t('actions.confirm');
      }
    },

    payloadServerAction() {
      return {
        idServer: this.serverInfo.idServer,
        action: this.selectedServerAction.code,
      };
    },

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    isTeamFFA() {
      const codeTeamFFA = 6;
      return this.tournament?.idSystem === codeTeamFFA;
    },
    tableHeaders() {
      return this.isTeamFFA
        ? [
            this.$t('change.match_start_time'),
            this.$t('mappick.entity_type_round'),
            this.$t('teams.few'),
            this.$t('server.finished'),
            this.$t('change.match_special_status'),
            this.$t('players.role_judge'),
          ]
        : [
            this.$t('change.match_start_time'),
            this.$t('mappick.entity_type_round'),
            this.$t('server.team1'),
            this.$t('server.team2'),
            this.$t('scores.single'),
            this.$t('server.finished'),
            this.$t('change.match_special_status'),
          ];
    },

    wfSaveParams() {
      switch (this.selectedWfOption.value) {
        case 'rounds':
          return this.selectedMatches.reduce(
            (acc, obj) => {
              const teams = [obj.team1, obj.team2];
              const num1 = teams.find((team) => team.num === 1);
              const num2 = teams.find((team) => team.num === 2);

              return {
                ...acc,
                pointsTeam1:
                  Number(acc.pointsTeam1) + Number(num1.score),
                pointsTeam2:
                  Number(acc.pointsTeam2) + Number(num2.score),
                fights: [...acc.fights, obj.id],
              };
            },
            { pointsTeam1: 0, pointsTeam2: 0, fights: [] },
          );
        case 'maps':
          return this.selectedMatches.reduce(
            (acc, obj) => {
              const teams = [obj.team1, obj.team2];
              const num1 = teams.find((team) => team.num === 1);
              const num2 = teams.find((team) => team.num === 2);

              return {
                ...acc,
                pointsTeam1:
                  Number(num1.score) > Number(num2.score)
                    ? acc.pointsTeam1 + 1
                    : acc.pointsTeam1,
                pointsTeam2:
                  Number(num1.score) < Number(num2.score)
                    ? acc.pointsTeam2 + 1
                    : acc.pointsTeam2,
                fights: [...acc.fights, obj.id],
              };
            },
            { pointsTeam1: 0, pointsTeam2: 0, fights: [] },
          );
        default:
          return { pointsTeam1: 0, pointsTeam2: 0, fights: [] };
      }
    },
    hasServerInfo() {
      return (matchId) => {
        return (
          Object.keys(this.matchServersInfo).length &&
          this.matchServersInfo[matchId]?.stage?.value === 'match'
        );
      };
    },

    isDisabledConfirmButton() {
      return Boolean(this.errorTeam1 || this.errorTeam2);
    },

    pauseText() {
      return this.$t('server.pause');
    },

    unpauseText() {
      return this.$t('server.unpause');
    },

    isPausedText() {
      return this.currentMatch?.isPaused ? 'unpause' : 'pause';
    },

    selectedMatchIndex() {
      return this.matches.findIndex(
        (match) => match.id === this.matchId,
      );
    },
  },

  watch: {
    queryParams: {
      async handler() {
        this.send('REFETCH');
        await this.fetchMatches(this.queryParams);
      },
      deep: true,
    },
    isVisibleAdminMsgModal() {
      if (this.isVisibleAdminMsgModal) {
        this.$nextTick(() => {
          this.$refs.adminMessage.$refs.inputField.focus();
        });
      }
    },
  },

  async mounted() {
    this.updateFilterPerPage({
      id: this.currentPage,
      name: this.currentPage,
      value: `${this.currentPage}`,
    });
    this.tournamentId = this.$route.params.tnId;
    this.organizationId = this.$route.params.orgId;
    const currentQueries = Object.entries(this.queryParams);

    if (currentQueries.length) {
      currentQueries.forEach(([key, val]) => {
        const currentStoreValue = this.getFilterParams[key];
        const newValueForStore = { ...currentStoreValue, value: val };
        this.syncQueryWithStore(key, newValueForStore);
      });

      await this.fetchTeams();
      await this.fetchMatches(this.queryParams);
    } else {
      const params = {};
      for (let [key, val] of Object.entries(this.getFilterParams)) {
        if (val.value) {
          params[key] = val.value;
        }
      }

      await this.fetchTeams();
      this.setQueryParams(params);
    }
  },

  methods: {
    async fetchTeams() {
      const { data } = await api.get(
        `tournament/${this.tournamentId}/teams`,
      );
      this.$store.commit('teams/UPDATE_TEAMS', data);
    },

    async fetchMatches(params = {}) {
      try {
        this.isError = false;
        this.send('FETCH');
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/matchteams`;
        const res = await api.get(url, { params });
        this.send('RESOLVE');
        this.matches = res?.data?.matches.map((match) => ({
          isVisibleTeams: false,
          ...match,
        }));
        this.totalMatches = res?.data?.totalMatches;
        this.perPage = this.getFilterParams?.c?.value;
        this.judges = res.data?.judges;
        this.matchServersInfo = res.data?.matchServersInfo;
        this.matches.forEach((match) => {
          if (this.matchServersInfo[match.id]) {
            match.isPaused = this.matchServersInfo[match.id].isPaused;
            match.pausedAt = this.matchServersInfo[match.id].pausedAt;
          }
        });
        if (this.$route.query.p > res.data.totalPages) {
          this.setPage({ page: 1, perPage: this.perPage });
        }
      } catch (err) {
        const error = JSON.parse(err.message);
        this.errorMessage = error.errorMessage;
        const errorText = err.error[0];
        this.isError = true;
        this.send('REJECT');

        if (errorText === 'Доступ запрещен') {
          this.send('NOMATCHES');
          this.errorMessage = this.$t(
            'errors.access_to_match_control_disabled',
          );
        }
        if (errorText === 'Данные отсутствуют') {
          this.send('ERRORFILTERS');
          this.errorMessage = errorText;
        }
        this.matches = [];
      }
    },

    async sendAdminMessage() {
      try {
        this.isError = false;
        this.isSuccess = false;
        this.isLoading = true;
        const url = `match/${this.matchId}/server/cs2/message`;
        await api.post(url, { message: this.adminMessage });
        this.isSuccess = true;
        this.adminMessage = '';
        this.updateKey += 1;
      } catch (err) {
        this.isError = true;
        this.error = err?.error;
      } finally {
        this.isLoading = false;
        setTimeout(() => {
          this.isSuccess = false;
        }, 3000);
      }
    },

    updateAdminMessage(message) {
      this.adminMessage = message;
    },

    async getMatchBackups() {
      try {
        this.isError = false;
        this.isLoading = true;
        const url = `match/${this.matchId}/server/cs2/backups`;
        const res = await api.get(url);
        const backupsInfo = res.data.map((obj) => ({
          info: `${obj.round} round, ${obj.map}, ${obj.team1Score}-${obj.team2Score}`,
          ...obj,
        }));
        this.matchBackups = backupsInfo;
      } catch (err) {
        this.isError = true;
        this.error = err?.error[0]?.detail;
      } finally {
        this.isLoading = false;
      }
    },

    async restoreMatchFromBackup() {
      try {
        this.isLoadingRestore = true;
        const url = `match/${this.matchId}/server/cs2/restore`;
        await api.post(url, {
          backup: this.selectedBackupRound['backup'],
        });
        this.isVisibleModal = false;
      } catch (err) {
        this.isError = true;
        this.error = err?.error[0]?.detail;
      } finally {
        this.isLoadingRestore = false;
      }
    },

    setQueryParams(query) {
      if (_.isEqual(this.$route.query, query)) return;

      this.$router.push({
        path: 'administration',
        query,
      });
    },

    syncQueryWithStore(key, value) {
      const map = {
        'f[finished]': (val) => this.updateFilterFinished(val),
        'f[specialStatus]': (val) => this.updateFilterStatus(val),
        'f[teamsDefined]': (val) =>
          this.updateFilterTeamsDefined(val),
        'f[rounds]': (val) => this.updateFilterRounds(val),
        'f[idMatch]': (val) => this.updateFilterIdMatch(val),
        c: (val) => this.updateFilterOnPage(val),
        p: (val) => this.updateFilterPerPage(val),
      };
      return map[key](value);
    },

    async getMatchInfoPerId(matchId) {
      const params = {
        idMatch: matchId,
      };
      const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/matchgetscorefrom`;
      try {
        const res = await api.post(url, params);
        const match = res?.data?.match;
        this.team1Name = match?.team1Name;
        this.team2Name = match?.team2Name;
        this.pointsTeam1 = match?.pointsTeam1;
        this.pointsTeam2 = match?.pointsTeam2;
      } catch (err) {
        this.isError = true;
        this.error = err.error[0];
      }
    },

    async setScoreMatch() {
      this.isError = false;
      this.isSuccess = false;
      try {
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/matchsetscore`;
        const { success } = await api.post(url, this.setScoreParams);
        if (success) {
          this.isSuccess = true;
          this.isVisibleCloseButton = true;
        }
        this.inputKey += 1;
        await this.fetchMatches(this.paramsForFetchMatches);
      } catch (err) {
        this.isError = true;
        this.error = err.error[0];
      }
    },

    async resetScoreMatch() {
      this.isError = false;
      this.isSuccess = false;
      const params = {
        idMatch: this.matchId,
      };
      try {
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/matchresetscore`;
        const { success } = await api.post(url, params);
        if (success) {
          this.isSuccess = true;
          this.isVisibleSettingButtons = false;
        }
        await this.fetchMatches(this.paramsForFetchMatches);
      } catch (err) {
        this.isError = true;
        this.error = err.error[0];
      }
    },

    async getWfData(matchId) {
      try {
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/match/${matchId}/wfdata`;
        const {
          data: { items },
          success,
        } = await api.get(url);
        this.wfData = items;
        return success;
      } catch (err) {
        this.isNoWfData = true;
        this.isVisibleModal = true;
      }
    },

    async saveWfResult(matchId) {
      if (this.selectedMatches.length === 0) {
        this.isError = true;
        this.error = this.$t('errors.need_select_matches');
      } else {
        this.isSuccess = false;
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/match/${matchId}/wfdata`;
        const { success } = await api.post(url, this.wfSaveParams);
        if (success) {
          this.isSuccess = true;
          this.isVisibleWfButtons = false;
          this.isDisabledSelect = true;
        }
        await this.fetchMatches(this.paramsForFetchMatches);
      }
    },

    async getServerData(matchId) {
      const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/match/${matchId}/servermanager`;
      const res = await api.get(url);
      this.serverInfo = res.data;
      if (this.serverInfo.idServer === null) {
        this.hasNoActiveServer = true;
      }
    },

    async restartServer(matchId) {
      try {
        this.isRestartingServer = true;
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/match/${matchId}/serverrestart`;
        const { success } = await api.post(url);
        this.isVisibleRestartButtons = !success;
        if (success) {
          this.isVisibleRestartServerButton = false;
        }
      } finally {
        this.isRestartingServer = false;
      }
    },

    async makeServerAction(matchId, payload) {
      try {
        this.isLoadingServerData = true;
        const url = `organization/${this.organizationId}/tournament/${this.tournamentId}/match/${matchId}/serveraction`;
        const {
          data: { response },
        } = await api.post(url, payload);
        this.$refs.serverResult.$el.value = response;
      } catch (err) {
        this.isError = true;
        this.error = err.error[0];
      } finally {
        this.isLoadingServerData = false;
      }
    },

    normalizeParams(params) {
      return Object.entries(params).reduce((acc, [key, objValue]) => {
        if (key === 'f[finished]' || key === 'f[teamsDefined]') {
          acc[key] = objValue?.value;
        } else if (key === 'f[idMatch]' || key === 'f[rounds]') {
          acc[key] = objValue?.value ?? undefined;
        } else if (key === 'f[specialStatus]') {
          acc[key] = objValue?.id || undefined;
        } else if (key === 'c') {
          acc[key] = objValue?.value;
        } else if (key === 'p') {
          acc[key] = this.currentPage;
        } else {
          acc[key] = objValue || undefined;
        }
        return acc;
      }, {});
    },

    async setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
      this.syncQueryWithStore('p', {
        id: page,
        name: page,
        value: `${page}`,
      });
      await this.fetchMatches(this.paramsForFetchMatches);
    },

    closeModal() {
      this.isVisibleModal = false;
      this.isError = false;
      this.isErrorNoData = false;
      this.errorTeam1 = '';
      this.errorTeam2 = '';
      this.isSuccess = false;
      this.isEnterMatchScore = false;
      this.isCancelMatchScore = false;
      this.isVisibleSettingButtons = true;
      this.isVisibleWfButtons = true;
      this.isVisibleRestartButtons = true;
      this.isServerCsgo = false;
      this.isRestartServerCsgo = false;
      this.hasNoActiveServer = false;
      this.isWfMatchScore = false;
      this.isNoWfData = false;
      this.isVisibleCloseButton = false;
      this.isVisibleConfirmBlock = false;
      this.isDisabledSelect = false;
      this.isVisibleRestartServerButton = true;
      this.isVisibleAdminMsgModal = false;
      this.selectedMatches = [];
      this.isPauseModal = false;
      this.isVisibleBackupModal = false;
      this.selectedWfOption = {
        id: 1,
        name: 'Выбрать',
        value: 'choose',
      };
      this.matchBackups = [];
      this.selectedBackupRound = {};
      this.resetAllScoreParams();
    },

    async showSetScoreModal(matchId) {
      await this.getMatchInfoPerId(matchId);
      this.isEnterMatchScore = true;
      this.isVisibleModal = true;
      this.setScoreParams.form.id = matchId;
    },

    showResetScoreModal() {
      this.isCancelMatchScore = true;
      this.isVisibleModal = true;
    },

    showRestartServerModal() {
      this.isRestartServerCsgo = true;
      this.isVisibleModal = true;
    },

    showAdminMsgModal(matchId) {
      this.matchId = matchId;
      this.isVisibleAdminMsgModal = true;
      this.isVisibleModal = true;
    },

    showPausedModal(match) {
      this.matchId = match.id;
      this.currentMatch = match;
      this.isPauseModal = true;
      this.isVisibleModal = true;
    },

    async showBackupModal(matchId) {
      this.matchId = matchId;
      this.isVisibleBackupModal = true;
      this.isVisibleModal = true;
      await this.getMatchBackups();
    },

    resetAllScoreParams() {
      for (let key of Object.keys(this.setScoreParams.form)) {
        if (key === 'tpTeam1' || key === 'tpTeam2') {
          this.setScoreParams.form[key] = false;
        } else {
          this.setScoreParams.form[key] = '';
        }
      }
      for (let key of Object.keys(
        this.setScoreParams.detailedScoring,
      )) {
        if (key === 'team1' || key === 'team2') {
          Object.keys(
            this.setScoreParams.detailedScoring[key],
          ).forEach((innerKey) => {
            this.setScoreParams.detailedScoring[key][innerKey] = '';
          });
        } else {
          this.setScoreParams.detailedScoring[key] = '';
        }
      }
    },

    async showWfMatchModal(matchId) {
      const res = await this.getWfData(matchId);
      if (res) {
        this.isWfMatchScore = true;
        this.isVisibleModal = true;
      }
    },

    async showServerStatusModal(matchId) {
      this.matchId = matchId;
      await this.getServerData(matchId);
      this.isServerCsgo = true;
      this.isVisibleModal = true;
    },

    getScore(match) {
      const team1Score = match.team1.points;
      const team2Score = match.team2.points;
      return !team1Score || !team2Score
        ? ''
        : `${team1Score}:${team2Score}`;
    },

    getTeamScore(matchResult) {
      const team1Score = matchResult.pointsTeam1;
      const team2Score = matchResult.pointsTeam2;
      return !team1Score || !team2Score
        ? ''
        : `${team1Score}:${team2Score}`;
    },

    goToUserProfile(userHash) {
      this.$router.push({
        name: 'profile',
        params: { hash: userHash },
      });
    },

    validateInput(value, errorTeamName) {
      const regExpNumbers = /^\d+$/;
      const isNumber = regExpNumbers.test(value);
      if (value === '') {
        this[errorTeamName] = '';
      } else if (!isNumber) {
        this[errorTeamName] = this.$t('errors.input_digit');
      } else if (value > 200) {
        this[errorTeamName] = this.$t('errors.limit_max_value');
      } else {
        this[errorTeamName] = '';
      }
    },

    validateInputFilter(value, errorFilterName) {
      const regExpRounds = /^[0-9UL., ]+$/;
      const isValidRounds = regExpRounds.test(value);
      if (!value.length) {
        this[errorFilterName] = '';
      } else if (!isValidRounds) {
        this[errorFilterName] = this.$t('errors.invalid_rounds');
      } else {
        this[errorFilterName] = '';
      }
    },

    showAllTeams(match) {
      match.isVisibleTeams = true;
    },

    hideAllTeams(match) {
      match.isVisibleTeams = false;
    },

    makeAction(actionType, matchId) {
      this.matchId = matchId;
      switch (actionType) {
        case 'setScore':
          this.showSetScoreModal(matchId);
          break;
        case 'reset':
          this.showResetScoreModal();
          break;
        case 'restartMatch':
          this.showRestartServerModal();
          break;
        case 'loadData':
          this.showWfMatchModal(matchId);
          break;
        default:
          throw new Error('There is not action with this type');
      }
    },

    updateFilterFinished(value) {
      this.$store.commit(
        'tournamentSettings/SET_SELECT_FINISHED',
        value,
      );
      this.setQueryParams({
        ...this.queryParams,
        'f[finished]': value.value,
      });
    },

    updateFilterStatus(value) {
      this.$store.commit(
        'tournamentSettings/SET_SELECT_STATUS',
        value,
      );
      this.setQueryParams({
        ...this.queryParams,
        'f[specialStatus]': value.value,
      });
    },

    updateFilterTeamsDefined(value) {
      this.$store.commit(
        'tournamentSettings/SET_SELECT_TEAMS_DEFINED',
        value,
      );
      this.setQueryParams({
        ...this.queryParams,
        'f[teamsDefined]': value.value,
      });
    },

    updateFilterOnPage(value) {
      this.currentPage = 1;
      this.$store.commit(
        'tournamentSettings/SET_SELECT_ON_PAGE',
        value,
      );
      this.setQueryParams({
        ...this.queryParams,
        c: value.value,
      });
    },

    updateFilterRounds: _.debounce(function (value) {
      const valueForSave =
        typeof value === 'object'
          ? value
          : {
              id: 1,
              value,
            };
      const valueForQuery =
        typeof value === 'object' ? value.value : value;
      this.$store.commit(
        'tournamentSettings/SET_INPUT_ROUNDS',
        valueForSave,
      );
      this.setQueryParams({
        ...this.queryParams,
        'f[rounds]': valueForQuery,
      });
    }, 600),

    updateFilterIdMatch(value) {
      const valueForSave =
        typeof value === 'object'
          ? value
          : {
              id: 1,
              value,
            };
      const valueForQuery =
        typeof value === 'object' ? value.value : value;
      this.$store.commit(
        'tournamentSettings/SET_INPUT_ID_MATCH',
        valueForSave,
      );

      this.setQueryParams({
        ...this.queryParams,
        'f[idMatch]': valueForQuery,
      });
    },

    updateFilterPerPage(value) {
      this.$store.commit(
        'tournamentSettings/SET_FILTER_CURRENT_PAGE',
        value,
      );

      this.setQueryParams({
        ...this.queryParams,
        p: value.value,
      });
    },
    selectTpTeam(value, teamNumber) {
      if (value) {
        this.setScoreParams.form[`pointsTeam${teamNumber}`] = '';
        this[`errorTeam${teamNumber}`] = '';
        this.inputKey += 1;
      }
    },

    canAdminSendMessage(matchId) {
      const allowAdminActions =
        this.matchServersInfo[matchId]?.actions;
      return allowAdminActions?.includes('message');
    },

    canAdminRestoreBackup(matchId) {
      const allowAdminActions =
        this.matchServersInfo[matchId]?.actions;
      return allowAdminActions?.includes('restoreBackup');
    },

    canAdminPauseMatch(matchId) {
      const allowAdminActions =
        this.matchServersInfo[matchId]?.actions;
      return allowAdminActions?.includes('pause');
    },

    canAdminUnpauseMatch(matchId) {
      const allowAdminActions =
        this.matchServersInfo[matchId]?.actions;
      return allowAdminActions?.includes('unpause');
    },

    async setMatchPause(pauseType) {
      const map = {
        pause: 'pause',
        unpause: 'unpause',
      };
      const url = `match/${this.matchId}/server/cs2/${map[pauseType]}`;
      try {
        this.isError = false;
        this.isLoading = true;
        await api.post(url);
        await this.fetchMatches(this.queryParams);
        this.isVisibleModal = false;
      } catch (err) {
        this.isError = true;
        this.error = err?.error[0]?.detail;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.filters {
  padding: 16px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background: var(--main-color-darkgray);
  margin-bottom: 8px;
  &__item {
    margin-bottom: 12px;
  }
  &__title {
    margin-bottom: 8px;
  }
  @include min-tablet() {
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
.enter-match-score {
  &__info {
    margin-bottom: 8px;
    @include min-tablet() {
      margin-bottom: 12px;
    }
  }
  &__settings-btns {
    display: flex;
    gap: 8px;
  }
}
.cancel-match-score {
  &__settings-btns {
    display: flex;
    gap: 8px;
  }
}
.scores-team {
  margin-bottom: 12px;
  &__score,
  &__team-name,
  &__team__score,
  &__tech-defeat {
    margin-bottom: 8px;
    @include min-tablet() {
      margin-bottom: 12px;
    }
  }
}

.error-msg,
.success-msg {
  margin-bottom: 12px;
}

.table__list {
  list-style: none;
}
.table__item {
  margin-bottom: 10px;
}
.table__link {
  display: block;
}
.table__button {
  display: block;
  margin-bottom: 4px;
}

.match-card {
  &__info {
    text-align: center;
  }
  &__data {
    display: flex;
    justify-content: center;
    gap: 4px;
  }
}
.match-card__input {
  display: none;
}
.match-card__select-block {
  position: sticky;
  top: 0;
}
.match-card__select {
  margin-bottom: 12px;
}

.match-card__label {
  display: grid;
  border: 2px solid grey;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 12px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.match-card__input:checked + .match-card__label {
  border-color: #16a7ff;
}

.wf-match-score__content {
  max-height: 450px;
  overflow: auto;
}
.wf-match-score__buttons {
  display: flex;
  gap: 8px;
  position: sticky;
  bottom: 0;
  padding-top: 12px;
  background: #2d2d30;
}
.wf-match-score__select-block {
  padding-bottom: 12px;
  background: #2d2d30;
}
.scores-team-gof__title,
.scores-team-gof__select {
  margin-bottom: 12px;
}
.scores-team-gof__team-names {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  justify-content: end;
  margin-bottom: 8px;
}
.scores-team-gof__team1 {
  grid-column-start: 2;
}
.scores-team-gof__team2 {
  grid-column-start: 3;
}
.scores-team-gof__detailed-info-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 8px;
}
.special-status {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.match-score {
  margin-bottom: 4px;
}
.special-status__label {
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: var(--additional-color-blue);
}
.special-status--alert {
  background-color: var(--additional-color-red);
}
.special-status--success {
  background-color: #1e8726;
}
.teams-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;

  li {
    a {
      background: var(--button-bg-tertiary);
      display: flex;
      border-radius: 6px;
      padding: 4px 6px;
    }
  }
}
.server-csgo__title,
.server-csgo__item {
  margin-bottom: 8px;
}
.server-csgo__status,
.server-csgo__status-title {
  margin-bottom: 4px;
}
.control-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}
.command-result {
  width: 100%;
}
.server-info-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.server-info-status {
  padding: 4px 6px;
  border-radius: 6px;
}
.restart-server__warning {
  margin-bottom: 8px;
}
.restart-server__confirm-button {
  margin-bottom: 12px;
}
.pagination {
  margin-top: 20px;
  @include min-tablet() {
    margin-top: 10px;
  }
}
.server-info-status--attention {
  background-color: var(--additional-color-red);
}
.server-info-status--success {
  background-color: #1e8726;
}
.server-info-status--warning {
  background-color: var(--additional-color-orange);
}

.match-control__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.match-control__list-item {
  padding: 16px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background: #161516;
}

.match-card-header {
  display: flex;
  gap: 16px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 6px;
  margin-bottom: 4px;
}

.match-card-results {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 16px;

  @include min-laptop() {
    flex-wrap: nowrap;
    gap: 0;
  }
}

.match-card-results__team,
.match-card-results__total {
  width: 100%;

  @include min-laptop() {
    width: 30%;
  }
}

.match-card-results__team {
  font-size: 14px;

  ::v-deep .team-link {
    width: 100%;
  }
}

.match-card-results__team-additional {
  font-size: 12px;
  color: var(--additional-color-gray);
}

.match-card-results__total {
  font-size: 32px;
  line-height: 1em;
  font-weight: bold;
  text-align: center;
}

.match-card-results__footer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border-top: 1px solid var(--main-color-o-gray);
  padding-top: 16px;
}

.match-card-results__screenshot-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.match-card-results__screenshot-list-item {
  font-size: 10px;
  list-style-type: none;
}
.match-card-results__screenshot {
  display: block;
  color: var(--main-color-white);
  padding: 2px 4px;
  background: var(--button-bg-tertiary);
  border-radius: 4px;
  &:hover {
    color: var(--main-color-white);
    text-decoration: none;
  }
}

.match-card-server {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  padding-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid var(--main-color-o-gray);
  font-size: 12px;
  font-weight: bold;
}

.match-card-server__status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.match-card-results__players {
  font-size: 12px;
  margin-bottom: 8px;
}
.match-card-server__judge {
  font-size: 12px;
  margin-bottom: 12px;
}
.match-card-server__toggle-btn {
  color: var(--button-color-dark);
  background: var(--button-bg-secondary);
  border: none;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 12px;
  margin-bottom: 8px;
}
.admin-msg-modal__desc,
.admin-msg-modal__send-button {
  margin-bottom: 12px;
}
.admin-msg-modal__msg {
  width: 100%;
  margin-bottom: 12px;
}
.pause-match__desc {
  margin-bottom: 12px;
}
.pause-match__buttons {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}
.pause-duration {
  display: flex;
  gap: 4px;
}
.backup-modal__title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
}
.backup-modal__desc {
  margin-bottom: 12px;
}
.backup-modal__select {
  margin-bottom: 12px;
}
</style>
