<template>
  <div class="swiss-settings">
    <BaseSelect
      class="swiss-settings__control"
      :value="form.numLimitParticipants.value"
      :required="true"
      label-key="key"
      value-key="value"
      :disabled="isExecutingState"
      :label="$t('swissBracket.max_participants_count')"
      :error-message.sync="form.numLimitParticipants.error"
      :options="form.numLimitParticipants.selectFrom"
      @input="
        updateFormData({
          name: form.numLimitParticipants.name,
          value: Number($event),
        })
      "
    />
    <p class="swiss-settings__info">{{ winnersCountText }}</p>
  </div>
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import { i18n } from '@src/localization/config';

export default {
  name: 'SwissSettings',
  components: {
    BaseSelect,
  },
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapGetters('tournamentSettings', ['getWorkflow']),

    getWorkflowCurrentState() {
      return this.getWorkflow(this.$route.params.tnId)?.currentState;
    },

    isExecutingState() {
      const executingState = 'executing';
      return this.getWorkflowCurrentState === executingState;
    },

    spreadScoresTitle() {
      return i18n.t('swissBracket.spread_scores');
    },

    getWinnersCount() {
      return Number(this.form.numLimitParticipants.value) / 2;
    },

    winnersCountText() {
      return `${i18n.t('swissBracket.winners_count')}: ${
        this.getWinnersCount
      }`;
    },
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
  },
};
</script>

<style lang="scss" scoped>
.swiss-settings__control {
  margin-top: 16px;
  margin-bottom: 16px;
}

.swiss-settings__title {
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 24px;
  margin-bottom: 16px;
}
</style>
