var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"inline"},[_c('BaseInput',{staticClass:"settings-control",attrs:{"label":_vm.$t('tournaments.group_count_title'),"value":_vm.form.count.value,"error-message":_vm.form.count.error,"type":"number"},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.count, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.count, "error", $event)},"input":function($event){_vm.updateFormData({
          name: _vm.form.count.name,
          value: Number($event || 0),
        })}}}),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_subgroups-count'))+" ")])],2)],1),_c('div',{staticClass:"inline"},[_c('BaseInput',{staticClass:"settings-control",attrs:{"label":_vm.$t('tournaments.group_count_team'),"value":_vm.form.countOnGroup.value,"error-message":_vm.form.countOnGroup.error,"type":"number"},on:{"update:errorMessage":function($event){return _vm.$set(_vm.form.countOnGroup, "error", $event)},"update:error-message":function($event){return _vm.$set(_vm.form.countOnGroup, "error", $event)},"change":_vm.validateCountOnGroup}}),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_subgroups-teams-count'))+" ")])],2)],1),_c('div',{staticClass:"rounds-title"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.group_count_round'))+" "),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_subgroups-rounds-count'))+" ")])],2)],1),_c('div',{staticClass:"rounds"},_vm._l((_vm.roundCount),function(round){return _c('pvp-btn',{key:round,staticClass:"rounds-btn",attrs:{"variant":_vm.form.countRound.value === round ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.updateFormData({ name: _vm.form.countRound.name, value: round })}}},[_vm._v(" "+_vm._s(round)+" ")])}),1),_c('div',{staticClass:"divider"}),_c('h4',{staticClass:"points-title"},[_vm._v(_vm._s(_vm.$t('scores.distribution')))]),_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"settings-control",attrs:{"label":_vm.$t('scores.for_win'),"value":_vm.form.pointWin.value,"required":true,"options":_vm.points.win},on:{"input":function($event){_vm.updateFormData({
          name: _vm.form.pointWin.name,
          value: Number($event),
        })}}}),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_subgroups-win-count'))+" ")])],2)],1),_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"settings-control",attrs:{"label":_vm.$t('scores.for_tie'),"value":_vm.form.pointTie.value,"required":true,"options":_vm.points.tie},on:{"input":function($event){_vm.updateFormData({
          name: _vm.form.pointTie.name,
          value: Number($event),
        })}}}),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_subgroups-tie-count'))+" ")])],2)],1),_c('div',{staticClass:"inline"},[_c('BaseSelect',{staticClass:"settings-control",attrs:{"label":_vm.$t('scores.for_defeat'),"value":_vm.form.pointDefeat.value,"required":true,"options":_vm.points.defeat},on:{"input":function($event){_vm.updateFormData({
          name: _vm.form.pointDefeat.name,
          value: Number($event),
        })}}}),_c('pvp-tooltip',[_c('icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('tournaments.tooltips_settings_subgroups-defeat-count'))+" ")])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }