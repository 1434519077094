<template>
  <textarea
    v-model="currentText"
    class="textarea"
    name="name"
    :rows="rows"
    :cols="cols"
    :placeholder="placeholder"
    @change="updateText"
  />
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    rows: {
      type: [Number, String],
      default: 4,
    },
    cols: {
      type: [Number, String],
      default: 50,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      currentText: this.text,
    };
  },
  methods: {
    updateText() {
      this.$emit('change', this.currentText);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  background-color: var(--button-bg-tertiary);
  color: var(--main-color-white);
  padding: 16px;

  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  border-radius: 6px;
}
</style>
